<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { Cropper } from "vue-advanced-cropper";
import ModalTemplate from "./modalTemplate.vue";
import ButtonGeneral from "./buttons/buttonGeneral.vue";

import "vue-advanced-cropper/dist/style.css";

defineProps(["croppingImage", "aspectRatio"]);
const emit = defineEmits(["onCrop", "cancel"]);

const croppedImage = ref(null);

const change = ({ canvas }) => {
  croppedImage.value = canvas.toDataURL();
};

const submitCrop = () => {
  emit("onCrop", croppedImage.value);
};
</script>

<template>
  <div>
    <modal-template
      title="Potong Gambar"
      @close="emit('cancel')"
      :show="croppingImage"
    >
      <div class="px-5 pb-5 text-left sm:last:p-6">
        <cropper
          class="cropper"
          :src="croppingImage"
          :stencil-props="{
            aspectRatio: aspectRatio,
          }"
          @change="change"
        />
      </div>
      <div class="flex justify-end p-5 sm:p-6">
        <button-general
          :title="'Batal'"
          :size="'s'"
          :width="'full'"
          :color="'outline'"
          class="mr-[6px]"
          @click="emit('cancel')"
        />
        <button-general
          :title="'Potong Gambar'"
          :size="'s'"
          :width="'full'"
          :color="'primary'"
          @click="submitCrop"
        />
      </div>
    </modal-template>
  </div>
</template>
