<template>
  <div
    style="height: calc(100vh - 90px)"
    class="bg-grayscale-300 animate-pulse"
  ></div>
  <div class="container px-5 mx-auto">
    <div
      class="mt-12 max-w-[150px] w-full h-[28px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
    <div
      class="mt-4 max-w-[500px] w-full h-[48px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
    <div class="flex mt-12">
      <div
        class="mr-6 max-w-[200px] w-full h-[28px] bg-grayscale-300 animate-pulse rounded-full"
      ></div>
      <div
        class="max-w-[200px] w-full h-[28px] bg-grayscale-300 animate-pulse rounded-full"
      ></div>
    </div>
    <div
      class="mt-12 w-full h-[28px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
    <div
      class="mt-4 w-full h-[28px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
    <div
      class="mt-4 w-full h-[28px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
    <div
      class="mt-4 w-1/2 h-[28px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
    <div
      class="mt-12 w-full h-[28px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
    <div
      class="mt-4 w-full h-[28px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
    <div
      class="mt-4 w-full h-[28px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
    <div
      class="mt-4 mb-12 w-1/2 h-[28px] bg-grayscale-300 animate-pulse rounded-full"
    ></div>
  </div>
</template>
