import axios from "axios";

export default {
  state: {
    totalEvent: 0,
    totalPage: 0,
    listEvent: [],
    offset: 1,
    limit: 10,
    sortBy: "created_date",
    search: "",
    sortValue: "",
    filterBy: "",
    categorySelect: 0,
    groupSelect: 0,
    detailEvent: null,
    // history
    totalEventUser: 0,
    totalPageUser: 0,
    offsetEventUser: 1,
    limitEventUser: 0,
    eventUser: [],
    // event participant
    offsetParticipant: 1,
    dataParticipant: [],
    totalParticipant: 0,
    // participated event
    participatedEvents: [],
    participatedEventsTotal: 0,
    participatedEventsPage: 1,
    participatedEventsTotalPage: 1,
  },
  getters: {
    getTotalEvent: (state) => {
      return state.totalEvent;
    },
    getTotalPageEvent: (state) => {
      return state.totalPage;
    },
    getOffsetEvent: (state) => {
      return state.offset;
    },
    getLimitEvent: (state) => {
      return state.limit;
    },
    getSortByEvent: (state) => {
      return state.sortBy;
    },
    getSearchEvent: (state) => {
      return state.search;
    },
    getSortValueEvent: (state) => {
      return state.sortValue;
    },
    getListEvent: (state) => {
      return state.listEvent;
    },
    getCategorySelectEvent: (state) => {
      return state.categorySelect;
    },
    getGroupSelectEvent: (state) => {
      return state.groupSelect;
    },
    getDetailEvent: (state) => {
      return state.detailEvent;
    },
    // history
    getTotalEventUser: (state) => {
      return state.totalEventUser;
    },
    getTotalPageEventUser: (state) => {
      return state.totalPageUser;
    },
    getOffsetEventUser: (state) => {
      return state.offsetEventUser;
    },
    getEventUser: (state) => {
      return state.eventUser;
    },
    getDataParticipant: (state) => {
      return state.dataParticipant;
    },
    getTotalParticipant: (state) => {
      return state.totalParticipant;
    },
    // participated event
    getParticipatedEvents: (state) => {
      return state.participatedEvents;
    },
    getParticipatedEventsTotal: (state) => {
      return state.participatedEventsTotal;
    },
    getParticipatedEventsPage: (state) => {
      return state.participatedEventsPage;
    },
    getParticipatedEventsTotalPage: (state) => {
      return state.participatedEventsTotalPage;
    },
  },
  actions: {
    async getListEvent({ state, commit }, data) {
      const params = {
        offset: data?.offset || state.offset,
        limit: state.limit,
        categoryId: state.categorySelect,
        groupId: state.groupSelect,
        // search: state.filterBy,
        sortBy: state.sortBy,
        search: data?.search || state.search,
        sortValue: state.sortValue,
        status: "P",
      };
      const url =
        process.env.VUE_APP_API_URL +
        "/api/v1/event?" +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        commit("setTotalEvent", response.data.result.total);
        commit("setTotalPageEvent", response.data.result.total);
        commit("setListEvent", response.data.result.datas);
        return response;
      } catch (error) {
        commit("setTotalEvent", 0);
        commit("setTotalPageEvent", 0);
        commit("setListEvent", []);
        return error.response;
      }
    },
    async getListEventForUser({ state, commit }, data) {
      const params = {
        offset: data?.offset || state.offset,
        limit: state.limit,
        categoryId: state.categorySelect,
        groupId: state.groupSelect,
        // search: state.filterBy,
        sortBy: state.sortBy,
        search: data?.search || state.search,
        sortValue: state.sortValue,
      };
      const url =
        process.env.VUE_APP_API_URL +
        "/api/v1/event?" +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setTotalEvent", response.data.result.total);
        commit("setTotalPageEvent", response.data.result.total);
        commit("setListEvent", response.data.result.datas);
        return response;
      } catch (error) {
        commit("setTotalEvent", 0);
        commit("setTotalPageEvent", 0);
        commit("setListEvent", []);
        return error.response;
      }
    },
    async getDetailEvent({ commit }, id) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/event/${id}`;
      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        commit("setDetailEvent", response.data.result);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async createEvent({ dispatch }, data) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/event`;
      try {
        const response = await axios({
          method: "post",
          url: url,
          data,
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        dispatch("addNewAlert", {
          status: "success",
          title: "Berhasil",
          message: "Anda berhasil menambah acara",
        });
        return response;
      } catch (error) {
        dispatch("addNewAlert", {
          status: "error",
          title: "Terdapat kesalahan",
          message:
            "Menambah acara gagal, silakan masukkan data dengan benar dan coba lagi.",
        });
        return false;
      }
    },
    async editEvent({ dispatch }, { id, data }) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/event`;
      try {
        const response = await axios({
          method: "put",
          url: url,
          data: {
            id,
            ...data,
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        dispatch("addNewAlert", {
          status: "success",
          title: "Berhasil",
          message: "Anda berhasil mengubah acara",
        });
        return response;
      } catch (error) {
        dispatch("addNewAlert", {
          status: "error",
          title: "Terdapat kesalahan",
          message:
            "Mengubah acara gagal, silakan masukkan data dengan benar dan coba lagi.",
        });
        return false;
      }
    },
    async getEventUser({ state, commit }, id) {
      const params = {
        offset: state.offsetEventUser,
        limit: state.limitEventUser,
        userId: id,
      };
      const url =
        process.env.VUE_APP_API_URL +
        "/api/v1/event?" +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setTotalEventUser", response.data.result.total);
        commit("setEventUser", response.data.result.datas);
        return response;
      } catch (error) {
        commit("setTotalEventUser", 0);
        commit("setEventUser", []);
        return error.response;
      }
    },
    async registerEvent(_, data) {
      const url =
        process.env.VUE_APP_API_URL + `/api/v1/event-participant/register`;
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: {
            eventId: data,
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async cancelEvent(_, data) {
      const url =
        process.env.VUE_APP_API_URL + `/api/v1/event-participant/canceled`;
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: {
            eventId: data,
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async getEventParticipantDetail({ commit, state }, data) {
      const params = {
        offset: data.offset || state.offsetParticipant,
        limit: data.limit || state.limit,
        eventId: data.id,
        name: data.name,
      };
      const url =
        process.env.VUE_APP_API_URL +
        "/api/v1/event-participant/get-current?" +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setDataParticipant", response.data.result.participants);
        commit("setTotalParticipant", response.data.result.total);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async getParticipatedEvents({ commit, state }, userId) {
      const params = {
        offset: state.participatedEventsPage,
        limit: 10,
        userId,
      };
      const url =
        process.env.VUE_APP_API_URL +
        "/api/v1/event-participant/get-by-user?" +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setParticipatedEvents", response.data.result.datas);
        commit("setParticipatedEventsTotal", response.data.result.total);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async deleteEvent({ dispatch }, data) {
      const url = process.env.VUE_APP_API_URL + "/api/v1/event/delete";
      try {
        const response = await axios({
          method: "put",
          url: url,
          data: {
            eventIds: data,
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        dispatch("addNewAlert", {
          status: "success",
          title: "Berhasil",
          message: "Anda berhasil menghapus acara",
        });
        return response;
      } catch (error) {
        dispatch("addNewAlert", {
          status: "error",
          title: "Terdapat kesalahan",
          message: "Menghapus acara gagal, silakan coba lagi.",
        });
        return false;
      }
    },
    async cancelParticipant({ dispatch }, { eventId, userId }) {
      const url =
        process.env.VUE_APP_API_URL + "/api/v1/event-participant/cancel";
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: {
            eventId,
            userId,
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        dispatch("addNewAlert", {
          status: "success",
          title: "Berhasil",
          message: "Anda berhasil membatalkan kehadiran",
        });
        return response;
      } catch (error) {
        dispatch("addNewAlert", {
          status: "error",
          title: "Gagal Membatalkan Kehadiran",
          message:
            error.response?.data?.message ||
            "Membatalkan kehadiran gagal, silakan coba lagi.",
        });
        return false;
      }
    },
  },
  mutations: {
    setTotalEvent: (state, data) => {
      state.totalEvent = data;
    },
    setTotalPageEvent: (state, data) => {
      state.totalPage = Math.ceil(data / state.limit);
    },
    setListEvent: (state, data) => {
      state.listEvent = data;
    },
    setOffsetEvent: (state, data) => {
      state.offset = data;
    },
    setSortByEvent: (state, data) => {
      state.sortBy = data;
    },
    setSearchEvent: (state, data) => {
      state.search = data;
    },
    setSortValueEvent: (state, data) => {
      state.sortValue = data;
    },
    setLimitEvent: (state, data) => {
      state.limit = data;
    },
    setFilterByEvent: (state, data) => {
      state.filterBy = data;
    },
    setCategorySelectEvent: (state, data) => {
      state.categorySelect = data;
    },
    setGroupSelectEvent: (state, data) => {
      state.groupSelect = data;
    },
    setDetailEvent: (state, data) => {
      state.detailEvent = data;
    },
    // history
    setTotalEventUser: (state, data) => {
      state.totalEventUser = data;
      state.totalPageUser = Math.ceil(data / 10);
    },
    setOffsetEventUser: (state, data) => {
      state.offsetEventUser = data;
    },
    setLimitEventUser: (state, data) => {
      state.limitEventUser = data;
    },
    setEventUser: (state, data) => {
      state.eventUser = data;
    },
    setDataParticipant: (state, data) => {
      state.dataParticipant = data;
    },
    setTotalParticipant: (state, data) => {
      state.totalParticipant = data;
    },
    // participated events
    setParticipatedEvents: (state, data) => {
      state.participatedEvents = data;
    },
    setParticipatedEventsTotal: (state, data) => {
      state.participatedEventsTotal = data;
      state.participatedEventsTotalPage = Math.ceil(data / 10);
    },
    setParticipatedEventsPage: (state, data) => {
      state.participatedEventsPage = data;
    },
  },
};
