import axios from 'axios';

export default {
	state: {
		listBanner: [],
		listEvent: [],
		listCategory: [],
		listGallery: [],
	},
	getters: {
		getListBannerLanding: (state) => {
			return state.listBanner;
		},
		getListGalleryLanding: (state) => {
			return state.listGallery;
		},
	},
	mutations: {
		setListBannerLanding: (state, data) => {
			state.listBanner = data;
		},
		setListGalleryLanding: (state, data) => {
			state.listGallery = data;
		},
	},
	actions: {
		async getListBannerLanding({ commit }) {
			const url =
				process.env.VUE_APP_API_URL + `/api/v1/banner?type=HEROES`;
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});
				commit('setListBannerLanding', response.data.result.datas);
			} catch (error) {
				return error.response;
			}
		},
		async getListGalleryLanding({ commit }) {
			const url =
				process.env.VUE_APP_API_URL + `/api/v1/banner?type=GALLERY`;
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});
				commit('setListGalleryLanding', response.data.result.datas);
			} catch (error) {
				return error.response;
			}
		},
	},
};
