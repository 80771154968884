<template>
  <div
    class="fixed sm:sticky top-0 z-[101] w-screen sm:w-[256px] max-h-screen flex-shrink-0"
  >
    <div class="flex justify-between p-5 bg-gray-650 sm:bg-grayscale-950">
      <img
        alt="86-logo-white"
        :src="require(`@/assets/images/86-logo-white.svg`)"
      />
      <img
        alt="86-menu-mobile"
        :src="require(`@/assets/images/86-menu-mobile.svg`)"
        class="block sm:hidden"
        @click="setShowMenu(!showMenu)"
      />
    </div>
    <div class="border-t border-gray-650" />
    <div
      class="p-5 overflow-y-auto bg-grayscale-950"
      :class="showMenu ? 'flex flex-col justify-between' : 'hidden sm:block'"
      style="height: calc(100vh - 72px)"
    >
      <div>
        <div v-for="(data, index) in listMenu" :key="index">
          <button-layout
            :title="data.title"
            :image="data.image"
            :link="data.link"
            :active="data.title === showActiveMenu"
            @click="setActiveMenu(data.title)"
          />
        </div>
      </div>
      <div class="block sm:hidden">
        <!-- <button-layout
          :title="'Notifikasi'"
          :image="'86-notification.svg'"
          @click="toLogPage"
        /> -->
        <button-layout
          :title="'Pengaturan'"
          :image="'86-setting-mobile.svg'"
          :active="showActiveMenu === 'Pengaturan'"
          @click="toSettingPage"
        />
        <div class="border-t border-gray-650" />
        <div class="flex items-center justify-between mt-6">
          <mini-profile :style="'white'" />
          <img
            alt="86-logout"
            :src="require(`@/assets/images/86-logout-white.svg`)"
            class="cursor-pointer"
            @click="setShowConfirmationLogout"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import buttonLayout from "../buttons/buttonLayout.vue";
import MiniProfile from "../miniprofile.vue";

export default {
  components: { buttonLayout, MiniProfile },
  name: "Sidebar",
  data() {
    return {
      listMenu: [
        {
          title: "Dashboard",
          image: "86-dashboard.svg",
          link: "/admin",
        },
        {
          title: "Pengguna",
          image: "86-user.svg",
          link: "/admin/user",
        },
        {
          title: "Acara",
          image: "86-agenda.svg",
          link: "/admin/event",
        },
        {
          title: "Aktivitas Log",
          image: "86-log.svg",
          link: "/admin/log",
        },
      ],
    };
  },
  computed: {
    showActiveMenu() {
      return store.getters.getActiveMenu;
    },
    showMenu() {
      return store.getters.getShowMenu;
    },
  },
  methods: {
    setActiveMenu(data) {
      store.commit("setActiveMenu", data);
    },
    setShowMenu(isShowMenu) {
      store.commit("setShowMenu", isShowMenu);
    },
    setShowConfirmationLogout() {
      store.commit("setConfirmationStatus", "warning");
      store.commit("setShowConfirmation", !store.getters.getShowConfirmation);
    },
    toLogPage() {
      this.$router.push("/admin/log");
    },
    toSettingPage() {
      this.$router.push("/admin/setting");
    },
  },
  unmounted() {
    this.setShowMenu(false);
  },
};
</script>
