<template>
  <div class="text-left">
    <label>{{ title }}</label>
    <input-select
      class="mt-[6px]"
      v-model="value"
      :listItem="listItem"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/inputSelect.vue";
import { computed } from "vue";
export default {
  name: "InputSelectLabel",
  components: {
    InputSelect,
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    modelValue: {
      type: String,
    },
    listItem: {
      type: Object,
      required: true,
      default() {},
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        context.emit("update:modelValue", value);
      },
    });

    return {
      value,
    };
  },
};
</script>
