<template>
  <div class="container px-5 mx-auto my-8">
    <div class="bg-grayscale-300 animate-pulse rounded-full h-[56px]"></div>
    <div
      class="mt-4 w-1/2 bg-grayscale-300 animate-pulse rounded-full h-[56px]"
    ></div>
    <div class="grid grid-cols-3 gap-6 mt-12">
      <div
        v-for="i in 3"
        :key="i"
        class="aspect-square bg-grayscale-300 animate-pulse rounded-2xl"
      ></div>
    </div>
  </div>
</template>
