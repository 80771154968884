<!-- eslint-disable vue/no-parsing-error -->
<template>
  <!-- Personal Profile -->
  <div class="bg-white border border-grayscale-100 rounded-xl">
    <div
      class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
    >
      Data Pribadi
    </div>
    <div class="px-6 py-5">
      <!-- Name -->
      <div class="text-base font-semibold leading-6">Nama</div>
      <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
        {{ detail?.name || "-" }}
      </div>
      <!-- Nickname -->
      <div class="mt-5 text-base font-semibold leading-6">Nama Panggilan</div>
      <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
        {{ detail?.nickname || "-" }}
      </div>
      <!-- Username -->
      <div class="mt-5 text-base font-semibold leading-6">Username</div>
      <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
        {{ detail?.username || "-" }}
      </div>
      <!-- Email -->
      <div class="mt-5 text-base font-semibold leading-6">Email</div>
      <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
        {{ detail?.email || "-" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShortProfileUser",
  props: {
    detail: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
