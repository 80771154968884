<template>
  <button
    class="flex items-center text-sm font-medium leading-6 px-4"
    :class="[
      size === 'xs'
        ? 'py-2'
        : size === 's'
        ? 'py-[8px]'
        : size === 'm'
        ? 'py-3'
        : size === 'm'
        ? 'py-[14px]'
        : 'py-4',
      border === 's' ? 'rounded-xl' : 'rounded-full',
      color === 'primary'
        ? 'bg-primary-50 text-grayscale-900'
        : color === 'secondary'
        ? 'bg-secondary text-white'
        : color === 'outline'
        ? 'border-grayscale-400 border'
        : null,
    ]"
    @click="onSubmit"
  >
    <img :alt="icon" :src="require(`@/assets/images/${icon}`)" class="me-4" />
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "ButtonIcon",
  props: {
    title: {
      type: String,
      required: true,
      default: "Button",
    },
    size: {
      validator(value) {
        return ["xs", "s", "m", "l"].includes(value);
      },
    },
    border: {
      validator(value) {
        return ["s"].includes(value);
      },
    },
    color: {
      validator(value) {
        return ["primary", "secondary", "outline"].includes(value);
      },
    },
    icon: {
      type: String,
      required: true,
      default: "86-user.svg",
    },
  },
  emits: ["click"],
  methods: {
    onSubmit() {
      this.$emit("click");
    },
  },
};
</script>