<template>
  <!-- Personal Profile -->
  <div v-if="usePersonal">
    <div class="bg-white border border-grayscale-100 rounded-xl">
      <div
        class="grid items-center justify-end grid-cols-2 px-6 py-5 border-b border-grayscale-100"
      >
        <div class="text-xl font-semibold leading-7 col">
          <span class="text-secondary">*</span>Data Pribadi
        </div>
        <div class="col">
          <img
            :alt="'86-pen.svg'"
            :src="require(`@/assets/images/86-pen.svg`)"
            class="ml-auto cursor-pointer"
            @click="handleShowPersonal"
          />
        </div>
      </div>
      <div class="px-6 py-5" v-if="!showPersonal">
        <!-- Name -->
        <div class="text-base font-semibold leading-6">Nama</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ data?.name || "-" }}
        </div>
        <!-- Nickname -->
        <div class="mt-5 text-base font-semibold leading-6">Nama Panggilan</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ data?.nickname || "-" }}
        </div>
        <!-- Username -->
        <div class="mt-5 text-base font-semibold leading-6">Username</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ data?.username || "-" }}
        </div>
        <!-- Phone -->
        <div class="mt-5 text-base font-semibold leading-6">
          Nomor Telepon/WA
        </div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ data?.phoneNumber || "-" }}
        </div>
        <!-- Address -->
        <div class="mt-5 text-base font-semibold leading-6">Alamat</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ data?.address || "-" }}
        </div>
        <!-- Email -->
        <div class="mt-5 text-base font-semibold leading-6">Email</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ data?.email || "-" }}
        </div>
        <!-- School -->
        <div class="mt-5 text-base font-semibold leading-6">Asal SMA</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ data?.highSchool || "-" }}
        </div>
        <!-- Profession -->
        <div class="mt-5 text-base font-semibold leading-6">Profesi</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ data?.occupation || "-" }}
        </div>
      </div>
      <div class="px-6 py-5" v-else>
        <!-- Name -->
        <input-label
          :title="'Nama'"
          :placeholder="'Masukan nama'"
          :value="data.name"
          v-model="data.name"
        />
        <!-- Nickname -->
        <input-label
          class="mt-5"
          :title="'Nama Panggilan'"
          :placeholder="'Masukan nama panggilan'"
          :value="data.nickname"
          v-model="data.nickname"
        />
        <!-- Username -->
        <input-label
          class="mt-5"
          :title="'Nama Username'"
          :placeholder="'Masukan nama username'"
          :value="data.username"
          v-model="data.username"
        />
        <!-- Password -->
        <div class="grid grid-cols-1 gap-4 mt-5 sm:grid-cols-2">
          <input-password
            class="col"
            :title="'Kata Sandi'"
            :useIcon="false"
            :placeholder="'Masukkan kata sandi anda'"
            v-model="data.password"
            :value="data.password"
            :messageError="messagePassword"
          />
          <input-password
            class="col"
            :title="'Konfirmasi Kata Sandi'"
            :useIcon="false"
            :placeholder="'Masukkan kembali kata sandi'"
            v-model="data.repassword"
            :value="data.repassword"
            :messageError="messagePassword"
          />
        </div>
        <!-- Phone -->
        <input-label
          class="mt-5"
          :title="'Nomor Telpon/WA'"
          :placeholder="'Masukan nomor telpon/WA'"
          :value="data.phoneNumber"
          v-model="data.phoneNumber"
        />
        <!-- Address -->
        <input-label
          class="mt-5"
          :title="'Alamat'"
          :placeholder="'Masukan alamat tempat tinggal'"
          :value="data.address"
          v-model="data.address"
        />
        <!-- Email -->
        <input-email
          class="mt-5"
          :title="'Email'"
          :useIcon="false"
          :placeholder="'Masukan email aktif'"
          v-model="data.email"
          :value="data.email"
        />
        <!-- School -->
        <input-label
          class="mt-5"
          :title="'Asal SMA'"
          :placeholder="'Masukan asal SMA'"
          :value="data.highSchool"
          v-model="data.highSchool"
        />
        <!-- Profession -->
        <input-label
          class="mt-5"
          :title="'Profesi'"
          :placeholder="'Masukan profesi sekarang'"
          :value="data.occupation"
          v-model="data.occupation"
        />
      </div>
    </div>
  </div>
  <!-- About You -->
  <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
    <div
      class="grid items-center justify-end grid-cols-2 px-6 py-5 border-b border-grayscale-100"
    >
      <div class="text-xl font-semibold leading-7 col">
        <span class="text-secondary">*</span>Tentang Anda
      </div>
      <div class="col">
        <img
          :alt="'86-pen.svg'"
          :src="require(`@/assets/images/86-pen.svg`)"
          class="ml-auto cursor-pointer"
          @click="handleShowAddAbout"
        />
      </div>
    </div>
    <div class="px-6 py-5">
      <div class="text-grayscale-600">
        <span v-if="data?.about === null || data?.about === ''">
          Anda belum mengisi kolom ini.
        </span>
        <span v-else>{{ data?.about }}</span>
      </div>
    </div>
  </div>
  <!-- Skill -->
  <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
    <div
      class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
    >
      <span class="text-secondary">*</span>
      Keahlian
    </div>
    <div class="px-6 py-5">
      <input-label
        :placeholder="'Masukkan keahlian anda'"
        :value="dummySpecialist"
        v-model="dummySpecialist"
        @onEnter="saveSpecialist"
      />
      <div class="mt-[6px] text-sm">
        Tekan <span class="font-semibold">Enter</span> untuk menyimpan
      </div>
      <div class="mt-2" v-if="specialities?.length !== 0">
        <div
          v-for="(data, index) in specialities"
          :key="index"
          class="inline-flex px-3 py-1 mt-2 mr-2 text-xs font-medium leading-4 border rounded-full border-grayscale-400"
        >
          {{ data }}
          <img
            alt="86-close"
            :src="require(`@/assets/images/86-x-mark.svg`)"
            class="ml-2 cursor-pointer"
            @click="removeListSpecialist(index)"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- Experience Skill -->
  <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
    <div
      class="grid items-center justify-end grid-cols-2 px-6 py-5 border-b border-grayscale-100"
    >
      <div class="text-xl font-semibold leading-7 col">
        <span class="text-secondary">*</span>
        Pengalaman Pekerjaan
      </div>
      <div class="col">
        <img
          :alt="'86-add.svg'"
          :src="require(`@/assets/images/86-add.svg`)"
          class="ml-auto cursor-pointer"
          @click="handleShowAddWorkingExps"
        />
      </div>
    </div>
    <div class="px-6 py-5">
      <div
        v-if="data.workingExps.filter((item) => !item.deleted).length === 0"
        class="text-grayscale-600"
      >
        Anda belum mengisi kolom ini.
      </div>
      <div v-else>
        <div
          v-for="(detail, index) in data.workingExps.filter(
            (item) => !item.deleted
          )"
          :key="index"
          class="grid grid-cols-4"
          :class="index !== 0 && 'mt-5'"
        >
          <div class="col-span-3">
            <div class="text-xl font-semibold leading-7">
              {{ detail.position || "-" }}
            </div>
            <div
              class="mt-1 text-base font-medium leading-6 text-grayscale-600"
            >
              {{ formatDateToMonthAndYear(detail.startDate) }} -
              <span v-if="detail.currentlyWorking"> sekarang </span>
              <span v-else>
                {{ formatDateToMonthAndYear(detail.endDate) }}
              </span>
              &#x2022;
              {{ convertDate(detail.endDate) - convertDate(detail.startDate) }}
              tahun
            </div>
            <div class="mt-3 text-sm font-semibold leading-5">
              {{ detail.name || "-" }}
            </div>
            <div class="mt-1 text-sm font-medium leading-6 text-grayscale-600">
              {{
                detail.jobTypeId
                  ? getJobById(detail.jobTypeId)?.name || "-"
                  : "-"
              }}
              &#x2022;
              {{ detail.address || "-" }}
            </div>
            <div class="mt-4 text-sm font-medium leading-6 text-grayscale-900">
              {{ detail.description || "-" }}
            </div>
          </div>
          <div class="col">
            <img
              :alt="'86-pen.svg'"
              :src="require(`@/assets/images/86-pen.svg`)"
              class="ml-auto cursor-pointer"
              @click="handleShowEditWorkingExps(detail)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Business -->
  <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
    <div
      class="grid items-center justify-end grid-cols-2 px-6 py-5 border-b border-grayscale-100"
    >
      <div class="text-xl font-semibold leading-7 col">
        Usaha yang Dimiliki <span class="text-grayscale-600">(Opsional)</span>
      </div>
      <div class="col">
        <img
          :alt="'86-add.svg'"
          :src="require(`@/assets/images/86-add.svg`)"
          class="ml-auto cursor-pointer"
          @click="handleShowAddBusinesses"
        />
      </div>
    </div>
    <div class="px-6 py-5">
      <div
        v-if="data.businesses.filter((item) => !item.deleted).length === 0"
        class="text-grayscale-600"
      >
        Anda belum mengisi kolom ini.
      </div>
      <div v-else>
        <div
          v-for="(detail, index) in data.businesses.filter(
            (item) => !item.deleted
          )"
          :key="index"
          class="grid grid-cols-4"
          :class="index !== 0 && 'mt-5'"
        >
          <div class="col-span-3">
            <div class="text-xl font-semibold leading-7">
              {{ detail.name }}
            </div>
            <div
              class="mt-1 text-base font-medium leading-6 text-grayscale-600"
            >
              {{ formatDateToMonthAndYear(detail.startDate) }} -
              <span v-if="detail.currentlyWorking"> sekarang </span>
              <span v-else>
                {{ formatDateToMonthAndYear(detail.endDate) }}
              </span>
              &#x2022;
              {{ convertDate(detail.endDate) - convertDate(detail.startDate) }}
              tahun
            </div>
            <div class="mt-3 text-sm font-medium leading-6 text-grayscale-600">
              {{ detail.address || "-" }}
            </div>
            <div class="mt-4 text-sm font-medium leading-6 text-grayscale-900">
              {{ detail.description || "-" }}
            </div>
          </div>
          <div class="col">
            <img
              :alt="'86-pen.svg'"
              :src="require(`@/assets/images/86-pen.svg`)"
              class="ml-auto cursor-pointer"
              @click="handleShowEditBussiness(detail)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Education -->
  <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
    <div
      class="grid items-center justify-end grid-cols-2 px-6 py-5 border-b border-grayscale-100"
    >
      <div class="text-xl font-semibold leading-7 col">
        <span class="text-secondary">*</span>Pendidikan
      </div>
      <div class="col">
        <img
          :alt="'86-add.svg'"
          :src="require(`@/assets/images/86-add.svg`)"
          class="ml-auto cursor-pointer"
          @click="handleShowAddEducations"
        />
      </div>
    </div>
    <div class="px-6 py-5">
      <div
        v-if="data.educations.filter((item) => !item.deleted).length === 0"
        class="text-grayscale-600"
      >
        Anda belum mengisi kolom ini.
      </div>
      <div v-else>
        <div
          v-for="(detail, index) in data.educations.filter(
            (item) => !item.deleted
          )"
          :key="index"
          class="grid grid-cols-4"
          :class="index !== 0 && 'mt-5'"
        >
          <div class="col-span-3">
            <div class="text-xl font-semibold leading-7">
              {{ detail.university || "-" }}
            </div>
            <div
              class="mt-1 text-base font-medium leading-6 text-grayscale-600"
            >
              {{ detail.title || "-" }},
              {{ detail.studyMajor || "-" }}
            </div>
            <div
              class="mt-1 text-base font-medium leading-6 text-grayscale-600"
            >
              {{ formatDateToMonthAndYear(detail.startDate) }} -
              {{ formatDateToMonthAndYear(detail.endDate) }}
              &#x2022;
              {{ convertDate(detail.endDate) - convertDate(detail.startDate) }}
              tahun
            </div>
            <div
              class="mt-4 text-sm font-semibold leading-6 text-grayscale-900"
            >
              IPK
            </div>
            <div class="text-sm font-medium leading-6 text-grayscale-900">
              {{ detail.gpa || "-" }}
            </div>
          </div>
          <div class="col">
            <img
              :alt="'86-pen.svg'"
              :src="require(`@/assets/images/86-pen.svg`)"
              class="ml-auto cursor-pointer"
              @click="handleShowEditEducations(detail)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Social Media -->
  <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
    <div
      class="grid items-center justify-end grid-cols-2 px-6 py-5 border-b border-grayscale-100"
    >
      <div class="text-xl font-semibold leading-7 col">
        Media Sosial <span class="text-grayscale-600">(Opsional)</span>
      </div>
      <div class="col">
        <img
          :alt="'86-pen.svg'"
          :src="require(`@/assets/images/86-pen.svg`)"
          class="ml-auto cursor-pointer"
          @click="handleShowAddSocials"
        />
      </div>
    </div>
    <div class="px-6 py-5">
      <!-- instagram -->
      <div class="flex items-center">
        <img
          alt="86-instagram"
          class="mr-3"
          :src="require(`@/assets/images/86-instagram.svg`)"
        />
        <div class="text-base leading-6">
          <span class="text-grayscale-400">Instagram.com/</span>
          <span class="text-grayscale-900">{{
            data.socials?.instagram || "-"
          }}</span>
        </div>
      </div>
      <!-- facebook -->
      <div class="flex items-center mt-4">
        <img
          alt="86-facebook"
          class="mr-3"
          :src="require(`@/assets/images/86-facebook.svg`)"
        />
        <div class="text-base leading-6">
          <span class="text-grayscale-400">Facebook.com/</span>
          <span class="text-grayscale-900">{{
            data.socials?.facebook || "-"
          }}</span>
        </div>
      </div>
      <!-- linkedin -->
      <div class="flex items-center mt-4">
        <img
          alt="86-linkedin"
          class="mr-3"
          :src="require(`@/assets/images/86-linkedin.svg`)"
        />
        <div class="text-base leading-6">
          <span class="text-grayscale-400">Linkedin.com/in/</span>
          <span class="text-grayscale-900">{{
            data.socials?.linkedin || "-"
          }}</span>
        </div>
      </div>
      <!-- twitter -->
      <div class="flex items-center mt-4">
        <img
          alt="86-twitter"
          class="mr-3"
          :src="require(`@/assets/images/86-twitter.svg`)"
        />
        <div class="text-base leading-6">
          <span class="text-grayscale-400">Twitter.com/</span>
          <span class="text-grayscale-900">{{
            data.socials?.twitter || "-"
          }}</span>
        </div>
      </div>
      <!-- tiktok -->
      <div class="flex items-center mt-4">
        <img
          alt="86-tiktok"
          class="mr-3"
          :src="require(`@/assets/images/86-tiktok.svg`)"
        />
        <div class="text-base leading-6">
          <span class="text-grayscale-400">Tiktok.com/</span>
          <span class="text-grayscale-900">{{
            data.socials?.tiktok || "-"
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-end mt-4" v-if="withAction">
    <button-general
      :title="'Batal'"
      :size="'s'"
      :color="'outline'"
      :width="'auto'"
      class="mr-[6px] w-32"
      @click="cancelData"
    />
    <button-general
      :title="'Simpan'"
      :size="'s'"
      :color="'primary'"
      :width="'auto'"
      class="w-32"
      @click="handleShowConfirmationAdd"
    />
  </div>
  <!-- input about -->
  <sidebar-template
    :title="'Informasi Pengguna'"
    :show="showAddAbout"
    @close="handleShowAddAbout"
  >
    <div class="px-10 py-6 text-justify border-t">
      <div class="font-semibold">Tentang</div>
      <div class="mt-3 text-sm text-grayscale-500">
        Paparkan deskripsi diri, pengalaman, industri dan juga keahlian Anda.
        Orang juga menceritakan tentang pencapain atau pengalamn kerja mereka
        sebelumnya.
      </div>
      <textarea
        v-model="dummyAbout"
        placeholder="Masukan diskripsi Acara"
        rows="4"
        class="w-full px-3 py-2 mt-8 border rounded-lg border-grayscale-200"
      />
    </div>
    <div
      class="fixed bottom-0 w-full px-10 py-5 sm:w-2/4"
      style="box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.08)"
    >
      <button-general
        :title="'Simpan'"
        :size="'s'"
        :border="'s'"
        :color="'primary'"
        @click="saveAbout"
      />
    </div>
  </sidebar-template>
  <!-- input work -->
  <sidebar-template
    :title="'Informasi Pengguna'"
    :show="showAddWorkingExps"
    @close="handleShowAddWorkingExps"
  >
    <div class="px-10 pt-6 text-justify border-t pb-28">
      <div class="font-semibold">Pengalaman Pekerjaan</div>
      <div class="mt-3 text-sm text-grayscale-500">
        Masukkan ringkasan yang padat dan informatif tentang pengalaman kerja
        Anda untuk membantu orang lain memahami latar belakang profesional Anda.
        Sertakan detail yang relevan tentang peran dan tanggung jawab
        sebelumnya.
      </div>
      <div class="mt-8">
        <input-label
          :title="'Posisi'"
          :placeholder="'Masukan Posisi'"
          :value="dummyWorkingExps.position"
          v-model="dummyWorkingExps.position"
        />
        <div class="mt-4">
          <label class="mb-1 text-left">Jenis Pekerjaan</label>
          <select
            v-model="dummyWorkingExps.jobTypeId"
            class="w-full flex border rounded-lg py-[10px] px-[10px]"
          >
            <option disabled value="">Masukan Jenis Pekerjaan</option>
            <option
              v-for="(item, index) in listJob"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <p
            v-show="messageWorkingExps !== ''"
            class="text-red-main text-sm font-normal leading-5 mt-[6px]"
          >
            {{ messageWorkingExps }}
          </p>
        </div>
        <input-label
          :title="'Nama Perusahaan'"
          :placeholder="'Masukan Nama Perusahaan'"
          class="mt-4"
          :value="dummyWorkingExps.name"
          v-model="dummyWorkingExps.name"
        />
        <input-label
          :title="'Lokasi'"
          :placeholder="'Masukan Lokasi'"
          class="mt-4"
          :value="dummyWorkingExps.address"
          v-model="dummyWorkingExps.address"
        />
        <div class="grid grid-cols-2 gap-4 mt-4">
          <div class="col">
            <label>Bulan Mulai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyWorkingExps.monthStart"
                placeholder="Masukan Bulan Mulai"
                type="month"
                value-type="format"
                format="MMMM"
              />
            </div>
          </div>
          <div class="col">
            <label>Tahun Mulai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyWorkingExps.yearStart"
                placeholder="Masukan Tahun Mulai"
                type="year"
                value-type="format"
                format="YYYY"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center my-4">
          <input
            type="checkbox"
            v-model="lastWorkingExps"
            id="checkUsingEndDate"
            class="mr-2"
          />
          <label for="checkUsingEndDate"> Saya masih bekerja disini </label>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4" v-if="!lastWorkingExps">
          <div class="col">
            <label>Bulan Selesai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyWorkingExps.monthEnd"
                placeholder="Masukan Bulan Selesai"
                type="month"
                value-type="format"
                format="MMMM"
              />
            </div>
          </div>
          <div class="col">
            <label>Tahun Selesai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyWorkingExps.yearEnd"
                placeholder="Masukan Tahun Selesai"
                type="year"
                value-type="format"
                format="YYYY"
              />
            </div>
          </div>
        </div>
        <textarea
          v-model="dummyWorkingExps.description"
          placeholder="Masukkan Deksripsi Pekerjaan"
          rows="4"
          class="w-full px-3 py-2 mt-4 border rounded-lg border-grayscale-200"
        />
      </div>
    </div>
    <div
      class="fixed bottom-0 flex justify-between w-full gap-6 px-10 py-5 bg-white sm:w-2/4"
      style="box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.08)"
    >
      <button-general
        v-if="editWorkingExps"
        :title="'Hapus'"
        :size="'s'"
        :border="'s'"
        :color="'outline'"
        @click="deletingWorkExpId = dummyWorkingExps.id"
      />
      <button-general
        :title="'Simpan'"
        :size="'s'"
        :border="'s'"
        :color="'primary'"
        @click="saveWorkingExps"
      />
    </div>
  </sidebar-template>
  <!-- businesses -->
  <sidebar-template
    :title="'Informasi Pengguna'"
    :show="showAddBusinesses"
    @close="handleShowAddBusinesses"
  >
    <div class="px-10 pt-6 text-justify border-t pb-28">
      <div class="font-semibold">Jenis Usaha</div>
      <div class="mt-3 text-sm text-grayscale-500">
        Masukkan ringkasan yang padat dan informatif tentang usaha yang sedang
        Anda jalani. Sertakan detail untuk membantu orang lain memahami usaha
        Anda.
      </div>
      <div class="mt-8">
        <input-label
          :title="'Nama Perusahaan'"
          :placeholder="'Masukan Nama Perusahaan'"
          :value="dummyBusinesses.name"
          v-model="dummyBusinesses.name"
        />
        <input-label
          :title="'Lokasi'"
          :placeholder="'Masukan Lokasi'"
          class="mt-4"
          :value="dummyBusinesses.address"
          v-model="dummyBusinesses.address"
        />
        <div class="grid grid-cols-2 gap-4 mt-4">
          <div class="col">
            <label>Bulan Mulai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyBusinesses.monthStart"
                placeholder="Masukan Bulan Mulai"
                type="month"
                value-type="format"
                format="MMMM"
              />
            </div>
          </div>
          <div class="col">
            <label>Tahun Mulai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyBusinesses.yearStart"
                placeholder="Masukan Tahun Mulai"
                type="year"
                value-type="format"
                format="YYYY"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center my-4">
          <input
            type="checkbox"
            v-model="lastBusinesses"
            id="checkUsingEndDate"
            class="mr-2"
          />
          <label for="checkUsingEndDate"> Usaha masih berjalan </label>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4" v-if="!lastBusinesses">
          <div class="col">
            <label>Bulan Selesai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyBusinesses.monthEnd"
                placeholder="Masukan Bulan Selesai"
                type="month"
                value-type="format"
                format="MMMM"
              />
            </div>
          </div>
          <div class="col">
            <label>Tahun Selesai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyBusinesses.yearEnd"
                placeholder="Masukan Tahun Selesai"
                type="year"
                value-type="format"
                format="YYYY"
              />
            </div>
          </div>
        </div>
        <textarea
          v-model="dummyBusinesses.description"
          placeholder="Masukkan Deksripsi Usaha"
          rows="4"
          class="w-full px-3 py-2 mt-4 border rounded-lg border-grayscale-200"
        />
      </div>
    </div>
    <div
      class="fixed bottom-0 flex justify-between w-full gap-6 px-10 py-5 bg-white sm:w-2/4"
      style="box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.08)"
    >
      <button-general
        v-if="editBusinesses"
        :title="'Hapus'"
        :size="'s'"
        :border="'s'"
        :color="'outline'"
        @click="deletingBusinessId = dummyBusinesses.id"
      />
      <button-general
        :title="'Simpan'"
        :size="'s'"
        :border="'s'"
        :color="'primary'"
        @click="saveBusinesses"
      />
    </div>
  </sidebar-template>
  <!-- educations -->
  <sidebar-template
    :title="'Informasi Pengguna'"
    :show="showAddEducations"
    @close="handleShowAddEducations"
  >
    <div class="px-10 pt-6 text-justify border-t pb-28">
      <div class="font-semibold">Pendidikan</div>
      <div class="mt-3 text-sm text-grayscale-500">
        Masukkan pengalaman pendidikan Anda, untuk memberikan gambaran singkat
        tentang kualifikasi dan keahlian akademik Anda.
      </div>
      <div class="mt-8">
        <input-label
          :title="'Nama Universitas'"
          :placeholder="'Masukan Nama Universitas'"
          :value="dummyEducations.university"
          v-model="dummyEducations.university"
        />
        <input-label
          :title="'Gelar'"
          :placeholder="'Masukan Gelar'"
          class="mt-4"
          :value="dummyEducations.title"
          v-model="dummyEducations.title"
        />
        <input-label
          :title="'Bidang Studi'"
          :placeholder="'Masukan Bidang Studi'"
          class="mt-4"
          :value="dummyEducations.studyMajor"
          v-model="dummyEducations.studyMajor"
        />
        <div class="grid grid-cols-2 gap-4 mt-4">
          <div class="col">
            <label>Bulan Mulai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyEducations.monthStart"
                placeholder="Masukan Bulan Mulai"
                type="month"
                value-type="format"
                format="MMMM"
              />
            </div>
          </div>
          <div class="col">
            <label>Tahun Mulai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyEducations.yearStart"
                placeholder="Masukan Tahun Mulai"
                type="year"
                value-type="format"
                format="YYYY"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <div class="col">
            <label>Bulan Selesai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyEducations.monthEnd"
                placeholder="Masukan Bulan Selesai"
                type="month"
                value-type="format"
                format="MMMM"
              />
            </div>
          </div>
          <div class="col">
            <label>Tahun Selesai</label>
            <div class="mt-[6px] w-full">
              <date-picker
                v-model:value="dummyEducations.yearEnd"
                placeholder="Masukan Tahun Selesai"
                type="year"
                value-type="format"
                format="YYYY"
              />
            </div>
          </div>
        </div>
        <input-label
          :title="'Nilai IPK (max 4.00)'"
          :placeholder="'Masukan Nilai IPK (max 4.00)'"
          class="mt-4"
          :value="dummyEducations.gpa"
          v-model="dummyEducations.gpa"
          type="number"
        />
      </div>
    </div>
    <div
      class="fixed bottom-0 flex justify-between w-full gap-6 px-10 py-5 bg-white sm:w-2/4"
      style="box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.08)"
    >
      <button-general
        v-if="editEducations"
        :title="'Hapus'"
        :size="'s'"
        :border="'s'"
        :color="'outline'"
        @click="deletingEducationId = dummyEducations.id"
      />
      <button-general
        :title="'Simpan'"
        :size="'s'"
        :border="'s'"
        :color="'primary'"
        @click="saveEducations"
      />
    </div>
  </sidebar-template>
  <!-- socials -->
  <sidebar-template
    :title="'Informasi Pengguna'"
    :show="showAddSocials"
    @close="handleShowAddSocials"
  >
    <div class="px-10 py-6 text-justify border-t">
      <div class="font-semibold">Media Sosial</div>
      <div class="mt-3 text-sm text-grayscale-500">
        Isikan data social media anda agar memudahkan orang lain menemukan anda
        di social media. Pastikan seluruh data yang dimasukkan sudah benar.
      </div>
      <div class="mt-8">
        <div>
          <label class="mb-1">Instagram</label>
          <div class="flex border rounded-lg py-[10px] px-[10px]">
            <div class="text-grayscale-400">Instagram.com/</div>
            <input
              class="flex-1 mr-3 text-base font-normal leading-6 focus:outline-none"
              v-model="dummySocials.instagram"
            />
          </div>
        </div>
        <div>
          <label class="mb-1">Facebook</label>
          <div class="flex border rounded-lg py-[10px] px-[10px]">
            <div class="text-grayscale-400">Facebook.com/</div>
            <input
              class="flex-1 mr-3 text-base font-normal leading-6 focus:outline-none"
              v-model="dummySocials.facebook"
            />
          </div>
        </div>
        <div>
          <label class="mb-1">Linkedin</label>
          <div class="flex border rounded-lg py-[10px] px-[10px]">
            <div class="text-grayscale-400">Linkedin.com/in/</div>
            <input
              class="flex-1 mr-3 text-base font-normal leading-6 focus:outline-none"
              v-model="dummySocials.linkedin"
            />
          </div>
        </div>
        <div>
          <label class="mb-1">Twitter</label>
          <div class="flex border rounded-lg py-[10px] px-[10px]">
            <div class="text-grayscale-400">Twitter.com/</div>
            <input
              class="flex-1 mr-3 text-base font-normal leading-6 focus:outline-none"
              v-model="dummySocials.twitter"
            />
          </div>
        </div>
        <div>
          <label class="mb-1">Tiktok</label>
          <div class="flex border rounded-lg py-[10px] px-[10px]">
            <div class="text-grayscale-400">Tiktok.com/</div>
            <input
              class="flex-1 mr-3 text-base font-normal leading-6 focus:outline-none"
              v-model="dummySocials.tiktok"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="fixed bottom-0 w-full px-10 py-5 bg-white sm:w-2/4"
      style="box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.08)"
    >
      <button-general
        :title="'Simpan'"
        :size="'s'"
        :border="'s'"
        :color="'primary'"
        @click="saveSocials"
      />
    </div>
  </sidebar-template>
  <modal-template
    @close="deletingWorkExpId = null"
    :show="deletingWorkExpId"
    status="warning"
    :show-icon="true"
    icon-type="warning"
  >
    <div class="px-5 pb-5 text-left sm:last:pb-6 sm:last:px-6">
      <div class="text-lg font-semibold">Hapus Informasi</div>
      <div class="text-sm text-grayscale-500">
        Apakah anda yakin ingin menghapus informasi pada data pribadi anda?
      </div>
    </div>
    <div class="flex justify-end p-5 sm:p-6">
      <button-general
        :title="'Batal'"
        :size="'s'"
        :width="'full'"
        :color="'outline'"
        class="mr-[6px]"
        @click="deletingWorkExpId = null"
      />
      <button-general
        :title="'Hapus'"
        :size="'s'"
        :width="'full'"
        :color="'secondary'"
        @click="deleteWorkingExps(deletingWorkExpId)"
      />
    </div>
  </modal-template>
  <modal-template
    @close="deletingBusinessId = null"
    :show="deletingBusinessId"
    status="warning"
    :show-icon="true"
    icon-type="warning"
  >
    <div class="px-5 pb-5 text-left sm:last:pb-6 sm:last:px-6">
      <div class="text-lg font-semibold">Hapus Informasi</div>
      <div class="text-sm text-grayscale-500">
        Apakah anda yakin ingin menghapus informasi pada data pribadi anda?
      </div>
    </div>
    <div class="flex justify-end p-5 sm:p-6">
      <button-general
        :title="'Batal'"
        :size="'s'"
        :width="'full'"
        :color="'outline'"
        class="mr-[6px]"
        @click="deletingBusinessId = null"
      />
      <button-general
        :title="'Hapus'"
        :size="'s'"
        :width="'full'"
        :color="'secondary'"
        @click="deleteBusiness(deletingBusinessId)"
      />
    </div>
  </modal-template>
  <modal-template
    @close="deletingEducationId = null"
    :show="deletingEducationId"
    status="warning"
    :show-icon="true"
    icon-type="warning"
  >
    <div class="px-5 pb-5 text-left sm:last:pb-6 sm:last:px-6">
      <div class="text-lg font-semibold">Hapus Informasi</div>
      <div class="text-sm text-grayscale-500">
        Apakah anda yakin ingin menghapus informasi pada data pribadi anda?
      </div>
    </div>
    <div class="flex justify-end p-5 sm:p-6">
      <button-general
        :title="'Batal'"
        :size="'s'"
        :width="'full'"
        :color="'outline'"
        class="mr-[6px]"
        @click="deletingEducationId = null"
      />
      <button-general
        :title="'Hapus'"
        :size="'s'"
        :width="'full'"
        :color="'secondary'"
        @click="deleteEducation(deletingEducationId)"
      />
    </div>
  </modal-template>
  <confirmation
    v-if="mode === 'edit'"
    :title="'Simpan perubahan'"
    :message="'Apakah anda yakin mengubah data untuk user ini? Pastikan data yang anda ubah sudah benar.'"
    :show="showConfirmationAdd"
    :status="'alert'"
    @close="handleShowConfirmationAdd"
    @submit="submitData"
  />
  <confirmation
    v-else
    :title="'Tambah Pengguna'"
    :message="'Apakah anda yakin ingin manambah pengguna baru ini? Pastikan data yang anda ubah sudah benar.'"
    :show="showConfirmationAdd"
    :status="'alert'"
    @close="handleShowConfirmationAdd"
    @submit="submitData"
  />
</template>

<script>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import moment from "moment";
import InputLabel from "@/components/inputs/inputLabel.vue";
import SidebarTemplate from "@/components/sidebarTemplate.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import ModalTemplate from "@/components/modalTemplate.vue";
import DatePicker from "vue-datepicker-next";
import Confirmation from "@/components/confirmation.vue";
import "vue-datepicker-next/index.css";

export default {
  name: "ProfileEdit",
  components: {
    InputLabel,
    SidebarTemplate,
    ButtonGeneral,
    DatePicker,
    ModalTemplate,
    Confirmation,
  },
  props: {
    usePersonal: {
      type: Boolean,
      default: true,
    },
    detail: {
      type: Array,
      default: () => [],
    },
    withAction: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: () => "edit",
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const data = computed(() => {
      return props.detail;
    });
    // ref({ ...props.detail });
    const showConfirmationAdd = ref(false);

    const handleShowConfirmationAdd = () => {
      showConfirmationAdd.value = !showConfirmationAdd.value;
    };
    const specialities = ref(
      props?.detail?.specialities ? props?.detail?.specialities?.split(",") : []
    );
    const convertDate = (value) => {
      if (!value) {
        return moment().format("YYYY");
      }
      return moment(value, "DD/MM/YYYY").lang("id").format("YYYY");
    };
    const convertMonth = (value) => {
      return moment(value !== "" ? value : moment(), "MMMM")
        .lang("id")
        .format("MM");
    };
    const convertEditMonth = (value) => {
      return moment(value !== "" ? value : moment(), "DD/MM/YYYY")
        .lang("en")
        .format("MMMM");
    };
    const convertYear = (value) => {
      return moment(value !== "" ? value : moment(), "YYYY")
        .lang("id")
        .format("YYYY");
    };
    const convertEditYear = (value) => {
      return moment(value !== "" ? value : moment(), "DD/MM/YYYY")
        .lang("id")
        .format("YYYY");
    };
    const formatDateToMonthAndYear = (date) => {
      return moment(date, "DD/MM/YYYY").lang("id").format("MMMM YYYY");
    };
    const showPersonal = ref(false);
    const handleShowPersonal = () => {
      showPersonal.value = !showPersonal.value;
    };
    //  about
    const showAddAbout = ref(false);
    const dummyAbout = ref("");
    const handleShowAddAbout = () => {
      dummyAbout.value = data.value.about;
      showAddAbout.value = !showAddAbout.value;
    };
    const saveAbout = () => {
      data.value.about = dummyAbout.value;
      handleShowAddAbout();
    };

    // specialist
    const dummySpecialist = ref("");
    const saveSpecialist = (detail) => {
      dummySpecialist.value = "";
      specialities.value.push(detail);
      data.value.specialities = specialities.value.join();
    };
    const removeListSpecialist = (index) => {
      specialities.value.splice(index, 1);
      data.value.specialities = specialities.value.join();
    };

    // workingExps
    const listJob = computed(() => {
      return store.getters.getListJob;
    });
    const showAddWorkingExps = ref(false);
    const editWorkingExps = ref(false);
    const dummyWorkingExps = ref({
      position: "",
      jobTypeId: "",
      name: "",
      address: "",
      monthStart: "",
      yearStart: "",
      monthEnd: "",
      yearEnd: "",
      description: "",
      currentlyWorking: false,
      deleted: null,
    });
    const lastWorkingExps = ref(false);
    const messageWorkingExps = ref("");
    const deletingWorkExpId = ref(null);
    const deletingBusinessId = ref(null);
    const deletingEducationId = ref(null);

    const handleShowAddWorkingExps = () => {
      showAddWorkingExps.value = !showAddWorkingExps.value;
      if (!showAddWorkingExps.value) {
        dummyWorkingExps.value = {
          position: "",
          jobTypeId: "",
          name: "",
          address: "",
          monthStart: "",
          yearStart: "",
          monthEnd: "",
          yearEnd: "",
          description: "",
          currentlyWorking: false,
          deleted: null,
        };
        editWorkingExps.value = false;
      }
    };

    const handleShowEditWorkingExps = (detail) => {
      editWorkingExps.value = true;
      dummyWorkingExps.value = {
        id: detail.id,
        position: detail.position,
        jobTypeId: detail.jobTypeId,
        name: detail.name,
        address: detail.address,
        monthStart: convertEditMonth(detail.startDate),
        yearStart: convertEditYear(detail.startDate),
        monthEnd: convertEditMonth(detail.endDate),
        yearEnd: convertEditYear(detail.endDate),
        currentlyWorking: detail.currentlyWorking,
        description: detail.description,
        deleted: detail.deleted,
      };
      lastWorkingExps.value = detail.currentlyWorking;
      handleShowAddWorkingExps();
    };

    const saveWorkingExps = () => {
      if (dummyWorkingExps.value.jobTypeId === "") {
        messageWorkingExps.value = "Wajib Diisi";
      } else {
        let endDate = lastWorkingExps.value
          ? null
          : `10/${convertMonth(dummyWorkingExps.value?.monthEnd)}/${convertYear(
              dummyWorkingExps.value?.yearEnd
            )}`;
        console.log(lastWorkingExps.value);
        const detail = {
          position: dummyWorkingExps.value.position,
          jobTypeId: dummyWorkingExps.value.jobTypeId,
          name: dummyWorkingExps.value.name,
          address: dummyWorkingExps.value.address,
          startDate: `10/${convertMonth(
            dummyWorkingExps.value.monthStart
          )}/${convertYear(dummyWorkingExps.value.yearStart)}`,
          endDate: endDate,
          currentlyWorking: lastWorkingExps.value,
          description: dummyWorkingExps.value.description,
          deleted: dummyWorkingExps.value.deleted,
        };
        if (editWorkingExps.value) {
          detail.id = dummyWorkingExps.value.id;
          const index = data.value.workingExps.findIndex(
            (exp) => exp.id == detail.id
          );
          data.value.workingExps[index] = detail;
          editWorkingExps.value = false;
        } else {
          data.value.workingExps.push(detail);
        }
        dummyWorkingExps.value = {
          position: "",
          jobTypeId: "",
          name: "",
          address: "",
          monthStart: "",
          yearStart: "",
          monthEnd: "",
          yearEnd: "",
          description: "",
          currentlyWorking: false,
          deleted: null,
        };
        lastWorkingExps.value = false;
        handleShowAddWorkingExps();
      }
    };

    const deleteWorkingExps = (workingExpId) => {
      const index = data.value.workingExps.findIndex(
        (exp) => exp.id == workingExpId
      );
      data.value.workingExps[index].deleted = true;
      editWorkingExps.value = false;
      handleShowAddWorkingExps();
      deletingWorkExpId.value = null;
    };

    // businesses
    const showAddBusinesses = ref(false);
    const editBusinesses = ref(false);
    const dummyBusinesses = ref({
      name: "",
      address: "",
      monthStart: "",
      yearStart: "",
      monthEnd: "",
      yearEnd: "",
      description: "",
      lastBusinesses: false,
    });
    const lastBusinesses = ref(false);

    const handleShowAddBusinesses = () => {
      showAddBusinesses.value = !showAddBusinesses.value;
      if (!showAddBusinesses.value) {
        dummyBusinesses.value = {
          name: "",
          address: "",
          monthStart: "",
          yearStart: "",
          monthEnd: "",
          yearEnd: "",
          description: "",
          lastBusinesses: false,
        };
        editBusinesses.value = false;
      }
    };

    const handleShowEditBussiness = (detail) => {
      editBusinesses.value = true;
      dummyBusinesses.value = {
        id: detail.id,
        name: detail.name,
        address: detail.address,
        monthStart: convertEditMonth(detail.startDate),
        yearStart: convertEditYear(detail.startDate),
        monthEnd: convertEditMonth(detail.endDate),
        yearEnd: convertEditYear(detail.endDate),
        currentlyWorking: detail.currentlyWorking,
        description: detail.description,
      };
      lastBusinesses.value = detail.currentlyWorking;
      handleShowAddBusinesses();
    };

    const saveBusinesses = () => {
      let endDate = lastBusinesses.value
        ? null
        : `10/${convertMonth(dummyWorkingExps.value?.monthEnd)}/${convertYear(
            dummyWorkingExps.value?.yearEnd
          )}`;
      const detail = {
        name: dummyBusinesses.value.name,
        address: dummyBusinesses.value.address,
        startDate: `10/${convertMonth(
          dummyBusinesses.value.monthStart
        )}/${convertYear(dummyBusinesses.value.yearStart)}`,
        endDate: endDate,
        description: dummyBusinesses.value.description,
        currentlyWorking: lastBusinesses.value,
      };
      if (editBusinesses.value) {
        detail.id = dummyBusinesses.value.id;
        const index = data.value.businesses.findIndex(
          (business) => business.id == detail.id
        );
        data.value.businesses[index] = detail;
        editBusinesses.value = false;
      } else {
        data.value.businesses.push(detail);
      }
      dummyBusinesses.value = {
        name: "",
        address: "",
        monthStart: "",
        yearStart: "",
        monthEnd: "",
        yearEnd: "",
        description: "",
        lastBusinesses: false,
      };
      lastBusinesses.value = false;
      handleShowAddBusinesses();
    };

    const deleteBusiness = (businessId) => {
      const index = data.value.businesses.findIndex(
        (item) => item.id == businessId
      );
      data.value.businesses[index].deleted = true;
      editBusinesses.value = false;
      handleShowAddBusinesses();
      deletingBusinessId.value = null;
    };

    // educations
    const showAddEducations = ref(false);
    const editEducations = ref(false);
    const dummyEducations = ref({
      university: "",
      title: "",
      studyMajor: "",
      monthStart: "",
      yearStart: "",
      monthEnd: "",
      yearEnd: "",
      gpa: 0,
      description: "",
    });

    const handleShowAddEducations = () => {
      showAddEducations.value = !showAddEducations.value;
      if (!showAddEducations.value) {
        dummyEducations.value = {
          university: "",
          title: "",
          studyMajor: "",
          monthStart: "",
          yearStart: "",
          monthEnd: "",
          yearEnd: "",
          gpa: 0,
          description: "",
        };
        editEducations.value = false;
      }
    };

    const handleShowEditEducations = (detail) => {
      editEducations.value = true;
      dummyEducations.value = {
        id: detail.id,
        university: detail.university,
        title: detail.title,
        studyMajor: detail.studyMajor,
        monthStart: convertEditMonth(detail.startDate),
        yearStart: convertEditYear(detail.startDate),
        monthEnd: convertEditMonth(detail.endDate),
        yearEnd: convertEditYear(detail.endDate),
        gpa: detail.gpa,
        description: detail.description,
        deleted: detail.deleted,
      };
      handleShowAddEducations();
    };

    const saveEducations = () => {
      const detail = {
        university: dummyEducations.value.university,
        title: dummyEducations.value.title,
        studyMajor: dummyEducations.value.studyMajor,
        startDate: `10/${convertMonth(
          dummyEducations.value.monthStart
        )}/${convertYear(dummyEducations.value.yearStart)}`,
        endDate: `10/${convertMonth(
          dummyEducations.value?.monthEnd
        )}/${convertYear(dummyEducations.value?.yearEnd)}`,
        gpa: Number(dummyEducations.value.gpa),
        description: dummyEducations.value.description,
      };
      if (editEducations.value) {
        detail.id = dummyEducations.value.id;
        const index = data.value.educations.findIndex(
          (education) => education.id == detail.id
        );
        data.value.educations[index] = detail;
        editEducations.value = false;
      } else {
        data.value.educations.push(detail);
      }
      dummyEducations.value = {
        university: "",
        title: "",
        studyMajor: "",
        monthStart: "",
        yearStart: "",
        monthEnd: "",
        yearEnd: "",
        gpa: 0,
        description: "",
      };
      handleShowAddEducations();
    };

    const deleteEducation = (educationId) => {
      const index = data.value.educations.findIndex(
        (item) => item.id == educationId
      );
      data.value.educations[index].deleted = true;
      editEducations.value = false;
      handleShowAddEducations();
      deletingEducationId.value = null;
    };

    // socials
    const showAddSocials = ref(false);
    const dummySocials = ref({
      ...data.value.socials,
      instagram: "",
      facebook: "",
      linkedin: "",
      twitter: "",
      tiktok: "",
    });

    const handleShowAddSocials = () => {
      showAddSocials.value = !showAddSocials.value;
    };

    const saveSocials = () => {
      const detail = {
        instagram: dummySocials.value.instagram,
        facebook: dummySocials.value.facebook,
        linkedin: dummySocials.value.linkedin,
        twitter: dummySocials.value.twitter,
        tiktok: dummySocials.value.tiktok,
      };
      data.value.socials = detail;
      handleShowAddSocials();
    };

    const cancelData = () => {
      emit("cancel");
    };

    const submitData = () => {
      handleShowConfirmationAdd();
      emit("submit", data.value);
    };

    const getJobById = (id) => {
      return listJob.value.find((job) => job.id == id);
    };

    onMounted(() => {
      store.dispatch("getListJob");
    });

    return {
      data,
      showConfirmationAdd,
      handleShowConfirmationAdd,
      specialities,
      convertDate,
      convertMonth,
      convertYear,
      formatDateToMonthAndYear,
      showPersonal,
      handleShowPersonal,
      showAddAbout,
      dummyAbout,
      handleShowAddAbout,
      saveAbout,
      dummySpecialist,
      saveSpecialist,
      removeListSpecialist,
      listJob,
      getJobById,
      showAddWorkingExps,
      dummyWorkingExps,
      lastWorkingExps,
      messageWorkingExps,
      handleShowAddWorkingExps,
      handleShowEditWorkingExps,
      editWorkingExps,
      saveWorkingExps,
      deleteWorkingExps,
      showAddBusinesses,
      dummyBusinesses,
      lastBusinesses,
      handleShowAddBusinesses,
      handleShowEditBussiness,
      editBusinesses,
      saveBusinesses,
      deleteBusiness,
      showAddEducations,
      dummyEducations,
      handleShowAddEducations,
      handleShowEditEducations,
      editEducations,
      saveEducations,
      deleteEducation,
      showAddSocials,
      dummySocials,
      handleShowAddSocials,
      saveSocials,
      cancelData,
      submitData,
      moment,
      deletingWorkExpId,
      deletingBusinessId,
      deletingEducationId,
    };
  },
};
</script>
