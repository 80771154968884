<template>
  <div class="text-left">
    <label class="mb-1">{{ title }}</label>
    <div
      class="mt-1 flex border rounded-lg py-[10px] px-[10px]"
      :class="[
        messageError !== ''
          ? 'border-red-main'
          : focus
          ? 'border-grayscale-900'
          : 'border-grayscale-200',
        disabled ? 'bg-grayscale-50' : '',
      ]"
    >
      <input
        class="flex-1 text-base font-normal leading-6 bg-transparent focus:outline-none"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('update:modelValue', $event.target.value)"
        @keyup.enter="$emit('onEnter', $event.target.value)"
        @focus="onFocus"
        @blur="onLeave"
        autocomplete="off"
        :disabled="disabled"
        :type="type"
      />
    </div>
    <p
      v-show="messageError !== ''"
      class="text-red-main text-sm font-normal leading-5 mt-[6px]"
    >
      {{ messageError }}
    </p>
  </div>
</template>

<script>
export default {
  name: "InputLabel",
  data() {
    return {
      focus: false,
    };
  },
  props: {
    title: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
    messageError: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
    },
    type: {
      type: String,
      default: () => "text",
    },
  },
  emits: ["update:value", "onEnter"],
  methods: {
    onFocus() {
      this.focus = true;
    },
    onLeave() {
      this.focus = false;
    },
    onEnterInput() {},
  },
};
</script>
