<template>
  <div>
    <div class="text-lg font-semibold leading-7 mb-3">{{ title }}</div>
    <div class="text-sm leading-5 text-grayscale-400 mb-6">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "DetailUser",
  props: {
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    content: {
      type: String,
      required: true,
      default: "Content",
    },
  },
};
</script>