<template>
  <div>
    <div class="flex w-full">
      <sidebar />
      <div class="flex-1 w-full min-w-0">
        <topbar />
        <div
          class="bg-grayscale-50 py-4 px-5 text-left mt-[72px] sm:mt-0"
          style="min-height: calc(100vh - 72px)"
        >
          <breadcrumb :title="'Pengguna'" :list="listMenu" />
          <loading-table v-if="loading" />
          <table-user
            v-else
            class="mt-5"
            :count="totalUser"
            :page="page"
            :totalPage="totalPage"
            :list="listUser"
            :username="username"
            :selected="selectedFilter"
            @searchUsername="searchByUsername"
            @searchFilter="searchFilter"
            @import="handleShowImport"
            @before="beforePage"
            @next="nextPage"
            @deleteUser="deleteUser"
          />
        </div>
      </div>
    </div>
    <!-- import -->
    <sidebar-template
      :title="'Import Pengguna'"
      :show="showImport"
      @close="handleShowImport"
    >
      <div class="px-10 py-6">
        <div class="text-left">
          <div class="font-semibold">Import Anggota Komunitas Angkatan 86</div>
          <div class="mt-3 text-xs text-grayscale-500">
            Anda dapat melakukan import file excel untuk mengupload semua
            anggota angkata 86 yang sudah mendaftar melalui google form
          </div>
        </div>
        <div
          class="p-4 mt-4 border rounded-xl"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <input
            type="file"
            multiple
            name="fields[assetsFieldHandle][]"
            id="assetsFieldHandle"
            class="absolute w-px h-px overflow-hidden opacity-0"
            @change="onUpdateFile"
            ref="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />

          <label
            for="assetsFieldHandle"
            class="flex flex-col items-center cursor-pointer"
          >
            <img
              alt="86-import"
              :src="require(`@/assets/images/86-import-2.svg`)"
              width="40"
            />
            <div class="mt-3 text-sm">
              <span class="font-semibold text-primary-50">
                Click to upload
              </span>
              or drag and drop
            </div>
            <div class="text-xs">Upload File Excel (max. 10mb)</div>
          </label>
        </div>
        <div v-if="filelist.length">
          <div
            class="flex items-start p-4 mt-6 border rounded-xl"
            v-for="(file, index) in filelist"
            :key="index"
          >
            <img
              alt="86-file"
              :src="require(`@/assets/images/86-file-2.svg`)"
              width="40"
            />
            <div class="flex-1 ml-4 text-sm text-left">
              <div class="font-medium">
                {{ file.name }}
              </div>
              <div class="text-grayscale-600">{{ file.size }}</div>
            </div>
            <img
              alt="86-close"
              :src="require(`@/assets/images/86-close.svg`)"
              width="16"
              @click="remove(filelist.indexOf(index))"
            />
          </div>
        </div>
      </div>
      <div
        class="fixed bottom-0 w-full px-10 py-5 bg-white sm:w-2/4"
        style="box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.08)"
      >
        <button-general
          :title="'Simpan'"
          :size="'s'"
          :border="'s'"
          :color="'primary'"
          @click="handleShowConfirmationImport"
        />
      </div>
    </sidebar-template>
    <confirmation
      :title="'Menambah pengguna'"
      :message="'Apakah Anda yakin menambah pengguna?'"
      :show="showConfirmationImport"
      :status="'alert'"
      @close="handleShowConfirmationImport"
      @submit="saveImport"
    />
  </div>
</template>

<script>
import { ref, onMounted, computed, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import Sidebar from "@/components/layouts/sidebar.vue";
import Topbar from "@/components/layouts/topbar.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import TableUser from "@/components/tables/tableUser.vue";
import SidebarTemplate from "@/components/sidebarTemplate.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import Confirmation from "@/components/confirmation.vue";
import LoadingTable from "@/components/loading/loadingTable.vue";
import * as XLSX from "xlsx";

export default {
  components: {
    Sidebar,
    Topbar,
    Breadcrumb,
    TableUser,
    SidebarTemplate,
    ButtonGeneral,
    Confirmation,
    LoadingTable,
  },
  name: "ListUser",
  setup() {
    const store = useStore();
    const loading = ref(false);
    const listMenu = ref([
      {
        title: "Beranda",
        link: "/admin",
      },
      {
        title: "List Pengguna",
        link: "/admin/user",
      },
    ]);
    const totalUser = computed(() => {
      return store.getters.getTotalUser;
    });
    const page = computed(() => {
      return store.getters.getOffsetUser;
    });
    const totalPage = computed(() => {
      return store.getters.getTotalPageUser;
    });
    const listUser = computed(() => {
      return store.getters.getListUser;
    });
    const username = computed(() => {
      return store.getters.getUsernameUser;
    });
    const showImport = ref(false);
    const selectedFilter = ref("");
    const filelist = ref([]);

    const searchByUsername = (data) => {
      store.commit("setUsernameUser", data);
      store.dispatch("getListUser");
    };

    const searchFilter = (selected, searchBy, sortValue) => {
      selectedFilter.value = selected;
      store.commit("setOffsetUser", 1);
      store.commit("setSortByUser", searchBy);
      store.commit("setSortValueUser", sortValue);
      store.dispatch("getListUser");
    };

    const showConfirmationImport = ref(false);

    const handleShowConfirmationImport = () => {
      showConfirmationImport.value = !showConfirmationImport.value;
    };

    const handleShowImport = () => {
      showImport.value = !showImport.value;
    };

    const onUpdateFile = (event) => {
      filelist.value.push(event.target.files[0]);
    };

    const remove = (i) => {
      filelist.value.splice(i, 1);
    };

    const dragover = (event) => {
      event.preventDefault();
    };

    const dragleave = () => {};

    const drop = (event) => {
      event.preventDefault();
      filelist.value.push(event.dataTransfer.files[0]);
    };

    const saveImport = () => {
      var files = filelist.value,
        f = files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        store
          .dispatch("importUser", XLSX.utils.sheet_to_json(worksheet))
          .then((res) => {
            if (res.data?.message === undefined) {
              filelist.value = [];
              loading.value = true;
              store.dispatch("getListUser").then(() => {
                loading.value = false;
              });
              handleShowImport();
            }
            // if (res.data?.message !== undefined) {
            //   statusNotification.value = "fail";
            //   titleNotification.value = "Gagal mengubah data pengguna";
            //   messageNotification.value =
            //     "Pengguna gagal ditambah. Pastikan bahwa data kredensial pengguna yang Anda masukan sudah benar.";
            // } else {
            //   statusNotification.value = "success";
            //   titleNotification.value = "Berhasil mengubah data pengguna";
            //   messageNotification.value =
            //     "Perubahan anda telah berhasil disimpan. Pastikan perubahan yang telah dibuat sudah benar.";
            // }
          });
        handleShowConfirmationImport();
      };
      reader.readAsArrayBuffer(f);
    };

    const beforePage = () => {
      if (page.value !== 1) {
        store.commit("setOffsetUser", page.value - 1);
        store.dispatch("getListUser");
      }
    };

    const nextPage = () => {
      if (page.value !== totalPage.value) {
        store.commit("setOffsetUser", page.value + 1);
        store.dispatch("getListUser");
      }
    };

    const deleteUser = (data) => {
      loading.value = true;
      store.dispatch("deleteMultiUser", data).then(() => {
        store.commit("setOffsetUser", 1);
        store.dispatch("getListUser").then(() => {
          loading.value = false;
        });
      });
    };

    onMounted(() => {
      loading.value = true;
      store.commit("setActiveMenu", "Pengguna");
      store.dispatch("getListUser").then(() => {
        loading.value = false;
      });
    });

    onUnmounted(() => {
      store.commit("setOffsetUser", 1);
      store.commit("setSortByUser", "created_date");
    });

    watch(
      () => {
        return store.getters.getUsernameUser;
      },
      function () {
        listUser.value = store.getters.getUsernameUser;
      }
    );

    return {
      loading,
      listMenu,
      totalUser,
      page,
      totalPage,
      listUser,
      username,
      showImport,
      selectedFilter,
      filelist,
      searchByUsername,
      searchFilter,
      handleShowImport,
      onUpdateFile,
      remove,
      dragover,
      dragleave,
      drop,
      saveImport,
      showConfirmationImport,
      handleShowConfirmationImport,
      beforePage,
      nextPage,
      deleteUser,
    };
  },
};
</script>
