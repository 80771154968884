import axios from "axios";

export default {
  state: {
    totalActivity: 0,
    totalPage: 0,
    listActivity: [],
    sortBy: "",
    username: "",
    sortValue: "",
    offset: 1,
    limit: 10,
  },
  getters: {
    getTotalActivity: (state) => {
      return state.totalActivity;
    },
    getTotalPageActivity: (state) => {
      return state.totalPage;
    },
    getListActivity: (state) => {
      return state.listActivity;
    },
    getOffsetActivity: (state) => {
      return state.offset;
    },
    getSortByActivity: (state) => {
      return state.sortBy;
    },
    getUsernameActivity: (state) => {
      return state.username;
    },
    getSortValueActivity: (state) => {
      return state.sortValue;
    },
  },
  actions: {
    async getListActivity({ state, commit }, data) {
      const params = {
        offset: state.offset,
        limit: data?.limit || state.limit,
        sortBy: state.sortBy,
        userName: state.username,
        sortValue: state.sortValue,
      };
      const url =
        process.env.VUE_APP_API_URL +
        `/api/v1/activity-log?` +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        commit("setTotalActivity", response.data.result.total);
        commit("setTotalPageActivity", response.data.result.total);
        commit("setListActivity", response.data.result.datas);
        return response;
      } catch (error) {
        return error.response;
      }
    },
  },
  mutations: {
    setTotalActivity: (state, data) => {
      state.totalActivity = data;
    },
    setTotalPageActivity: (state, data) => {
      state.totalPage = Math.ceil(data / state.limit);
    },
    setListActivity: (state, data) => {
      state.listActivity = data;
    },
    setOffsetActivity: (state, data) => {
      state.offset = data;
    },
    setSortByActivity: (state, data) => {
      state.sortBy = data;
    },
    setUsernameActivity: (state, data) => {
      state.username = data;
    },
    setSortValueActivity: (state, data) => {
      state.sortValue = data;
    },
  },
};
