<template>
  <div class="bg-white rounded-xl">
    <div
      class="flex items-center justify-between px-4 py-5 border-b border-b-grayscale-100"
    >
      <div class="text-lg font-semibold leading-7 text-grayscale-900">
        Status
      </div>
      <div
        class="rounded-full w-[10px] h-[10px]"
        :class="{
          'bg-green': status === 'PUBLISH',
          'bg-primary-50': status === 'DRAFT',
          'bg-secondary': status === 'EXPIRED',
        }"
      />
    </div>
    <div class="px-4 py-5">
      <div class="text-sm font-medium leading-5 text-grayscale-900">
        Atur Acara Status
      </div>
      <input-select
        class="mt-[6px]"
        :listItem="listStatus"
        :modelValue="status"
        @update:modelValue="onChangeStatus"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import InputSelect from "@/components/inputs/inputSelect.vue";

export default {
  name: "EventStatus",
  components: {
    InputSelect,
  },
  props: {
    status: {
      validator(value) {
        return ["PUBLISH", "DRAFT", "EXPIRED"].includes(value);
      },
    },
  },
  emits: ["onChangeStatus"],
  setup(_, { emit }) {
    const listStatus = ref([
      {
        value: "DRAFT",
        label: "Draft",
      },
      {
        value: "PUBLISH",
        label: "Publish",
      },
      {
        value: "EXPIRED",
        label: "Expired",
      },
    ]);
    const onChangeStatus = (data) => {
      emit("onChangeStatus", data);
    };

    return {
      listStatus,
      onChangeStatus,
    };
  },
};
</script>
