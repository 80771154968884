<template>
  <div
    class="fixed top-0 left-0 h-full w-screen bg-black-300 z-[101]"
    :class="show ? 'show ' : 'hidden'"
  >
    <div class="absolute z-[103] w-full h-full" @click="$emit('close')" />
    <div
      class="relative bg-white h-screen w-full sm:w-2/4 pb-20 float-right overflow-auto z-[104]"
    >
      <div class="flex justify-between px-10 py-6">
        <div class="text-lg font-semibold leading-7 text-grayscale-900">
          {{ title }}
        </div>
        <img
          alt="86-close"
          :src="require(`@/assets/images/86-close.svg`)"
          class="mt-2 me-2 w-3 h-3 cursor-pointer"
          @click="$emit('close')"
        />
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalTemplate",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
  emits: ["close"],
};
</script>