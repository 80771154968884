<script setup>
import TopbarUser from "@/components/layouts/topbarUser.vue";
import FootbarUser from "@/components/layouts/footbarUser.vue";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import Pagination from "@/components/pagination.vue";
import moment from "moment";
import { useRouter } from "vue-router";

onMounted(() => {
  getNotifications();
});

const router = useRouter();
const notifications = ref([]);
const totalNotifications = ref(0);
const totalUnreadNotifications = ref(0);
const isFething = ref(false);
const currentPage = ref(1);
const perPage = 5;

watch(currentPage, () => {
  getNotifications();
});

const groupedNotifications = computed(() => {
  return notifications.value.reduce((groups, item) => {
    const yearMonth = moment(item.time).format("MMMM YYYY");

    if (!groups[yearMonth]) {
      groups[yearMonth] = [];
    }

    groups[yearMonth].push(item);
    return groups;
  }, {});
});

const getNotifications = async () => {
  const params = {
    offset: currentPage.value,
    limit: perPage,
  };
  const url =
    process.env.VUE_APP_API_URL +
    "/api/v2/notification?" +
    new URLSearchParams(params).toString();
  try {
    isFething.value = true;
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
      },
    });
    notifications.value = response.data.result.datas;
    totalNotifications.value = response.data.result.total;
    totalUnreadNotifications.value = response.data.result.totalUnread;
  } catch (error) {
    console.error(error);
  } finally {
    isFething.value = false;
  }
};

const readNotification = async (notification) => {
  if (!notification.read) {
    const url = process.env.VUE_APP_API_URL + "/api/v2/notification/read";
    try {
      await axios({
        method: "post",
        url: url,
        headers: {
          Authorization: `
          Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
        },
        data: {
          id: notification.id,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  router.push(notification.redirectUrl);
};

const readAllNotifications = async () => {
  const url = process.env.VUE_APP_API_URL + "/api/v2/notification/read";
  try {
    await axios({
      method: "post",
      url: url,
      headers: {
        Authorization: `
          Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
      },
      data: {
        all: true,
      },
    });
  } catch (error) {
    console.error(error);
  }

  getNotifications();
};
</script>

<template>
  <div>
    <topbar-user />
    <div class="max-w-2xl mx-auto">
      <div
        class="w-full bg-white border border-gray-200 shadow-sm sm:my-12 sm:rounded-xl"
      >
        <div
          class="flex flex-wrap items-center justify-between gap-4 px-6 py-5 border-b border-gray-200"
        >
          <div class="flex items-center mr-4">
            <span class="text-2xl font-bold">Notifikasi</span>
            <span
              class="ml-2 bg-purple text-[#6941C6] text-sm px-2 py-1 font-medium rounded-full inline-flex items-center"
            >
              {{ totalUnreadNotifications }}
            </span>
          </div>
          <button
            class="text-sm font-medium text-right text-secondary"
            @click="readAllNotifications"
          >
            Tandai semua telah dibaca
          </button>
        </div>
        <div class="py-6 text-left min-h-[456px]">
          <template v-if="isFething">
            <div v-for="i in 5" :key="i" class="flex items-center px-6 py-4">
              <div
                class="w-10 h-10 rounded-full bg-grayscale-300 animate-pulse"
              />
              <div class="flex-1 ml-3">
                <div
                  class="w-full h-5 rounded-full bg-grayscale-300 animate-pulse"
                />
                <div
                  class="w-1/4 h-4 mt-2 rounded-full bg-grayscale-300 animate-pulse"
                />
              </div>
            </div>
          </template>
          <template v-else-if="!Object.keys(groupedNotifications).length">
            <div
              class="flex flex-col justify-center items-center h-[408px] mx-auto text-center"
            >
              <img
                :src="require(`@/assets/images/86-empty-comment.svg`)"
                alt="Belum Ada Komentar"
                class="w-[256px] inline-block"
              />
              <p class="text-lg font-semibold text-grayscale-900">
                Belum Ada Notifikasi
              </p>
              <p class="text-sm text-grayscale-500">
                Anda belum memiliki notifikasi.
              </p>
            </div>
          </template>
          <template
            v-else
            v-for="(notifications, month) in groupedNotifications"
            :key="month"
          >
            <p class="px-6 text-sm font-medium">{{ month }}</p>
            <ul class="mt-2">
              <li
                v-for="notification in notifications"
                :key="notification.id"
                class="flex px-6 py-4"
                :class="{ 'opacity-50 bg-grayscale-50': notification.read }"
              >
                <img
                  :src="notification.user?.picture"
                  alt="Avatar"
                  class="flex-shrink-0 w-10 h-10 rounded-full"
                />
                <div class="flex-1 ml-3">
                  <button
                    class="font-semibold text-left"
                    @click="readNotification(notification)"
                  >
                    {{ notification.user?.name }} {{ notification.content }}
                  </button>
                  <p class="mt-1 text-xs text-grayscale-400">
                    {{ moment(notification.time).format("DD MMM YYYY, HH:mm") }}
                  </p>
                </div>
              </li>
            </ul>
          </template>
        </div>
        <Pagination
          v-model="currentPage"
          :per-page="perPage"
          :total="totalNotifications"
          class="px-6 pb-5"
        />
      </div>
    </div>
    <footbar-user />
  </div>
</template>

<script>
export default {
  name: "Notifications",
};
</script>
