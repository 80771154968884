<template>
  <div class="h-screen w-screen bg-grayscale-50">
    <div
      class="h-full flex items-center justify-center m-auto px-5 sm:px-0 sm:w-[640px]"
    >
      <div class="w-full bg-white rounded-xl py-5 px-5 sm:py-[70px] sm:px-16">
        <img
          alt="86-logo"
          :src="require(`@/assets/images/86-logo.svg`)"
          class="m-auto w-[84px] sm:w-[146px]"
        />
        <div class="w-full mt-8 sm:mt-12 flex flex-col justify-center">
          <h5>Masuk Akun</h5>
          <form ref="form" @submit.prevent="submit">
            <input-email
              name
              class="mt-10"
              :value="data.email"
              v-model="data.email"
              :messageError="messageEmail"
              @keyup="validateInput"
            />
            <input-password
              class="mt-4"
              :value="data.password"
              v-model="data.password"
              :messageError="messagePassword"
              @keyup="validateInput"
            />
            <button-primary
              :title="'Masuk Akun'"
              :active="email !== '' || password !== ''"
              class="mt-10"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import store from "@/store";
import useValidators from "@/modules/validators";
import inputEmail from "@/components/inputs/inputEmail.vue";
import InputPassword from "@/components/inputs/inputPassword.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";

export default {
  components: { inputEmail, InputPassword, ButtonPrimary },
  name: "AdminLogin",
  setup() {
    const cryoptojs = inject("cryptojs");
    const data = ref({
      email: "",
      password: "",
    });
    const { isEmpty } = useValidators();
    const messageEmail = ref("");
    const messagePassword = ref("");

    // function
    const validateInput = () => {
      messageEmail.value = "";
      messagePassword.value = "";
    };

    const submit = () => {
      messageEmail.value = isEmpty("Email", data.value.email);
      messagePassword.value = isEmpty("Password", data.value.password);
      if (messageEmail.value === "" && messagePassword.value === "") {
        const payload = {
          ...data.value,
          secret: cryoptojs.AES.encrypt(
            data.value.password,
            "Secret Passphrase"
          ).toString(),
        };
        store.dispatch("login", payload).then((res) => {
          if (res) {
            if (res.data?.message !== undefined) {
              messageEmail.value = res.data.message;
            } else {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          }
        });
      }
    };

    return {
      data,
      messageEmail,
      messagePassword,
      validateInput,
      submit,
    };
  },
};
</script>