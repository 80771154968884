<script setup>
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Sidebar from "@/components/layouts/sidebar.vue";
import Topbar from "@/components/layouts/topbar.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import EventStatus from "@/components/progress/eventStatus.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import EventEditDetail from "@/components/events/eventEditDetail.vue";
import SidebarTemplate from "@/components/sidebarTemplate.vue";
import InputIcon from "@/components/inputs/inputIcon.vue";
import InputLabel from "@/components/inputs/inputLabel.vue";
import InputPassword from "@/components/inputs/inputPassword.vue";
import InputEmail from "@/components/inputs/inputEmail.vue";
import InputSelectTitle from "@/components/inputs/inputSelectTitle.vue";
import ModalTemplate from "@/components/modalTemplate.vue";
import UploadImage2 from "@/components/uploadImage2.vue";
import ShortProfileUser from "@/components/profiles/shortProfileUser.vue";
import moment from "moment";
import LoadingEventAdmin from "@/components/loading/loadingEventAdmin.vue";

const router = useRouter();
const route = useRoute();
const eventId = route.params.id;
const store = useStore();
const loading = ref(false);

onMounted(async () => {
  loading.value = true;
  store.commit("setActiveMenu", "Acara");
  await store.dispatch("getListGroup");
  await store.dispatch("getListCategory");
  await store.dispatch("getListJob");

  await store.dispatch("getDetailEvent", eventId).then((eventRes) => {
    eventRes = eventRes.data.result;
    typeSelect.value = eventRes.eventCreationType;
    status.value = eventRes.status;
    data.value = {
      eventGroupId: eventRes.eventGroup.id,
      eventGroupName: eventRes.eventGroup.name,
      eventGroupImage: eventRes.eventGroup.imageUrl,
      name: eventRes.name,
      image: eventRes.imageUrl,
      eventType: eventRes.eventType,
      location: eventRes.location,
      eventCategory: eventRes.eventCategory.id,
      quota: eventRes.quota,
      startDate: moment(eventRes.startDate, "DD/MM/YYYY HH:mm").format(
        "DD/MM/YYYY"
      ),
      startTime: moment(eventRes.startDate, "DD/MM/YYYY HH:mm").format("HH:mm"),
      endDate: moment(eventRes.endDate, "DD/MM/YYYY HH:mm").format(
        "DD/MM/YYYY"
      ),
      endTime: moment(eventRes.endDate, "DD/MM/YYYY HH:mm").format("HH:mm"),
      description: eventRes.description,
    };

    detailSelectedUser.value = eventRes.user;
  });
  loading.value = false;
});

const event = computed(() => {
  return store.getters.getDetailEvent;
});

const listMenu = computed(() => [
  {
    title: "Beranda",
    link: "/admin",
  },
  {
    title: "List Acara",
    link: "/admin/event",
  },
  {
    title: event.value?.name || "-",
    link: `/admin/event/${eventId}`,
  },
  {
    title: "Edit Acara",
    link: `/admin/event/${eventId}/edit`,
  },
]);

const status = ref("DRAFT");
const typeSelect = ref(null);
const data = ref(null);
const isEditGroup = ref(false);

const dataGroup = computed(() => {
  return store.getters.getListGroup;
});

const dataCategory = computed(() => {
  return store.getters.getListCategory;
});

watch(
  () => data.value?.eventGroupId,
  (value) => {
    data.value.eventGroupName = null;
    data.value.eventGroupImage = null;
    nextTick(() => {
      const selectedGroup = dataGroup.value.find((group) => group.id == value);
      data.value.eventGroupName = selectedGroup.name;
      data.value.eventGroupImage = selectedGroup.imageUrl;
    });
  }
);

const onChangeStatus = (data) => {
  status.value = data;
};

const updateThumbnailImage = (img) => {
  data.value.eventGroupImage = img;
};
const updateImage = (img) => {
  data.value.image = img;
};

const listUser = computed(() => {
  return store.getters.getListUser;
});

const showFindUser = ref(false);

const handleShowFindUser = () => {
  store.commit("setLimitUser", 100);
  store.commit("setUsernameUser", "");
  store.dispatch("getListUser");
  showFindUser.value = !showFindUser.value;
  if (showFindUser.value) {
    showCreateUser.value = false;
  }
};

const searchByUsername = (data) => {
  store.commit("setUsernameUser", data);
  store.dispatch("getListUser");
};

const selectedUser = ref("");

const onSelectedUser = (data) => {
  selectedUser.value = data;
};

const detailSelectedUser = ref(null);

const saveFindUser = () => {
  store.dispatch("getProfile", selectedUser.value).then((res) => {
    detailSelectedUser.value = res.data.result;
    handleShowFindUser();
  });
};

// new user

const showCreateUser = ref(false);

const handleShowCreateUser = () => {
  showCreateUser.value = !showCreateUser.value;
  if (showCreateUser.value) {
    showFindUser.value = false;
    detailSelectedUser.value = null;
  }
};
const dataUser = ref({
  name: "",
  nickname: "",
  username: "",
  password: "",
  repassword: "",
  email: "",
});

dataUser.value = {
  name: "",
  nickname: "",
  username: "",
  password: "",
  repassword: "",
  email: "",
};

const wantToSubmit = ref(false);

const submitData = async () => {
  let endDate = data.value.endDate
    ? `${data.value.endDate} ${data.value.endTime}`
    : `${data.value.startDate} ${data.value.startTime}`;

  if (data.value.image instanceof FormData) {
    const res = await store.dispatch("uploadImage", data.value.image);
    data.value.image = res.data.result;
  }

  if (data.value.eventGroupImage instanceof FormData) {
    const res = await store.dispatch("uploadImage", data.value.eventGroupImage);
    data.value.eventGroupImage = res.data.result;
  }

  const payload = {
    eventCreationType: typeSelect.value,
    ...data.value,
    eventCategoryId: parseInt(data.value.eventCategory),
    startDate: `${data.value.startDate} ${data.value.startTime}`,
    endDate: endDate,
    user: dataUser.value,
    eventUserId: detailSelectedUser.value?.id,
    status: status.value,
    imageUrl: data.value.image,
  };

  delete payload.image;
  delete payload.eventCategory;
  delete payload.startTime;
  delete payload.endTime;

  if (payload.eventUserId) {
    delete payload.user;
  } else {
    delete payload.eventUserId;
  }

  payload.eventGroup = {
    id: data.value.eventGroupId,
    name: data.value.eventGroupName,
    displayName: data.value.eventGroupName,
    imageUrl: data.value.eventGroupImage,
  };

  delete payload.eventGroupId;
  delete payload.eventGroupName;
  delete payload.eventGroupImage;

  const res = await store.dispatch("editEvent", { id: eventId, data: payload });

  wantToSubmit.value = false;

  if (res) {
    router.push(`/admin/event/${eventId}`);
  }
};

watch(
  () => {
    return store.getters.getListGroup;
  },
  function () {
    dataGroup.value = store.getters.getListGroup;
  }
);

watch(
  () => {
    return store.getters.getListCategory;
  },
  function () {
    dataCategory.value = store.getters.getListCategory;
  }
);

const wantToCancel = ref(false);

const cancel = () => {
  router.push(`/admin/event/${eventId}`);
  wantToCancel.value = false;
};

const groupsForSelect = computed(() => {
  return dataGroup.value.map((item) => {
    return {
      value: item.id,
      label: item.displayName,
    };
  });
});
</script>

<template>
  <div>
    <div class="flex w-full">
      <sidebar />
      <div class="w-full">
        <topbar />
        <div
          class="bg-grayscale-50 py-4 px-5 text-left mt-[72px] sm:mt-0"
          style="min-height: calc(100vh - 72px)"
        >
          <breadcrumb :title="'Acara'" :list="listMenu" />
          <loading-event-admin v-if="loading" />
          <div v-else class="sm:flex sm:items-start">
            <event-status
              class="w-full sm:w-2/6 mr-[18px]"
              :status="status"
              @onChangeStatus="onChangeStatus"
            />
            <div v-if="data" class="flex-1 w-full">
              <div class="p-5 mt-6 bg-white sm:mt-0 rounded-xl">
                <div class="flex justify-between">
                  <div
                    class="text-xl font-semibold leading-7 text-grayscale-800"
                  >
                    Informasi Group
                  </div>
                  <button v-if="!isEditGroup" @click="isEditGroup = true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z"
                      />
                    </svg>
                  </button>
                  <button v-else @click="isEditGroup = false">Batal</button>
                </div>
                <p
                  class="mt-3 text-sm font-normal leading-5 text-grayscale-400"
                >
                  Masukkan informasi group secara jelas dan terperinci untuk
                  membantu orang lain memahami dan berpartisipasi dalam acara
                  yang Anda buat.
                </p>
                <input-select-title
                  :title="'Pilih Group'"
                  :listItem="groupsForSelect"
                  :value="data.eventGroupId"
                  v-model="data.eventGroupId"
                  :disabled="isEditGroup"
                  class="mt-6"
                />
                <template v-if="isEditGroup">
                  <input-label
                    class="mt-6"
                    :title="'Nama Group'"
                    :value="data.eventGroupName"
                    v-model="data.eventGroupName"
                  />
                  <div class="mt-6">
                    <label>Thumbnail Group Acara</label>
                    <upload-image-2
                      v-if="data.eventGroupImage"
                      :title="'Gambar Grup'"
                      class="mt-[6px]"
                      :img="data.eventGroupImage"
                      :aspectRatio="4 / 1"
                      @updateImage="updateThumbnailImage"
                    />
                    <div class="mt-2 text-xs text-grayscale-500">
                      * Thumbail ini akan ditampilkan pada halaman ketika user
                      lihat daftar acara berdasarkan group/parent acara.
                    </div>
                  </div>
                </template>
              </div>
              <!-- Information Event -->
              <div class="p-5 mt-6 bg-white rounded-xl">
                <div class="text-xl font-semibold leading-7 text-grayscale-800">
                  Informasi Acara
                </div>
                <div
                  class="mt-3 text-sm font-normal leading-5 text-grayscale-400"
                >
                  Masukkan informasi acara secara jelas dan terperinci untuk
                  membantu orang lain memahami dan berpartisipasi dalam acara
                  yang Anda selenggarakan.
                </div>
                <event-edit-detail
                  v-if="data"
                  :selectedType="typeSelect"
                  :listGroup="dataGroup"
                  :listCategory="dataCategory"
                  :data="data"
                  @updateThumbnailImage="updateThumbnailImage"
                  @updateImage="updateImage"
                  class="mt-6"
                />
              </div>
              <!-- Information Creator -->
              <div class="p-5 mt-5 bg-white rounded-xl">
                <div class="text-xl font-semibold leading-7 text-grayscale-800">
                  Informasi Pembuat Acara
                </div>
                <div
                  class="mt-3 text-sm font-normal leading-5 text-grayscale-400"
                >
                  Anda bisa memlih pembuat acara dari daftar pengguna atau
                  membuat pengguna baru. Masukkan informasi pembuat acara dengan
                  detail untuk memberikan pemahaman kepada calon partisipan
                  untuk mengikuti acara yang akan diselenggarakan.
                </div>
                <div class="flex flex-col items-center mt-8 sm:flex-row">
                  <button-general
                    :title="'Cari dari list user'"
                    :size="'m'"
                    :border="'s'"
                    :color="'primary'"
                    class="w-full sm:w-auto"
                    @click="handleShowFindUser"
                  />
                  <div
                    class="my-4 text-sm font-normal leading-5 text-grayscale-400 sm:my-0 sm:mx-4"
                  >
                    Atau
                  </div>
                  <button-general
                    :title="'Buat user baru'"
                    :size="'m'"
                    :border="'s'"
                    :color="'outline'"
                    class="w-full sm:w-auto"
                    @click="handleShowCreateUser"
                  />
                </div>
              </div>
              <!-- Create User -->
              <div class="p-5 mt-5 bg-white rounded-xl" v-if="showCreateUser">
                <div class="text-lg font-semibold">Informasi Pengguna</div>
                <div class="mt-3 text-sm">
                  Isikan data pengguna dengan lengkap dan benar untuk membuat
                  akun pengguna. Pastikan seluruh data yang dimasukkan sudah
                  benar.
                </div>
                <div class="mt-6">
                  <div
                    class="mt-6 bg-white border border-grayscale-100 rounded-xl"
                  >
                    <div
                      class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
                    >
                      Data Pribadi
                    </div>
                    <div class="px-6 py-5">
                      <!-- Name -->
                      <input-label
                        :title="'Nama'"
                        :placeholder="'Masukan nama'"
                        :value="dataUser.name"
                        v-model="dataUser.name"
                      />
                      <!-- Nickname -->
                      <input-label
                        class="mt-5"
                        :title="'Nama Panggilan'"
                        :placeholder="'Masukan nama panggilan'"
                        :value="dataUser.nickname"
                        v-model="dataUser.nickname"
                      />
                      <!-- Username -->
                      <input-label
                        class="mt-5"
                        :title="'Nama Username'"
                        :placeholder="'Masukan nama username'"
                        :value="dataUser.username"
                        v-model="dataUser.username"
                      />
                      <!-- Email -->
                      <input-email
                        class="mt-5"
                        :title="'Email'"
                        :useIcon="false"
                        :placeholder="'Masukan email aktif'"
                        v-model="dataUser.email"
                        :value="dataUser.email"
                      />
                      <!-- Password -->
                      <div class="grid grid-cols-1 gap-4 mt-5 sm:grid-cols-2">
                        <input-password
                          class="col"
                          :title="'Kata Sandi'"
                          :useIcon="false"
                          :placeholder="'Masukkan kata sandi anda'"
                          v-model="dataUser.password"
                          :value="dataUser.password"
                          :messageError="messagePassword"
                        />
                        <input-password
                          class="col"
                          :title="'Konfirmasi Kata Sandi'"
                          :useIcon="false"
                          :placeholder="'Masukkan kembali kata sandi'"
                          v-model="dataUser.repassword"
                          :value="dataUser.repassword"
                          :messageError="messagePassword"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Infomasi Selected User -->
              <div v-if="detailSelectedUser" class="mt-6">
                <short-profile-user :detail="detailSelectedUser" />
              </div>
              <div class="flex items-center justify-end mt-5">
                <button-general
                  :title="'Batalkan'"
                  :size="'m'"
                  :border="'s'"
                  :width="'auto'"
                  :color="'outline'"
                  class="mr-4"
                  @click="wantToCancel = true"
                />
                <button-general
                  :title="'Simpan'"
                  :size="'m'"
                  :border="'s'"
                  :width="'auto'"
                  :color="'primary'"
                  @click="wantToSubmit = true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sidebar-template
      :title="'Informasi Pembuat Acara'"
      :show="showFindUser"
      @close="handleShowFindUser"
    >
      <div class="px-10 py-6 text-left">
        <div class="font-semibold">Cari Dari List Pengguna</div>
        <div class="mt-3 text-sm text-grayscale-400">
          Anda dapat mencari dan menggunakan data pengguna yang sudah terdaftar
          pada website website 86 university.
        </div>
        <input-icon
          :label="'Search'"
          :icon="'86-search.svg'"
          :placeholder="'Search'"
          :value="username"
          @keyup.enter="searchByUsername($event.target.value)"
          class="mt-6 mb-3"
        />
        <label
          v-for="(item, index) in listUser"
          :key="index"
          class="flex items-center p-4 mt-3 border rounded-xl"
          :for="item.email"
        >
          <img :src="item.picture" width="32" class="h-8 mr-3 rounded-full" />
          <div class="flex-1">
            <div class="text-sm font-medium">{{ item.name }}</div>
            <div class="text-sm font-medium">{{ item.username }}</div>
          </div>
          <input
            type="radio"
            :id="item.email"
            name="selectUser"
            :value="item.email"
            @click="onSelectedUser($event.target.value)"
          />
        </label>
      </div>
      <div
        class="fixed bottom-0 flex justify-between w-full gap-6 px-10 py-5 bg-white sm:w-2/4"
        style="box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.08)"
      >
        <button-general
          :title="'Simpan'"
          :size="'s'"
          :border="'s'"
          :color="'primary'"
          @click="saveFindUser"
        />
      </div>
    </sidebar-template>
    <modal-template
      @close="wantToCancel = false"
      :show="wantToCancel"
      :show-icon="true"
      icon-type="alert"
    >
      <div class="px-5 pb-5 text-left sm:last:p-6">
        <div class="text-lg font-semibold">Batalkan edit acara</div>
        <div class="text-sm text-grayscale-500">
          Apakah Anda yakin membatalkan edit acara?
        </div>
      </div>
      <div class="flex justify-end p-5 sm:p-6">
        <button-general
          :title="'Tidak'"
          :size="'s'"
          :width="'full'"
          :color="'outline'"
          class="mr-[6px]"
          @click="wantToCancel = false"
        />
        <button-general
          :title="'Ya'"
          :size="'s'"
          :width="'full'"
          :color="'primary'"
          @click="cancel"
        />
      </div>
    </modal-template>
    <modal-template
      @close="wantToSubmit = false"
      :show="wantToSubmit"
      :show-icon="true"
      icon-type="alert"
    >
      <div class="px-5 pb-5 text-left sm:last:p-6">
        <div class="text-lg font-semibold">Simpan perubahan</div>
        <div class="text-sm text-grayscale-500">
          Apakah Anda yakin ingin menyimpan perubahan acara ini?
        </div>
      </div>
      <div class="flex justify-end p-5 sm:p-6">
        <button-general
          :title="'Tidak'"
          :size="'s'"
          :width="'full'"
          :color="'outline'"
          class="mr-[6px]"
          @click="wantToSubmit = false"
        />
        <button-general
          :title="'Ya'"
          :size="'s'"
          :width="'full'"
          :color="'primary'"
          @click="submitData"
        />
      </div>
    </modal-template>
  </div>
</template>
