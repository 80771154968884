<template>
  <div class="bg-white">
    <topbar-user />
    <loading-event-detail v-if="loading" />
    <template v-else>
      <img
        alt="86-banner-event"
        :src="detail.imageUrl"
        class="hidden object-cover w-full sm:block"
        style="height: calc(100vh - 90px)"
      />
      <img
        alt="86-banner-event"
        :src="detail.imageUrl"
        class="block sm:hidden w-full object-cover h-[175px]"
      />
      <div class="container px-5 mx-auto mt-12 sm:mt-14">
        <div class="text-left sm:grid sm:grid-cols-3 sm:gap-10">
          <div class="col-span-2">
            <router-link :to="`/event?g=${detail.eventGroup.id}`">
              <div
                class="inline-block text-sm border-b cursor-pointer sm:text-xl text-grayscale-600 border-b-transparent hover:text-red-main hover:border-b hover:border-b-red-main"
              >
                {{ detail.eventGroup.displayName || "" }}
              </div>
            </router-link>
            <div
              class="mt-4 text-2xl font-semibold sm:text-5xl text-grayscale-900"
            >
              {{ detail.name }}
            </div>
            <!-- participant -->
            <div class="flex flex-col mt-4 sm:flex-row sm:mt-6">
              <div class="flex items-center pr-3 sm:border-r">
                <img
                  alt="86-user"
                  :src="detail.user.picture"
                  width="40"
                  class="h-10 mr-2 rounded-full"
                />
                <div class="flex items-center">
                  <div class="text-sm leading-4 sm:text-base">
                    Oleh:&nbsp;
                    <span class="text-sm font-semibold leading-5 sm:text-base">
                      {{ detail.user.name }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="flex pr-3 mt-2 sm:border-r sm:relative sm:mt-0 sm:ml-3"
              >
                <div
                  v-if="firstThreeParticipants.length"
                  class="flex items-center mr-5 -space-x-3 overflow-hidden"
                >
                  <img
                    v-for="(participant, index) in firstThreeParticipants"
                    :key="index"
                    alt="86-sample-participant"
                    :src="participant.picture"
                    width="32"
                    height="32"
                    class="object-cover w-10 h-10 border border-white rounded-full"
                  />
                </div>
                <div
                  v-else
                  class="flex items-center justify-center w-10 h-10 mr-2 rounded-full bg-grayscale-100"
                >
                  <img
                    alt="86-user-black"
                    :src="require(`@/assets/images/86-user-black.svg`)"
                    width="32"
                    height="32"
                    class="w-5 h-5"
                  />
                </div>
                <div class="flex items-center py-2 sm:py-0">
                  <span class="text-sm leading-4 sm:text-base">
                    Partisipan:&nbsp;
                    <span class="text-sm font-semibold leading-5 sm:text-base">
                      {{ totalParticpant }} pendaftar
                    </span>
                  </span>
                </div>
              </div>
              <div class="flex items-center mt-2 sm:mt-0 sm:ml-3">
                <div class="p-2 border rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                    />
                  </svg>
                </div>
                <span class="ml-2 text-sm sm:text-base">
                  Kuota:&nbsp;
                  <span class="font-semibold">{{
                    detail.quota !== null && detail.quota !== undefined
                      ? `${detail.quota} Partisipan`
                      : "tidak ada batas kuota"
                  }}</span>
                </span>
              </div>
            </div>
            <!-- location -->
            <div class="mt-10">
              <div class="text-lg font-semibold text-grayscale-900">
                Waktu dan Tempat
              </div>
              <div
                class="flex flex-col items-start justify-between mt-6 sm:flex-row"
              >
                <div class="flex items-start w-full">
                  <img
                    alt="86-event-calendar"
                    :src="require(`@/assets/images/86-event-calendar.svg`)"
                    width="54"
                    class="me-4"
                  />
                  <div class="flex flex-col justify-between">
                    <div class="text-base font-semibold sm:text-lg">
                      Tanggal dan Waktu
                    </div>
                    <div class="text-sm text-grayscale-600">
                      <template
                        v-if="date(detail.startDate) == date(detail.endDate)"
                      >
                        {{ date(detail.startDate) }} &#x2022;
                        <template
                          v-if="time(detail.startDate) == time(detail.endDate)"
                        >
                          {{ time(detail.startDate) }}
                        </template>
                        <template v-else>
                          {{ time(detail.startDate) }} -
                          {{ time(detail.endDate) }}
                        </template>
                      </template>
                      <template v-else>
                        {{ date(detail.startDate) }} &#x2022;
                        {{ time(detail.startDate) }}
                        -
                        {{ date(detail.endDate) }} &#x2022;
                        {{ time(detail.endDate) }}
                      </template>
                    </div>
                  </div>
                </div>
                <div class="flex items-start w-full mt-10 sm:mt-0">
                  <img
                    alt="86-event-location"
                    :src="require(`@/assets/images/86-event-location.svg`)"
                    width="54"
                    class="me-4"
                  />
                  <div class="flex flex-col justify-between">
                    <div class="text-base font-semibold sm:text-lg">Lokasi</div>
                    <div
                      class="text-sm text-grayscale-600"
                      v-html="linkify(detail.location)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- category -->
            <div class="mt-10">
              <div class="text-lg font-semibold sm:text-2xl text-grayscale-900">
                Kategori Acara
              </div>
              <div class="mt-6">
                <div class="grid grid-cols-2">
                  <card-event-category-user :detail="detail.eventCategory" />
                </div>
              </div>
            </div>
            <template v-if="token !== null">
              <!-- description -->
              <div class="mt-10">
                <div
                  class="text-lg font-semibold sm:text-2xl text-grayscale-900"
                >
                  Deskripsi Acara
                </div>
                <div
                  class="mt-6 break-words whitespace-pre-wrap text-grayscale-700"
                  v-html="linkify(detail.description)"
                ></div>
              </div>
              <list-particpant
                class="mt-14"
                :creator="detail.user.id"
                :totalParticipant="totalParticpant"
                :list="listParticpant"
                @search="findUser"
              />
              <event-comments class="my-10" :event-id="eventId" />
              <div class="block sm:hidden">
                <div class="p-6 border rounded-2xl">
                  <div class="text-lg font-semibold sm:text-2xl">
                    Tentang Pembuat Acara
                  </div>
                  <div class="flex items-center mt-6">
                    <img
                      alt="86-user"
                      :src="detail.user.picture"
                      width="56"
                      class="object-cover mr-2 rounded-full h-14"
                    />
                    <div>
                      <div class="text-base font-semibold leading-5 sm:text-lg">
                        {{ detail.user.name || "" }}
                      </div>
                      <div class="text-xs sm:text-sm">
                        {{ detail.user.nickname || "" }}
                      </div>
                    </div>
                  </div>
                  <div class="mt-6">
                    <div class="text-sm font-semibold sm:text-base">
                      Pendidikan
                    </div>
                    <div class="mt-1 text-sm sm:text-base">
                      {{ detail.user.highSchool || "" }}
                    </div>
                  </div>
                  <div class="mt-6">
                    <div class="text-sm font-semibold sm:text-base">
                      Keahlian
                    </div>
                    <template v-if="detail.user.specialities?.split(',')">
                      <div
                        v-for="(skill, i) in detail.user.specialities?.split(
                          ','
                        ) || []"
                        :key="i"
                        class="inline-block px-3 py-1 mt-1 mr-4 text-xs border rounded-full sm:text-base"
                      >
                        {{ skill }}
                      </div>
                    </template>
                    <div v-else>-</div>
                  </div>
                  <router-link :to="`/profile/${detail.user.email}`">
                    <div
                      class="mt-8 text-xs text-center cursor-pointer sm:text-sm"
                    >
                      Lihat Selengkapnya
                    </div>
                  </router-link>
                </div>
                <div class="p-6 my-10 border rounded-2xl">
                  <div class="text-lg font-semibold sm:text-2xl">
                    Acara {{ detail.user.name }}
                  </div>
                  <div v-for="(item, index) in eventRelated" :key="index">
                    <div
                      class="flex items-center py-5 cursor-pointer"
                      :class="index !== eventRelated.length - 1 && 'border-b'"
                      @click="handleRelatedEvent(item.id)"
                    >
                      <img
                        alt="86-banner-event"
                        :src="item.imageUrl"
                        width="88"
                        class="h-[64px] object-cover mr-4 rounded-[4px]"
                      />
                      <div class="text-sm font-semibold sm:text-base">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="relative mt-10" v-else>
              <img
                alt="86-blur-login"
                :src="require(`@/assets/images/86-blur-login.jpg`)"
                class="absolute object-cover w-full h-full"
              />
              <div
                class="relative flex flex-col items-center justify-between w-full h-full p-12"
              >
                <div class="text-2xl font-semibold">
                  Masuk Akun Untuk Dapat Melihat Semua Konten
                </div>
                <router-link :to="'/login'">
                  <button-general
                    :title="'Masuk Akun'"
                    :size="'l'"
                    :color="'primary'"
                    class="mt-10 w-[400px]"
                  />
                </router-link>
                <router-link :to="'/register'">
                  <button-general
                    :title="'Daftar Anggota'"
                    :size="'l'"
                    :color="'outline'"
                    class="mt-4 w-[400px]"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="hidden sm:block sticky top-[106px] pb-10">
              <div class="p-6 border rounded-2xl">
                <div class="text-2xl font-semibold">
                  {{
                    detail.status !== "EXPIRED"
                      ? "Hadiri Acara Ini"
                      : "Acara Telah Selesai"
                  }}
                </div>
                <div class="flex items-center mt-2">
                  <img
                    alt="86-user-red"
                    :src="require(`@/assets/images/86-user-red.svg`)"
                    class="mr-[6px]"
                  />
                  <span class="font-semibold text-red-main">
                    {{
                      detail.status !== "EXPIRED"
                        ? detail.totalViewer
                        : detail.totalParticipant
                    }}
                    orang &nbsp;
                  </span>
                  <span v-if="detail.status !== 'EXPIRED'">
                    telah melihat event ini
                  </span>
                  <span v-else>telah menghadiri event ini</span>
                </div>
                <div
                  v-if="
                    detail.status !== 'EXPIRED' &&
                    ![null, undefined].includes(detail.quota)
                  "
                  class="flex items-center mt-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-[6px] text-secondary"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                    />
                  </svg>

                  <span class="font-semibold text-red-main">
                    <!-- If remaining quota is under 0, will return 0 -->
                    {{ Math.max(0, detail.quota - detail.totalParticipant) }}
                    kuota tersisa &nbsp;
                  </span>
                </div>
                <div v-if="detail.status !== 'EXPIRED'">
                  <template v-if="!alreadyRegister">
                    <button-general
                      v-if="isCanRegister"
                      :title="'Daftar Sekarang'"
                      :size="'l'"
                      :color="token !== null ? 'primary' : 'teritary'"
                      class="mt-6 font-semibold"
                      @click="registerEvent"
                    />
                    <button-general
                      v-else
                      :title="'Kuota Sudah Penuh'"
                      :size="'l'"
                      :color="'teritary'"
                      class="mt-6 font-semibold"
                      :disabled="true"
                    />
                  </template>
                  <div class="mt-6" v-else>
                    <div
                      class="bg-grayscale-300 w-full rounded-full py-[10px] text-center"
                    >
                      Anda telah mendaftar acara ini
                    </div>
                    <button-general
                      :title="'Batal Mendaftar'"
                      :size="'l'"
                      class="mt-6 font-semibold text-red-main"
                      @click="handleShowCanceled"
                    />
                  </div>
                </div>
              </div>
              <div class="p-6 mt-4 border rounded-2xl">
                <div class="text-lg font-semibold sm:text-2xl">
                  Tentang Pembuat Acara
                </div>
                <div class="flex items-center mt-6">
                  <img
                    alt="86-user"
                    :src="detail.user.picture"
                    width="56"
                    class="object-cover mr-2 rounded-full h-14"
                  />
                  <div>
                    <div class="text-base font-semibold leading-5 sm:text-lg">
                      {{ detail.user.name || "" }}
                    </div>
                    <div class="text-xs sm:text-sm">
                      {{ detail.user.nickname || "" }}
                    </div>
                  </div>
                </div>
                <div class="mt-6">
                  <div class="text-sm font-semibold sm:text-base">
                    Pendidikan
                  </div>
                  <div class="mt-1 text-sm sm:text-base">
                    {{ detail.user.highSchool || "" }}
                  </div>
                </div>
                <div class="mt-6">
                  <div class="text-sm font-semibold sm:text-base">Keahlian</div>
                  <template v-if="detail.user.specialities?.split(',')">
                    <div
                      v-for="(skill, i) in detail.user.specialities?.split(
                        ','
                      ) || []"
                      :key="i"
                      class="inline-block px-3 py-1 mt-1 mr-4 text-xs border rounded-full sm:text-base"
                    >
                      {{ skill }}
                    </div>
                  </template>
                  <div v-else>-</div>
                </div>
                <router-link :to="`/profile/${detail.user.email}`">
                  <div
                    class="mt-8 text-xs text-center cursor-pointer sm:text-sm"
                  >
                    Lihat Selengkapnya
                  </div>
                </router-link>
              </div>
              <div class="p-6 mt-4 border rounded-2xl">
                <div class="text-lg font-semibold sm:text-2xl">
                  Acara {{ detail.user.name }}
                </div>
                <div v-for="(item, index) in eventRelated" :key="index">
                  <div
                    class="flex items-center py-5 cursor-pointer"
                    :class="index !== eventRelated.length - 1 && 'border-b'"
                    @click="handleRelatedEvent(item.id)"
                  >
                    <img
                      alt="86-banner-event"
                      :src="item.imageUrl"
                      width="88"
                      class="h-[64px] object-cover mr-4 rounded-[4px]"
                    />
                    <div class="text-sm font-semibold sm:text-base">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <event-in-group
          :group-id="detail.eventGroup?.id"
          :ignore-event-ids="[detail.id]"
          class="mb-[74px] sm:mb-[100px]"
        />
      </div>
      <div
        class="fixed bottom-0 w-full bg-white sm:hidden"
        style="box-shadow: 0px -2px 24px 0px rgba(0, 0, 0, 0.1)"
        v-if="showRegisterEvent && token !== null"
      >
        <div class="p-5">
          <p
            v-if="detail.status === 'EXPIRED'"
            class="text-2xl font-semibold text-left"
          >
            Acara Telah Selesai
          </p>
          <div class="flex items-center mt-2">
            <img
              alt="86-user-red"
              :src="require(`@/assets/images/86-user-red.svg`)"
              class="mr-[6px]"
            />
            <span class="font-semibold text-red-main">
              {{
                detail.status !== "EXPIRED"
                  ? detail.totalViewer
                  : detail.totalParticipant
              }}
              orang &nbsp;
            </span>
            <span v-if="detail.status !== 'EXPIRED'">
              telah melihat event ini
            </span>
            <span v-else>telah menghadiri event ini</span>
          </div>
          <div
            v-if="
              detail.status !== 'EXPIRED' &&
              ![null, undefined].includes(detail.quota)
            "
            class="flex items-center mt-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 mr-[6px] text-secondary"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
              />
            </svg>

            <span class="font-semibold text-red-main">
              <!-- If remaining quota is under 0, will return 0 -->
              {{ Math.max(0, detail.quota - detail.totalParticipant) }}
              kuota tersisa &nbsp;
            </span>
          </div>
          <div v-if="detail.status !== 'EXPIRED'">
            <template v-if="!alreadyRegister">
              <button-general
                v-if="isCanRegister"
                :title="'Daftar Sekarang'"
                :size="'l'"
                :color="token !== null ? 'primary' : 'teritary'"
                class="mt-5 font-semibold sm:mt-6"
                @click="registerEvent"
              />
              <button-general
                v-else
                :title="'Kuota Sudah Penuh'"
                :size="'l'"
                :color="'teritary'"
                class="mt-5 font-semibold sm:mt-6"
                :disabled="true"
              />
            </template>
            <div class="mt-6" v-else>
              <div
                class="bg-grayscale-300 w-full rounded-full py-[10px] text-center"
              >
                Anda telah mendaftar acara ini
              </div>
              <button-general
                :title="'Batal Mendaftar'"
                :size="'l'"
                class="mt-3 font-semibold sm:mt-6 text-red-main"
                @click="handleShowCanceled"
              />
            </div>
          </div>
        </div>
      </div>
      <footbar-user />
      <modal-template
        :title="'Konfirmasi Pendaftaran'"
        @close="handleShowConfirmation"
        :show="showConfirmation"
      >
        <div class="p-5 text-left sm:last:p-6 border-y">
          <div class="text-sm sm:text-base">
            Anda akan bergabung pada acara:
          </div>
          <div class="text-xl font-semibold sm:text-2xl">
            {{ detail.name }}
          </div>
          <div class="flex items-start w-full mt-11 sm:mt-16">
            <img
              alt="86-event-calendar"
              :src="require(`@/assets/images/86-event-calendar.svg`)"
              width="54"
              class="me-4"
            />
            <div class="flex flex-col justify-between">
              <div class="text-sm font-semibold">Tanggal dan Waktu</div>
              <div class="text-grayscale-600">
                <template v-if="date(detail.startDate) == date(detail.endDate)">
                  {{ date(detail.startDate) }} &#x2022;
                  <template
                    v-if="time(detail.startDate) == time(detail.endDate)"
                  >
                    {{ time(detail.startDate) }}
                  </template>
                  <template v-else>
                    {{ time(detail.startDate) }} -
                    {{ time(detail.endDate) }}
                  </template>
                </template>
                <template v-else>
                  {{ date(detail.startDate) }} &#x2022;
                  {{ time(detail.startDate) }}
                  -
                  {{ date(detail.endDate) }} &#x2022;
                  {{ time(detail.endDate) }}
                </template>
              </div>
            </div>
          </div>
          <div class="flex items-start w-full mt-6 sm:mt-7">
            <img
              alt="86-event-location"
              :src="require(`@/assets/images/86-event-location.svg`)"
              width="54"
              class="me-4"
            />
            <div class="flex flex-col justify-between">
              <div class="text-sm font-semibold">Lokasi</div>
              <div
                class="text-grayscale-600"
                v-html="linkify(detail.location)"
              ></div>
            </div>
          </div>
        </div>
        <div class="flex justify-end p-5 sm:p-6">
          <button-general
            :title="'Kembali'"
            :size="'s'"
            :width="'auto'"
            :color="'outline'"
            class="mr-[6px]"
            @click="handleShowConfirmation"
          />
          <button-general
            :title="'Daftar'"
            :size="'s'"
            :width="'auto'"
            class="ms-[6px]"
            :color="'primary'"
            @click="submitRegisterEvent"
          />
        </div>
      </modal-template>
      <modal-template
        @close="handleShowCanceled"
        :show="showCanceled"
        :show-icon="true"
        icon-type="warning"
      >
        <div class="px-5 pb-5 text-left sm:last:pb-6 sm:last:px-6">
          <div class="text-lg font-semibold">Batal Mendaftar</div>
          <div class="text-sm text-grayscale-500">
            Apakah anda yakin ingin membatalkan kehadiran anda pada acara ini?
          </div>
        </div>
        <div class="flex justify-end p-5 sm:p-6">
          <button-general
            :title="'Kembali'"
            :size="'s'"
            :width="'full'"
            :color="'outline'"
            class="mr-[6px]"
            @click="handleShowCanceled"
          />
          <button-general
            :title="'Batal Daftar'"
            :size="'s'"
            :width="'full'"
            :color="'secondary'"
            @click="submitCancelEvent"
          />
        </div>
      </modal-template>
      <alert-top
        :showAlert="showAlertConfirmation"
        :status="'success'"
        :title="'Berhasil Bergabung Acara'"
        :message="`Anda telah berhasil bergabung pada acara ${detail.name}`"
        @close="handleShowAlertConfirmation"
      />
      <alert-top
        :showAlert="showAlertCanceled"
        :title="'Kehadiran Anda Dibatalkan'"
        :message="`Anda telah membatalkan kehadiran anda pada acara ${detail.name}`"
        @close="handleShowAlertCanceled"
      />
    </template>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import TopbarUser from "@/components/layouts/topbarUser.vue";
import FootbarUser from "@/components/layouts/footbarUser.vue";
import CardEventCategoryUser from "@/components/events/cardEventCategoryUser.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import ModalTemplate from "@/components/modalTemplate.vue";
import AlertTop from "@/components/alertTop.vue";
import EventComments from "@/components/events/eventComments.vue";
import ListParticpant from "@/components/events/listParticipant.vue";
import EventInGroup from "@/components/events/eventInGroup.vue";
import axios from "axios";
import LoadingEventDetail from "@/components/loading/loadingEventDetail.vue";

export default {
  name: "EventDetail",
  components: {
    TopbarUser,
    FootbarUser,
    CardEventCategoryUser,
    ButtonGeneral,
    ModalTemplate,
    AlertTop,
    EventComments,
    ListParticpant,
    EventInGroup,
    LoadingEventDetail,
  },
  setup() {
    const token = JSON.parse(window.localStorage.getItem("86"));
    const loading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const eventId = route.params.id;
    const alreadyRegister = computed(() => {
      return store.getters.getUserParticipate.find(
        (detail) => detail.id == route.params.id
      );
    });
    const detail = computed(() => {
      return store.getters.getDetailEvent;
    });
    const eventRelated = computed(() => {
      return store.getters.getEventUser;
    });
    const listParticpant = computed(() => {
      return store.getters.getDataParticipant;
    });
    const totalParticpant = computed(() => {
      return store.getters.getTotalParticipant;
    });
    const showRegisterEvent = ref(true);
    const showConfirmation = ref(false);
    const showAlertConfirmation = ref(false);
    const showCanceled = ref(false);
    const showAlertCanceled = ref(false);
    const date = (value) => {
      return moment(value, "DD/MM/YYYY")
        .lang("id")
        .format("dddd, DD MMMM YYYY");
    };
    const time = (value) => {
      return moment(value.split(" ")[1], "HH:mm").lang("id").format("HH:mm");
    };

    const handleShowConfirmation = () => {
      showConfirmation.value = !showConfirmation.value;
    };

    const handleShowAlertConfirmation = () => {
      showAlertConfirmation.value = !showAlertConfirmation.value;
    };

    const handleShowCanceled = () => {
      showCanceled.value = !showCanceled.value;
    };

    const handleShowAlertCanceled = () => {
      showAlertCanceled.value = !showAlertCanceled.value;
    };

    const registerEvent = () => {
      if (token !== null) {
        handleShowConfirmation();
      } else {
        router.push("/login");
      }
    };

    const submitRegisterEvent = () => {
      store.dispatch("registerEvent", route.params.id).then((res) => {
        if (res.data.result === "success") {
          handleShowConfirmation();
          handleShowAlertConfirmation();
          load();
          setTimeout(() => {
            handleShowAlertConfirmation();
          }, 3000);
        }
      });
    };

    const submitCancelEvent = () => {
      store.dispatch("cancelEvent", route.params.id).then((res) => {
        if (res.data.result === "success") {
          handleShowCanceled();
          handleShowAlertCanceled();
          load();
          setTimeout(() => {
            handleShowAlertCanceled();
          }, 3000);
        }
      });
    };

    const handleRelatedEvent = (id) => {
      router.push(`/event/${id}`);
      store.dispatch("getDetailEvent", id).then((res) => {
        store.dispatch("getEventUser", res.data.result.user.id);
      });
      store.dispatch("getListEventForUser");
    };

    const findUser = (data) => {
      store.dispatch("getEventParticipantDetail", {
        id: route.params.id,
        name: data.search,
        limit: 9,
        offset: data.offset,
      });
    };

    const load = () => {
      store.dispatch("getDetailEvent", route.params.id).then((res) => {
        if (res.status !== 200) {
          router.push("/404");
        }
        loading.value = false;
        store.dispatch("getEventParticipantDetail", {
          id: route.params.id,
          name: "",
          limit: 9,
        });
        store.commit("setLimitEventUser", 2);
        store.dispatch("getEventUser", res.data.result.user.id);
      });
      store.commit("setLimitEvent", 3);
      store.dispatch("getListEventForUser");
      store.dispatch("getEventParticipant");
      getFirstThreeParticipants();
    };

    onMounted(() => {
      load();
    });

    watch(
      () => {
        return store.getters.getDetailEvent;
      },
      function () {
        detail.value = store.getters.getDetailEvent;
      }
    );

    watch(
      () => {
        return store.getters.getEventUser;
      },
      function () {
        eventRelated.value = store.getters.getEventUser;
      }
    );

    watch(
      () => {
        return store.getters.getDataParticipant;
      },
      function () {
        listParticpant.value = store.getters.getDataParticipant;
      }
    );

    const firstThreeParticipants = ref([]);

    const getFirstThreeParticipants = async () => {
      const params = {
        offset: 1,
        limit: 3,
        eventId: eventId,
      };
      const url =
        process.env.VUE_APP_API_URL +
        "/api/v1/event-participant/get-current?" +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        firstThreeParticipants.value = response.data.result.participants;
        firstThreeParticipants.value.shift();
      } catch (error) {
        console.error(error);
      }
    };

    const isCanRegister = computed(() => {
      if ([null, undefined].includes(detail.value.quota)) {
        return true;
      }
      return detail.value.quota - totalParticpant.value > 0;
    });

    const linkify = (inputText) => {
      var replacedText, replacePattern1, replacePattern2;

      //URLs starting with http://, https://, or ftp://
      replacePattern1 =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
      replacedText = inputText.replace(
        replacePattern1,
        '<a href="$1" target="_blank" class="underline">$1</a>'
      );

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(
        replacePattern2,
        '$1<a href="http://$2" target="_blank" class="underline">$2</a>'
      );

      return replacedText;
    };

    return {
      token,
      eventId,
      alreadyRegister,
      loading,
      detail,
      eventRelated,
      listParticpant,
      totalParticpant,
      showRegisterEvent,
      showConfirmation,
      showAlertConfirmation,
      showCanceled,
      showAlertCanceled,
      date,
      time,
      handleShowConfirmation,
      handleShowAlertConfirmation,
      handleShowCanceled,
      handleShowAlertCanceled,
      registerEvent,
      submitRegisterEvent,
      submitCancelEvent,
      handleRelatedEvent,
      findUser,
      firstThreeParticipants,
      getFirstThreeParticipants,
      isCanRegister,
      linkify,
    };
  },
};
</script>
