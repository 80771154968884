<script setup>
import "floating-vue/dist/style.css";
import { ref, computed, defineProps } from "vue";
import store from "@/store";
import ModalTemplate from "@/components/modalTemplate.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import { Mentionable } from "vue-mention";
import axios from "axios";

const props = defineProps({
  eventId: {
    type: Number,
    required: true,
  },
});

const authenticatedUser = computed(() => store.getters.getDetailAuth);

const comments = computed(() => store.getters.getComments);
store.dispatch("getComments", props.eventId);

const expandedCommentIds = ref([]);
const commentInput = ref(null);
const newComment = ref("");
const replyTo = ref(null);
const editTo = ref(null);
const deleteTo = ref(null);
const isSubmitting = ref(false);

const submitComment = async () => {
  if (!newComment.value) {
    return;
  }

  if (replyTo.value) {
    await createComment(props.eventId, newComment.value, replyTo.value);

    expandComment(replyTo.value);
    replyTo.value = null;
  } else if (editTo.value) {
    await updateComment(props.eventId, editTo.value, newComment.value);
    editTo.value = null;
  } else {
    await createComment(props.eventId, newComment.value);
  }

  newComment.value = "";
};

const submitDeleteComment = async (commentId) => {
  await deleteComment(props.eventId, commentId);

  deleteTo.value = null;
};

const getComment = (id) => {
  return comments.value.find((comment) => comment.id === id);
};

const reply = (commentId) => {
  replyTo.value = commentId;
  editTo.value = null;
  newComment.value = "";
  commentInput.value.focus();
};

const createComment = async (eventId, content, parentCommentId) => {
  isSubmitting.value = true;
  await store.dispatch("createComment", { eventId, content, parentCommentId });
  await store.dispatch("getComments", eventId);
  isSubmitting.value = false;
};

const updateComment = async (eventId, id, content, parentCommentId) => {
  isSubmitting.value = true;
  await store.dispatch("updateComment", {
    eventId,
    id,
    content,
    parentCommentId,
  });
  await store.dispatch("getComments", eventId);
  isSubmitting.value = false;
};

const deleteComment = async (eventId, id) => {
  isSubmitting.value = true;
  await store.dispatch("deleteComment", { eventId, id });
  await store.dispatch("getComments", eventId);
  isSubmitting.value = false;
};

const edit = (comment) => {
  editTo.value = comment.id;
  replyTo.value = null;
  newComment.value = comment.content;
  commentInput.value.focus();
};

const del = (comment) => {
  deleteTo.value = comment.id;
};

const isFromMe = (comment) => {
  return comment.username === authenticatedUser.value?.user.username;
};

const isCommentExpanded = (commentId) => {
  return expandedCommentIds.value.includes(commentId);
};

const toggleExpand = (commentId) => {
  if (isCommentExpanded(commentId)) {
    unexpandComment(commentId);
  } else {
    expandComment(commentId);
  }
};

const expandComment = (commentId) => {
  expandedCommentIds.value.push(commentId);
};

const unexpandComment = (commentId) => {
  expandedCommentIds.value = expandedCommentIds.value.filter(
    (id) => id !== commentId
  );
};

const users = ref([]);

const loadUsers = async (search = "") => {
  const url =
    process.env.VUE_APP_API_URL +
    `/api/v1/user?` +
    new URLSearchParams({
      offset: 0,
      limit: 5,
      username: search,
    }).toString();
  try {
    const response = await axios({
      method: "get",
      url: url,
    });
    users.value = response.data.result.datas.map((user) => ({
      value: user.username,
      label: user.name,
    }));
  } catch (error) {
    console.error(error.response);
  }
};

function formatComment(text) {
  const mentionRegex = /@(\w+)/g;
  return text.replace(
    mentionRegex,
    `<a href='/profile/$1' class='text-secondary'>@$1</a>`
  );
}
</script>

<template>
  <div>
    <div class="text-lg font-semibold sm:text-2xl text-grayscale-900">
      Komentar
    </div>
    <div class="mt-6 border rounded-2xl border-grayscale-200">
      <div class="p-6 max-h-[800px] overflow-auto">
        <p class="text-lg font-semibold sm:text-xl text-grayscale-900">
          {{ comments.length }} Komentar
        </p>
        <div v-if="comments.length">
          <div
            v-for="(comment, index) in comments"
            :key="index"
            class="flex mt-4"
          >
            <div class="flex-shrink-0">
              <img
                :src="comment.userImageUrl"
                :alt="comment.name"
                class="w-12 h-12 rounded-full"
              />
            </div>
            <div class="flex-1 ml-3">
              <p class="text-base font-semibold sm:text-lg text-grayscale-900">
                {{ comment.name }}
              </p>
              <p class="mt-0.5 text-xs sm:text-sm text-grayscale-600">
                {{ comment.time }}
                <template v-if="comment.edited">(diedit)</template>
              </p>
              <p class="mt-3 text-sm sm:text-base text-grayscale-700">
                <span v-html="formatComment(comment.content)" />
              </p>
              <div
                class="flex items-center mt-3 text-sm font-medium text-grayscale-800 sm:text-base"
              >
                <button class="" @click="reply(comment.id)">Balas</button>
                <template v-if="isFromMe(comment)">
                  <svg
                    width="4"
                    height="5"
                    viewBox="0 0 4 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mx-2.5"
                  >
                    <circle cx="2" cy="2.5" r="2" fill="#0A2B3C" />
                  </svg>
                  <button @click="edit(comment)">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="inline-block mr-2"
                    >
                      <path
                        d="M12.6465 3.86544L13.9117 2.59944C14.1755 2.33568 14.5332 2.1875 14.9062 2.1875C15.2793 2.1875 15.637 2.33568 15.9008 2.59944C16.1645 2.86319 16.3127 3.22093 16.3127 3.59394C16.3127 3.96695 16.1645 4.32468 15.9008 4.58844L7.9365 12.5527C7.53999 12.949 7.05102 13.2402 6.51375 13.4002L4.5 14.0002L5.1 11.9864C5.25996 11.4492 5.55123 10.9602 5.9475 10.5637L12.6465 3.86544ZM12.6465 3.86544L14.625 5.84394M13.5 11.0002V14.5627C13.5 15.0102 13.3222 15.4395 13.0057 15.7559C12.6893 16.0724 12.2601 16.2502 11.8125 16.2502H3.9375C3.48995 16.2502 3.06072 16.0724 2.74426 15.7559C2.42779 15.4395 2.25 15.0102 2.25 14.5627V6.68769C2.25 6.24013 2.42779 5.81091 2.74426 5.49444C3.06072 5.17798 3.48995 5.00019 3.9375 5.00019H7.5"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    Edit
                  </button>
                  <svg
                    width="4"
                    height="5"
                    viewBox="0 0 4 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mx-2.5"
                  >
                    <circle cx="2" cy="2.5" r="2" fill="#0A2B3C" />
                  </svg>
                  <button @click="del(comment)">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="inline-block mr-2"
                    >
                      <path
                        d="M11.055 7.25039L10.7955 14.0004M7.2045 14.0004L6.945 7.25039M14.421 4.84289C14.6775 4.88189 14.9325 4.92314 15.1875 4.96739M14.421 4.84289L13.62 15.2551C13.5873 15.6791 13.3958 16.075 13.0838 16.3638C12.7717 16.6526 12.3622 16.813 11.937 16.8129H6.063C5.63782 16.813 5.22827 16.6526 4.91623 16.3638C4.6042 16.075 4.41269 15.6791 4.38 15.2551L3.579 4.84289M14.421 4.84289C13.5554 4.71203 12.6853 4.61271 11.8125 4.54514M3.579 4.84289C3.3225 4.88114 3.0675 4.92239 2.8125 4.96664M3.579 4.84289C4.4446 4.71203 5.31468 4.61271 6.1875 4.54514M11.8125 4.54514V3.85814C11.8125 2.97314 11.13 2.23514 10.245 2.20739C9.41521 2.18087 8.58479 2.18087 7.755 2.20739C6.87 2.23514 6.1875 2.97389 6.1875 3.85814V4.54514M11.8125 4.54514C9.94029 4.40045 8.05971 4.40045 6.1875 4.54514"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Hapus
                  </button>
                </template>
              </div>
              <div v-if="comment.replies.length">
                <div v-if="isCommentExpanded(comment.id)">
                  <div
                    v-for="(reply, index2) in comment.replies"
                    :key="index2"
                    class="flex mt-4"
                  >
                    <div class="flex-shrink-0">
                      <img
                        :src="reply.userImageUrl"
                        :alt="reply.name"
                        class="w-12 h-12 rounded-full"
                      />
                    </div>
                    <div class="flex-1 ml-3">
                      <p
                        class="text-base font-semibold sm:text-lg text-grayscale-900"
                      >
                        {{ reply.name }}
                      </p>
                      <p class="mt-0.5 text-xs sm:text-sm text-grayscale-600">
                        {{ reply.time }}
                        <template v-if="comment.edited">(diedit)</template>
                      </p>
                      <p class="mt-3 text-sm text-grayscale-700 sm:text-base">
                        <span v-html="formatComment(reply.content)" />
                      </p>
                      <div
                        class="flex items-center mt-3 text-sm font-medium text-grayscale-800 sm:text-base"
                      >
                        <template v-if="isFromMe(reply)">
                          <button @click="edit(reply)">
                            <svg
                              width="18"
                              height="19"
                              viewBox="0 0 18 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="inline-block mr-2"
                            >
                              <path
                                d="M12.6465 3.86544L13.9117 2.59944C14.1755 2.33568 14.5332 2.1875 14.9062 2.1875C15.2793 2.1875 15.637 2.33568 15.9008 2.59944C16.1645 2.86319 16.3127 3.22093 16.3127 3.59394C16.3127 3.96695 16.1645 4.32468 15.9008 4.58844L7.9365 12.5527C7.53999 12.949 7.05102 13.2402 6.51375 13.4002L4.5 14.0002L5.1 11.9864C5.25996 11.4492 5.55123 10.9602 5.9475 10.5637L12.6465 3.86544ZM12.6465 3.86544L14.625 5.84394M13.5 11.0002V14.5627C13.5 15.0102 13.3222 15.4395 13.0057 15.7559C12.6893 16.0724 12.2601 16.2502 11.8125 16.2502H3.9375C3.48995 16.2502 3.06072 16.0724 2.74426 15.7559C2.42779 15.4395 2.25 15.0102 2.25 14.5627V6.68769C2.25 6.24013 2.42779 5.81091 2.74426 5.49444C3.06072 5.17798 3.48995 5.00019 3.9375 5.00019H7.5"
                                stroke="black"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>

                            Edit
                          </button>
                          <svg
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="mx-2.5"
                          >
                            <circle cx="2" cy="2.5" r="2" fill="#0A2B3C" />
                          </svg>
                          <button @click="del(reply)">
                            <svg
                              width="18"
                              height="19"
                              viewBox="0 0 18 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="inline-block mr-2"
                            >
                              <path
                                d="M11.055 7.25039L10.7955 14.0004M7.2045 14.0004L6.945 7.25039M14.421 4.84289C14.6775 4.88189 14.9325 4.92314 15.1875 4.96739M14.421 4.84289L13.62 15.2551C13.5873 15.6791 13.3958 16.075 13.0838 16.3638C12.7717 16.6526 12.3622 16.813 11.937 16.8129H6.063C5.63782 16.813 5.22827 16.6526 4.91623 16.3638C4.6042 16.075 4.41269 15.6791 4.38 15.2551L3.579 4.84289M14.421 4.84289C13.5554 4.71203 12.6853 4.61271 11.8125 4.54514M3.579 4.84289C3.3225 4.88114 3.0675 4.92239 2.8125 4.96664M3.579 4.84289C4.4446 4.71203 5.31468 4.61271 6.1875 4.54514M11.8125 4.54514V3.85814C11.8125 2.97314 11.13 2.23514 10.245 2.20739C9.41521 2.18087 8.58479 2.18087 7.755 2.20739C6.87 2.23514 6.1875 2.97389 6.1875 3.85814V4.54514M11.8125 4.54514C9.94029 4.40045 8.05971 4.40045 6.1875 4.54514"
                                stroke="black"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Hapus
                          </button>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="mt-3 font-medium text-grayscale-800"
                  @click="toggleExpand(comment.id)"
                >
                  {{
                    isCommentExpanded(comment.id) ? "Sembunyikan" : "Tampilkan"
                  }}
                  balasan ({{ comment.replies.length }})
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="inline-block w-5 h-5"
                    :class="{ 'rotate-180': isCommentExpanded(comment.id) }"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-4">
          <div class="mx-auto text-center">
            <img
              :src="require(`@/assets/images/86-empty-comment.svg`)"
              alt="Belum Ada Komentar"
              class="w-[256px] inline-block"
            />
            <p class="text-lg font-semibold text-grayscale-900">
              Belum Ada Komentar
            </p>
            <p class="text-sm text-grayscale-500">
              Acara ini belum memiliki komentar.
            </p>
          </div>
        </div>
      </div>
      <!-- Reply bar -->
      <div v-if="replyTo" class="flex px-6 py-4 border-t border-grayscale-200">
        <button class="mr-4" @click="() => (replyTo = null)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
            />
          </svg>
        </button>
        <div class="flex-1 truncate text-grayscale-700">
          Membalas
          <strong class="text-secondary">
            {{ getComment(replyTo).name }} </strong
          >:
          {{ getComment(replyTo).content }}
        </div>
      </div>
      <!-- Edit bar -->
      <div v-if="editTo" class="flex px-6 py-4 border-t border-grayscale-200">
        <button class="mr-4" @click="() => (editTo = null)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
            />
          </svg>
        </button>
        <div class="flex-1 text-grayscale-700">Mengedit komentar</div>
      </div>
      <!-- Input bar -->
      <div class="flex p-6 border-t border-grayscale-200">
        <div class="flex-shrink-0">
          <img
            :src="authenticatedUser?.user.picture"
            class="w-12 h-12 rounded-full"
            alt="Avatar"
          />
        </div>
        <form class="relative flex-1 ml-3" @submit.prevent="submitComment">
          <Mentionable
            :keys="['@']"
            :items="users"
            filtering-disabled
            @open="loadUsers()"
            @search="loadUsers($event)"
          >
            <input
              ref="commentInput"
              type="text"
              placeholder="Tulis komentar Anda..."
              v-model="newComment"
              class="w-full py-3 pl-5 border rounded-full pr-[72px] bg-grayscale-50 border-grayscale-200"
              :disabled="isSubmitting"
            />
            <template #item="{ item }">
              <div class="px-4 py-3 cursor-pointer hover:bg-grayscale-100">
                <p class="text-sm font-semibold">
                  {{ item.label }}
                </p>
                <p class="text-xs text-grayscale-500">@{{ item.value }}</p>
              </div>
            </template>
            <template #no-result>
              <div class="px-4 py-3 text-sm text-grayscale-500">No result</div>
            </template>
          </Mentionable>
          <button
            v-if="!isSubmitting"
            type="submit"
            class="absolute font-medium -translate-y-1/2 text-secondary right-5 top-1/2"
          >
            Kirim
          </button>
          <div v-else class="absolute -translate-y-1/2 right-5 top-1/2">
            <svg
              class="w-6 h-6 text-grayscale-500 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </form>
      </div>
    </div>
    <modal-template
      @close="deleteTo = null"
      :show="deleteTo"
      :show-icon="true"
      icon-type="warning"
    >
      <div class="px-5 pb-5 text-left sm:last:p-6">
        <div class="text-lg font-semibold">Hapus Komentar</div>
        <div class="text-sm text-grayscale-500">
          Apakah anda yakin ingin menghapus komentar ini?
        </div>
      </div>
      <div class="flex justify-end p-5 sm:p-6">
        <button-general
          :title="'Batal'"
          :size="'s'"
          :width="'full'"
          :color="'outline'"
          class="mr-[6px]"
          @click="deleteTo = null"
        />
        <button-general
          :title="'Hapus Komentar'"
          :size="'s'"
          :width="'full'"
          :color="'secondary'"
          @click="submitDeleteComment(deleteTo)"
        />
      </div>
    </modal-template>
  </div>
</template>

<script>
export default {
  name: "EventComments",
};
</script>
