<template>
  <ModelSelect
    v-model="value"
    :options="listItem.map((item) => ({ value: item.value, text: item.label }))"
    :isDisabled="disabled"
    style="padding: 14px; border-radius: 8px; font-size: 16px"
    class="p-[10px] rounded-lg text-base"
  />
</template>

<script>
import { computed } from "vue";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelSelect } from "vue-search-select";

export default {
  name: "InputSelect",
  components: {
    ModelSelect,
  },
  props: {
    modelValue: {
      type: String,
    },
    listItem: {
      type: Object,
      required: true,
      default() {},
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        context.emit("update:modelValue", value);
      },
    });

    return {
      value,
    };
  },
};
</script>
