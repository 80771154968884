<template>
  <div class="flex h-screen">
    <div class="relative w-[50%] hidden sm:block">
      <img
        alt="86-splash-auth"
        :src="require(`@/assets/images/86-splash-auth.svg`)"
        class="h-full w-full absolute object-cover -z-10"
      />
      <img
        alt="86-logo-banner"
        :src="require(`@/assets/images/86-logo-banner.svg`)"
        class="w-[170px] sm:w-[348px] absolute bottom-12 left-12"
      />
    </div>
    <div class="flex-1 py-10 px-6 sm:py-10 sm:px-10">
      <router-link :to="'/'">
        <img alt="86-logo" :src="require(`@/assets/images/86-logo.svg`)" />
      </router-link>
      <div class="h-full w-full sm:p-5 flex flex-col justify-center">
        <h5>Masuk Akun</h5>
        <form ref="form" @submit.prevent="submit">
          <input-label
            class="mt-10"
            :title="'Username/Email'"
            :placeholder="'Masukkan Username/Email Anda'"
            :value="data.email"
            v-model="data.email"
            :messageError="messageEmail"
            @keyup="validateInput"
          />
          <input-password
            class="mt-4"
            :value="data.password"
            v-model="data.password"
            :messageError="messagePassword"
            @keyup="validateInput"
          />
          <div
            class="text-grayscale-600 text-sm font-normal leading-5 text-left mt-2"
          >
            Lupa kata sandi?
            <router-link
              to="/forget-password"
              class="cursor-pointer text-secondary font-semibold"
            >
              Ubah di sini
            </router-link>
          </div>
          <button-primary
            :title="'Masuk Akun'"
            :active="email !== '' || password !== ''"
            class="mt-10"
          />
        </form>
        <div class="text-grayscale-600 text-sm font-normal leading-5 mt-10">
          Belum punya akun?
          <router-link :to="'/register'">
            <span class="cursor-pointer text-secondary font-semibold">
              Daftar di sini
            </span>
          </router-link>
        </div>
        <div class="text-grayscale-600 text-sm font-normal leading-5 mt-6">
          Kembali ke
          <router-link
            to="/"
            class="cursor-pointer text-grayscale-900 font-semibold"
          >
            beranda
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import store from "@/store";
import useValidators from "@/modules/validators";
import InputLabel from "@/components/inputs/inputLabel.vue";
import InputPassword from "@/components/inputs/inputPassword.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";

export default {
  components: {
    InputLabel,
    InputPassword,
    ButtonPrimary,
  },
  name: "UserLogin",
  setup() {
    const cryoptojs = inject("cryptojs");
    const data = ref({
      email: "",
      password: "",
    });
    const { isEmpty } = useValidators();
    const messageEmail = ref("");
    const messagePassword = ref("");

    const validateInput = () => {
      messageEmail.value = "";
      messagePassword.value = "";
    };

    const submit = () => {
      messageEmail.value = isEmpty("Email/Username", data.value.email);
      messagePassword.value = isEmpty("Password", data.value.password);
      if (messageEmail.value === "" && messagePassword.value === "") {
        const payload = {
          ...data.value,
          secret: cryoptojs.AES.encrypt(
            data.value.password,
            "Secret Passphrase"
          ).toString(),
        };
        store.dispatch("login", payload).then((res) => {
          if (res) {
            if (res.data?.message !== undefined) {
              messageEmail.value = res.data.message;
            } else {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          }
        });
      }
    };

    return {
      data,
      messageEmail,
      messagePassword,
      validateInput,
      submit,
    };
  },
};
</script>