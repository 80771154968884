<!-- eslint-disable vue/no-parsing-error -->
<template>
  <template v-if="!isLoading">
    <!-- Personal Profile -->
    <div class="bg-white border border-grayscale-100 rounded-xl">
      <div
        class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
      >
        Data Pribadi
      </div>
      <div class="px-6 py-5">
        <!-- Name -->
        <div class="text-base font-semibold leading-6">Nama</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ detail?.name || "-" }}
        </div>
        <!-- Nickname -->
        <div class="mt-5 text-base font-semibold leading-6">Nama Panggilan</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ detail?.nickname || "-" }}
        </div>
        <!-- Username -->
        <div class="mt-5 text-base font-semibold leading-6">Username</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ detail?.username || "-" }}
        </div>
        <template v-if="!hideConfidential">
          <!-- Phone -->
          <div class="mt-5 text-base font-semibold leading-6">
            Nomor Telepon/WA
          </div>
          <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
            {{ detail?.phoneNumber || "-" }}
          </div>
          <!-- Address -->
          <div class="mt-5 text-base font-semibold leading-6">Alamat</div>
          <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
            {{ detail?.address || "-" }}
          </div>
          <!-- Email -->
          <div class="mt-5 text-base font-semibold leading-6">Email</div>
          <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
            {{ detail?.email || "-" }}
          </div>
        </template>
        <!-- School -->
        <div class="mt-5 text-base font-semibold leading-6">Asal SMA</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ detail?.highSchool || "-" }}
        </div>
        <!-- Profession -->
        <div class="mt-5 text-base font-semibold leading-6">Profesi</div>
        <div class="mt-2 text-base font-medium leading-6 text-grayscale-500">
          {{ detail?.occupation || "-" }}
        </div>
      </div>
    </div>
    <!-- About You -->
    <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
      <div
        class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
      >
        Tentang Pengguna
      </div>
      <div class="px-6 py-5">
        <div class="text-base font-medium leading-6 text-justify">
          {{ detail?.about || "-" }}
        </div>
      </div>
    </div>
    <!-- Skill -->
    <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
      <div
        class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
      >
        Keahlian
      </div>
      <div class="px-6 pt-3 pb-5">
        <div v-if="specialities.length !== ''">
          <div
            v-for="(item, index) in specialities"
            :key="index"
            class="inline-block px-3 py-1 mt-2 mr-2 text-xs font-medium leading-4 border rounded-full border-grayscale-400"
          >
            {{ item }}
          </div>
        </div>
        <div v-else>-</div>
      </div>
    </div>
    <!-- Experience Skill -->
    <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
      <div
        class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
      >
        Pengalaman Pekerjaan
      </div>
      <div v-if="detail?.workingExps.length !== 0">
        <div
          class="px-6 py-5"
          v-for="(item, index) in detail?.workingExps"
          :key="index"
        >
          <div class="text-xl font-semibold leading-7">
            {{ item.position || "-" }}
          </div>
          <div class="mt-1 text-base font-medium leading-6 text-grayscale-600">
            {{ formatDateToMonthAndYear(item.startDate) }} -
            <span v-if="item.currentlyWorking"> sekarang </span>
            <span v-else>
              {{ formatDateToMonthAndYear(item.endDate) }}
            </span>
            &#x2022;
            {{ convertDate(item.endDate) - convertDate(item.startDate) }}
            tahun
          </div>
          <div class="mt-3 text-sm font-semibold leading-5">
            {{ item.name || "-" }}
          </div>
          <div class="mt-1 text-sm font-medium leading-6 text-grayscale-600">
            {{ item.jobTypeId ? getJobById(item.jobTypeId)?.name || "-" : "-" }}
            &#x2022; {{ item.address || "-" }}
          </div>
          <div class="mt-4 text-sm font-medium leading-6 text-grayscale-900">
            {{ item.description || "-" }}
          </div>
        </div>
      </div>
      <div class="px-6 py-5" v-else>-</div>
    </div>
    <!-- Business -->
    <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
      <div
        class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
      >
        Usaha yang Dimiliki
      </div>
      <div v-if="detail?.businesses.length !== 0">
        <div
          class="px-6 py-5"
          v-for="(item, index) in detail?.businesses"
          :key="index"
        >
          <div class="text-xl font-semibold leading-7">
            {{ item.name || "-" }}
          </div>
          <div class="mt-1 text-base font-medium leading-6 text-grayscale-600">
            {{ formatDateToMonthAndYear(item.startDate) }} -
            <span v-if="item.currentlyWorking"> sekarang </span>
            <span v-else>
              {{ formatDateToMonthAndYear(item.endDate) }}
            </span>
            &#x2022;
            {{ convertDate(item.endDate) - convertDate(item.startDate) }}
            tahun
          </div>
          <div class="mt-3 text-sm font-medium leading-6 text-grayscale-600">
            {{ item.address || "-" }}
          </div>
          <div class="mt-4 text-sm font-medium leading-6 text-grayscale-900">
            {{ item.description || "-" }}
          </div>
        </div>
      </div>
      <div class="px-6 py-5" v-else>-</div>
    </div>
    <!-- Education -->
    <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
      <div
        class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
      >
        Pendidikan
      </div>
      <div v-if="detail?.educations.length !== 0">
        <div
          class="px-6 py-5"
          v-for="(item, index) in detail?.educations"
          :key="index"
        >
          <div class="text-xl font-semibold leading-7">
            {{ item.university || "-" }}
          </div>
          <div class="mt-1 text-base font-medium leading-6 text-grayscale-600">
            {{ item.title || "-" }},
            {{ item.studyMajor || "-" }}
          </div>
          <div class="mt-1 text-base font-medium leading-6 text-grayscale-600">
            {{ formatDateToMonthAndYear(item.startDate) }} -
            {{ formatDateToMonthAndYear(item.endDate) }}
            &#x2022;
            {{ convertDate(item.endDate) - convertDate(item.startDate) }}
            tahun
          </div>
          <div class="mt-4 text-sm font-semibold leading-6 text-grayscale-900">
            IPK
          </div>
          <div class="text-sm font-medium leading-6 text-grayscale-900">
            {{ item.gpa || "-" }}
          </div>
        </div>
      </div>
      <div class="px-6 py-5" v-else>-</div>
    </div>
    <!-- Social Media -->
    <div class="mt-4 bg-white border border-grayscale-100 rounded-xl">
      <div
        class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
      >
        Media Sosial (Optional)
      </div>
      <div class="px-6 py-5">
        <!-- instagram -->
        <div class="flex items-center">
          <img
            alt="86-instagram"
            class="mr-3"
            :src="require(`@/assets/images/86-instagram.svg`)"
          />
          <div class="text-base leading-6">
            <span class="text-grayscale-400">Instagram.com/</span>
            <span class="text-grayscale-900">{{
              detail?.socials?.instagram || "-"
            }}</span>
          </div>
        </div>
        <!-- facebook -->
        <div class="flex items-center mt-4">
          <img
            alt="86-facebook"
            class="mr-3"
            :src="require(`@/assets/images/86-facebook.svg`)"
          />
          <div class="text-base leading-6">
            <span class="text-grayscale-400">Facebook.com/</span>
            <span class="text-grayscale-900">{{
              detail?.socials?.facebook || "-"
            }}</span>
          </div>
        </div>
        <!-- linkedin -->
        <div class="flex items-center mt-4">
          <img
            alt="86-linkedin"
            class="mr-3"
            :src="require(`@/assets/images/86-linkedin.svg`)"
          />
          <div class="text-base leading-6">
            <span class="text-grayscale-400">Linkedin.com/in/</span>
            <span class="text-grayscale-900">{{
              detail?.socials?.linkedin || "-"
            }}</span>
          </div>
        </div>
        <!-- twitter -->
        <div class="flex items-center mt-4">
          <img
            alt="86-twitter"
            class="mr-3"
            :src="require(`@/assets/images/86-twitter.svg`)"
          />
          <div class="text-base leading-6">
            <span class="text-grayscale-400">Twitter.com/</span>
            <span class="text-grayscale-900">{{
              detail?.socials?.twitter || "-"
            }}</span>
          </div>
        </div>
        <!-- tiktok -->
        <div class="flex items-center mt-4">
          <img
            alt="86-tiktok"
            class="mr-3"
            :src="require(`@/assets/images/86-tiktok.svg`)"
          />
          <div class="text-base leading-6">
            <span class="text-grayscale-400">Tiktok.com/</span>
            <span class="text-grayscale-900">{{
              detail?.socials?.tiktok || "-"
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import moment from "moment";
import store from "@/store";

export default {
  name: "ProfileUser",
  props: {
    detail: {
      type: Array,
      default: () => [],
    },
    hideConfidential: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const specialities = ref(
      props?.detail?.specialities !== null &&
        props?.detail?.specialities !== "" &&
        props?.detail?.specialities?.split(",")
    );
    const dateTime = (value) => {
      return moment(value, "YYYY-MM-DD").lang("id").format("MMMM YYYY");
    };
    const dateYear = (value) => {
      return moment(value, "YYYY-MM-DD").lang("id").format("YYYY");
    };

    const listJob = computed(() => {
      return store.getters.getListJob;
    });

    const getJobById = (id) => {
      return listJob.value.find((job) => job.id == id);
    };

    const convertDate = (value) => {
      if (!value) {
        return moment().format("YYYY");
      }
      return moment(value, "DD/MM/YYYY").lang("id").format("YYYY");
    };

    const formatDateToMonthAndYear = (date) => {
      return moment(date, "DD/MM/YYYY").lang("id").format("MMMM YYYY");
    };

    const isLoading = ref(false);

    onMounted(async () => {
      isLoading.value = true;
      await store.dispatch("getListJob");
      isLoading.value = false;
    });

    return {
      specialities,
      dateTime,
      dateYear,
      getJobById,
      convertDate,
      formatDateToMonthAndYear,
    };
  },
};
</script>
