import axios from "axios";

export default {
  state: {
    detailAuth: null,
  },
  getters: {
    getDetailAuth: (state) => {
      return state.detailAuth;
    },
  },
  actions: {
    async login({ commit }, data) {
      const url = process.env.VUE_APP_API_URL + "/api/v1/login";
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: {
            email: data.email,
            password: data.password,
          },
        });
        if (response.data?.message === undefined) {
          commit("setLocalstorage", {
            ...response.data.result,
            secret: data.secret,
          });
        }
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async register(_, data) {
      const url = process.env.VUE_APP_API_URL + "/api/v1/register";
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: {
            username: data.username,
            name: data.name,
            email: data.email,
            password: data.password,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async forgetPassword(_, data) {
      const url = process.env.VUE_APP_API_URL + "/api/v1/forgot-password";
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: {
            email: data.email,
            password: data.password,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async getUpdateUser({ commit }) {
      const url = process.env.VUE_APP_API_URL + "/api/v1/current-user";
      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        commit("setUpdateUser", response?.data?.result);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    setPassword(_, encryptedPassword) {
      const localStorageData = JSON.parse(window.localStorage.getItem("86"));
      window.localStorage.setItem(
        "86",
        JSON.stringify({
          ...localStorageData,
          secret: encryptedPassword,
        })
      );
    },
  },
  mutations: {
    setLocalstorage: (_, data) => {
      const token = {
        user: data.currentUser,
        token: data.token,
        secret: data.secret,
        // expired: Date.now() + 60 * 60 * 1000,
      };
      window.localStorage.setItem("86", JSON.stringify(token));
    },
    setUpdateUser: (state, data) => {
      let local = JSON.parse(window.localStorage.getItem("86"));
      local = {
        ...local,
        user: data,
      };
      state.detailAuth = local;
    },
  },
};
