<template>
  <div class="text-left">
    <label for="Email">{{ title }}</label>
    <div
      class="flex border rounded-lg py-[10px] px-[10px] mt-1"
      :class="
        messageError !== ''
          ? 'border-red-main'
          : focus
          ? 'border-grayscale-900'
          : 'border-grayscale-200'
      "
    >
      <img
        v-if="useIcon"
        alt="86-email"
        :src="require(`@/assets/images/86-email.svg`)"
        class="mr-3"
      />
      <input
        type="email"
        class="flex-1 text-base font-normal leading-6 focus:outline-none"
        :placeholder="placeholder"
        v-model="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="onFocus"
        @blur="onLeave"
        autocomplete="off"
      />
    </div>
    <p
      v-show="messageError !== ''"
      class="text-red-main text-sm font-normal leading-5 mt-[6px]"
    >
      {{ messageError }}
    </p>
  </div>
</template>

<script>
export default {
  name: "InputEmail",
  data() {
    return {
      focus: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "Username/Email",
    },
    useIcon: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Masukan username/email anda",
    },
    value: {
      type: String,
    },
    messageError: {
      type: String,
      default: "",
    },
  },
  emits: ["update:value"],
  methods: {
    onFocus() {
      this.focus = true;
    },
    onLeave() {
      this.focus = false;
    },
  },
};
</script>