<template>
  <div class="bg-white">
    <topbar-user />
    <loading-profile v-if="loading" class="my-20" />
    <div v-else class="container px-5 py-8 mx-auto sm:py-20">
      <div class="grid items-start grid-cols-1 sm:grid-cols-4 sm:gap-6">
        <profile-picture
          class="border col rounded-xl"
          :image="detail.picture"
        />
        <div class="col-span-3 mt-10 text-left sm:mt-0">
          <div class="overflow-auto">
            <div class="flex mb-6 sm:text-lg">
              <div
                class="pb-3 mx-3 cursor-pointer"
                :class="
                  selectedMenu === 'profile' &&
                  'border-b-2 border-b-primary-50 text-primary-50'
                "
                @click="handleSelectedMenu('profile')"
              >
                Data Pribadi
              </div>
              <div
                class="pb-3 mx-3 cursor-pointer"
                :class="
                  selectedMenu === 'history' &&
                  'border-b-2 border-b-primary-50 text-primary-50'
                "
                @click="handleSelectedMenu('history')"
              >
                Riwayat Acara
              </div>
              <div
                class="pb-3 mx-3 cursor-pointer"
                :class="
                  selectedMenu === 'create' &&
                  'border-b-2 border-b-primary-50 text-primary-50'
                "
                @click="handleSelectedMenu('create')"
              >
                Acara Dibuat
              </div>
            </div>
          </div>
          <profile-user
            :detail="detail"
            v-if="selectedMenu === 'profile' && !loading"
            :hideConfidential="true"
          />
          <div
            v-else-if="selectedMenu === 'history'"
            class="w-full bg-white border rounded-xl"
          >
            <div class="px-6 py-5 text-xl font-semibold leading-7">
              Riwayat Acara
            </div>
            <table-create-event
              v-if="participatedEventsTotal"
              :data="participatedEvents"
              :count="participatedEventsTotal"
              :page="participatedEventsPage"
              :totalPage="participatedEventsTotalPage"
              @before="goToPrevParticipatedEventsPage"
              @next="goToNextParticipatedEventsPage"
              :link="'/event'"
            />
            <div v-else>
              <div class="px-6 py-5 mx-auto text-center">
                <img
                  :src="require(`@/assets/images/86-empty-comment.svg`)"
                  alt="Ilustrasi"
                  class="w-[256px] inline-block"
                />
                <p class="text-lg font-semibold text-grayscale-900">
                  Belum Mengikuti Acara
                </p>
                <p class="text-sm text-grayscale-500">
                  Pengguna belum mengikuti acara apapun.
                </p>
              </div>
            </div>
          </div>
          <div
            v-else-if="selectedMenu === 'create'"
            class="w-full bg-white border rounded-xl"
          >
            <div class="px-6 py-5 text-xl font-semibold leading-7">
              Acara Dibuat
            </div>
            <table-create-event
              v-if="totalHistory"
              :data="listHistory"
              :count="totalHistory"
              :page="pageHistory"
              :totalPage="totalPageHistory"
              @before="beforePageHistory"
              @next="nextPageHistory"
              :link="'/event'"
            />
            <div v-else>
              <div class="px-6 py-5 mx-auto text-center">
                <img
                  :src="require(`@/assets/images/86-empty-comment.svg`)"
                  alt="Ilustrasi"
                  class="w-[256px] inline-block"
                />
                <p class="text-lg font-semibold text-grayscale-900">
                  Belum Membuat Acara
                </p>
                <p class="text-sm text-grayscale-500">
                  Pengguna belum membuat acara apapun.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footbar-user />
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import TopbarUser from "@/components/layouts/topbarUser.vue";
import ProfilePicture from "@/components/profiles/profilePicture.vue";
import ProfileUser from "@/components/profiles/profileUser.vue";
import TableCreateEvent from "@/components/tables/tableCreateEvent.vue";
// import TableDashboard from "@/components/tables/tableDashboard.vue";
import FootbarUser from "@/components/layouts/footbarUser.vue";
import LoadingProfile from "@/components/loading/loadingProfile.vue";

export default {
  name: "AboutUs",
  components: {
    TopbarUser,
    ProfilePicture,
    ProfileUser,
    TableCreateEvent,
    // TableDashboard,
    FootbarUser,
    LoadingProfile,
  },
  setup() {
    const route = useRoute();
    const id = ref();
    const loading = ref(true);
    const selectedMenu = ref("profile");
    const detail = ref([]);
    // history
    const totalHistory = computed(() => {
      return store.getters.getTotalEventUser;
    });
    const pageHistory = computed(() => {
      return store.getters.getOffsetEventUser;
    });
    const totalPageHistory = computed(() => {
      return store.getters.getTotalPageEventUser;
    });
    const listHistory = computed(() => {
      return store.getters.getEventUser;
    });

    const beforePageHistory = () => {
      if (pageHistory.value !== 1) {
        store.commit("setOffsetEventUser", pageHistory.value - 1);
        store.dispatch("getEventUser", id.value);
      }
    };

    const nextPageHistory = () => {
      if (pageHistory.value !== totalPageHistory.value) {
        store.commit("setOffsetEventUser", pageHistory.value + 1);
        store.dispatch("getEventUser", id.value);
      }
    };

    const participatedEvents = computed(
      () => store.getters.getParticipatedEvents
    );
    const participatedEventsTotal = computed(
      () => store.getters.getParticipatedEventsTotal
    );
    const participatedEventsPage = computed(
      () => store.getters.getParticipatedEventsPage
    );
    const participatedEventsTotalPage = computed(
      () => store.getters.getParticipatedEventsTotalPage
    );

    const goToPrevParticipatedEventsPage = () => {
      if (participatedEventsPage.value !== 1) {
        store.commit(
          "setParticipatedEventsPage",
          participatedEventsPage.value - 1
        );
        store.dispatch("getParticipatedEvents", id.value);
      }
    };

    const goToNextParticipatedEventsPage = () => {
      if (participatedEventsPage.value !== participatedEventsTotalPage.value) {
        store.commit(
          "setParticipatedEventsPage",
          participatedEventsPage.value + 1
        );
        store.dispatch("getParticipatedEvents", id.value);
      }
    };

    const handleSelectedMenu = (selected) => {
      selectedMenu.value = selected;
    };

    onMounted(() => {
      loadData();
    });

    const loadData = async () => {
      await store.dispatch("getProfile", route.params.id).then((res) => {
        loading.value = false;
        store.commit("setLimitEventUser", 10);
        id.value = res.data.result.id;
        store.dispatch("getEventUser", res.data.result.id);
        store.dispatch("getParticipatedEvents", res.data.result.id);
      });
    };

    watch(
      () => {
        return store.getters.getDetail;
      },
      function () {
        detail.value = store.getters.getDetail;
      }
    );

    watch(
      () => {
        return store.getters.getEventUser;
      },
      function () {
        listHistory.value = store.getters.getEventUser;
      }
    );

    return {
      loading,
      selectedMenu,
      detail,
      totalHistory,
      pageHistory,
      totalPageHistory,
      listHistory,
      beforePageHistory,
      nextPageHistory,
      handleSelectedMenu,
      participatedEvents,
      participatedEventsTotal,
      participatedEventsPage,
      participatedEventsTotalPage,
      goToPrevParticipatedEventsPage,
      goToNextParticipatedEventsPage,
    };
  },
};
</script>
