<template>
  <div class="text-left">
    <label for="Password">{{ title }}</label>
    <div
      class="flex border rounded-lg py-[10px] px-[10px] mt-1"
      :class="
        messageError !== ''
          ? 'border-red-main'
          : focus
          ? 'border-grayscale-900'
          : 'border-grayscale-200'
      "
    >
      <img
        v-if="useIcon"
        alt="86-lock"
        :src="require(`@/assets/images/86-lock.svg`)"
        class="mr-3"
      />
      <input
        :type="show ? 'text' : 'password'"
        class="flex-1 w-full mr-3 text-base font-normal leading-6 focus:outline-none"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="onFocus"
        @blur="onLeave"
        autocomplete="off"
      />
      <img
        alt="86-show"
        :src="
          show
            ? require(`@/assets/images/86-show.svg`)
            : require(`@/assets/images/86-unshow.svg`)
        "
        class="cursor-pointer"
        @click="handleShow"
      />
    </div>
    <p
      v-show="messageError !== ''"
      class="text-red-main text-sm font-normal leading-5 mt-[6px]"
    >
      {{ messageError }}
    </p>
  </div>
</template>

<script>
export default {
  name: "InputPassword",
  data() {
    return {
      focus: false,
      show: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "Kata Sandi",
    },
    useIcon: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Masukan kata sandi anda",
    },
    value: {
      type: String,
    },
    messageError: {
      type: String,
      default: "",
    },
  },
  emits: ["update:value"],
  methods: {
    onFocus() {
      this.focus = true;
    },
    onLeave() {
      this.focus = false;
    },
    handleShow() {
      this.show = !this.show;
    },
  },
};
</script>
