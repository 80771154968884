import axios from "axios";

export default {
  state: {
    totalCategory: 0,
    totalPage: 0,
    listCategory: [],
    offset: 0,
    limit: 99999,
  },
  getters: {
    getTotalCategory: (state) => {
      return state.totalCategory;
    },
    getTotalPageCategory: (state) => {
      return state.totalPage;
    },
    getListCategory: (state) => {
      return state.listCategory;
    },
    getOffsetCategory: (state) => {
      return state.offset;
    },
  },
  actions: {
    async getListCategory({ state, commit }) {
      const url =
        process.env.VUE_APP_API_URL +
        `/api/v1/event-category?offset=${state.offset}&limit=${state.limit}`;
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setTotalCategory", response.data.result.total);
        commit("setTotalPageCategory", response.data.result.total);
        commit("setListCategory", response.data.result.datas);
        return response;
      } catch (error) {
        return error.response;
      }
    },
  },
  mutations: {
    setTotalCategory: (state, data) => {
      state.totalCategory = data;
    },
    setTotalPageCategory: (state, data) => {
      state.totalPage = Math.ceil(data / state.limit);
    },
    setListCategory: (state, data) => {
      state.listCategory = data;
    },
    setLimitCategory: (state, data) => {
      state.limit = data;
    },
  },
};
