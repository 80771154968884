<template>
  <div
    class="top-5 right-5 p-3 rounded-xl bg-white border-gray-100 shadow-lg sm:w-[400px] z-[9999999]"
    :class="showAlert ? 'fixed' : 'hidden'"
  >
    <div class="flex items-start">
      <div class="me-4">
        <img
          alt="86-success"
          :src="require(`@/assets/images/86-success.svg`)"
          class="me-2"
          v-if="status === 'success'"
        />
        <img
          alt="86-fail"
          :src="require(`@/assets/images/86-fail.svg`)"
          class="me-2"
          v-else
        />
      </div>
      <div class="flex-1 text-left">
        <div class="text-sm font-medium leading-5 text-gray-900">
          {{ title }}
        </div>
        <div class="mt-2 text-xs font-normal leading-4 text-grayscale-400">
          {{ message }}
        </div>
      </div>
      <button @click="deleteAlert">
        <img
          alt="86-close"
          :src="require(`@/assets/images/86-close.svg`)"
          class="w-[10px]"
        />
      </button>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Alert",
  props: {
    id: {
      type: String,
    },
    status: {
      type: String,
      required: true,
      default: "fail",
    },
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    message: {
      type: String,
      required: true,
      default: "Message",
    },
    showAlert: {
      type: Boolean,
    },
  },
  methods: {
    deleteAlert() {
      store.dispatch("deleteAlert", this.id);
    },
  },
};
</script>
