<template>
  <router-link :to="link">
    <button
      class="rounded-lg hover:bg-primary-100 hover:text-primary-50 w-full flex items-center py-2.5 ps-3 mb-5"
      :class="active ? 'bg-primary-100 text-primary-50' : 'text-white '"
    >
      <img
        :alt="image"
        :src="require(`@/assets/images/${image}`)"
        class="me-4"
      />
      <div class="text-base font-medium leading-6">{{ title }}</div>
    </button>
  </router-link>
</template>

<script>
export default {
  name: "ButtonLayout",
  data() {
    return {
      activeMenu: "Dashboard",
    };
  },
  props: {
    title: {
      type: String,
      required: true,
      default: "Button",
    },
    image: {
      type: String,
      required: true,
      default: "86-user.svg",
    },
    link: {
      type: String,
      required: true,
      default: "/#",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>