<template>
  <div class="bg-white border border-gray-200 shadow rounded-xl">
    <div class="p-5 border border-gray-200">
      <div class="justify-between sm:flex">
        <div>
          <div class="flex items-center">
            <div class="mr-2 text-lg font-bold leading-7">Acara</div>
            <badge :title="`${count} Acara`" :color="'purple'" />
          </div>
          <div class="mt-1 text-sm font-normal leading-5 text-gray-600">
            Semua Acara yang diselenggarakan oleh 86 University
          </div>
        </div>
        <div
          class="flex items-center mt-4 sm:mt-0"
          :class="addData ? 'block' : 'hidden'"
        >
          <button-icon
            :title="'Buat Acara Baru'"
            :size="'xs'"
            :border="'s'"
            :color="'primary'"
            :icon="'86-add.svg'"
            @click="handleAddData"
          />
        </div>
      </div>
    </div>
    <div class="justify-between p-5 sm:flex">
      <div class="flex flex-col items-stretch gap-4 sm:flex-row">
        <input-icon
          :label="'Search'"
          :icon="'86-search.svg'"
          :placeholder="'Search'"
          :value="username"
          @keyup.enter="$emit('search', $event.target.value)"
          class="sm:mr-3"
        />
        <div>
          <div class="flex h-full border rounded-lg py-[8px] px-[10px]">
            <img
              alt="86-filter"
              :src="require(`@/assets/images/86-filter.svg`)"
              width="20"
            />
            <select
              class="font-medium outline-none appearance-none ml-7"
              @change="handleFilterData($event.target.value)"
            >
              <option
                v-for="(item, index) in filter"
                :key="index"
                :value="item.value"
                :selected="selected === item.value"
                class="p-2"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <button-general
        v-if="selectData.length !== 0"
        :title="'Hapus'"
        :size="'s'"
        :border="'s'"
        :width="'auto'"
        class="inline-block text-sm border text-red-main border-red-main"
        @click="handleShowConfirmationDelete"
      />
    </div>
    <div v-if="list.length === 0" class="flex flex-col py-28">
      <div class="m-auto text-center">
        <img
          class="m-auto"
          alt="86-no-data"
          :src="require(`@/assets/images/86-no-data.svg`)"
        />
        <div class="text-lg font-semibold leading-7 mt-14 text-grayscale-900">
          Tidak ada Acara
        </div>
        <div
          class="mt-[10px] text-sm font-normal leading-[20px] text-grayscale-500"
        >
          Tekan tombol buat Acara baru untuk membuat Acara
        </div>
      </div>
    </div>
    <div v-else class="overflow-auto">
      <table class="table w-full overflow-auto table-auto">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-4 py-3 font-medium">
              <input type="checkbox" @change="selectAll" />
            </th>
            <th
              v-for="(item, index) in tableHead"
              :key="index"
              class="text-xs font-medium leading-[18px] text-gray-600 py-3 px-4 whitespace-nowrap"
            >
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in list"
            :key="index"
            class="text-sm font-normal leading-5 border text-grayscale-500"
          >
            <td class="px-4 py-3">
              <input
                type="checkbox"
                :checked="!!selectData.find((x) => x === item.id)"
                @change="selectingData(item.id)"
              />
            </td>
            <td
              class="flex items-center py-3 px-4 text-gray-900 text-sm font-medium w-[250px]"
            >
              <img
                :src="item.imageUrl"
                width="55"
                class="object-cover h-10 mr-3"
              />
              <router-link :to="`/admin/event/${item.id}`">
                {{ item.name.slice(0, 30) + "..." }}
              </router-link>
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ item.eventGroup.name.slice(0, 30) + "..." }}
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ item.user.name }}
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ item.eventCategory.displayName }}
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ ![null, undefined].includes(item.quota) ? item.quota : "-" }}
            </td>
            <td>
              <badge
                v-if="item.status === 'DRAFT'"
                :title="item.status"
                :color="'yellow'"
              />
              <badge
                v-else-if="item.status === 'EXPIRED'"
                :title="item.status"
                :color="'red'"
              />
              <badge v-else :title="item.status" :color="'green'" />
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ item.totalViewer }}
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ item.totalParticipant }}
            </td>
            <td class="px-4 py-3 text-gray-500" v-if="addData">
              <router-link
                :to="`/admin/event/${item.id}`"
                class="text-sm font-medium"
              >
                Detail
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="list.length !== 0" class="flex items-center justify-between p-5">
      <div class="hidden sm:block">Halaman {{ page }} dari {{ totalPage }}</div>
      <div class="flex flex-1 sm:flex-none">
        <button-general
          :title="'Sebelumnya'"
          :size="'s'"
          :border="'s'"
          :color="'outline'"
          class="text-sm mr-[6px]"
          :disabled="isFirstPage"
          @click="$emit('before')"
        />
        <button-general
          :title="'Selanjutnya'"
          :size="'s'"
          :border="'s'"
          :color="'outline'"
          class="text-sm"
          :disabled="isLastPage"
          @click="$emit('next')"
        />
      </div>
    </div>
    <confirmation
      :title="'Hapus acara'"
      :message="'Apakah Anda yakin menghapus acara?'"
      :show="showConfirmationDelete"
      :status="'danger'"
      @close="handleShowConfirmationDelete"
      @submit="deleteEvent"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import inputIcon from "@/components/inputs/inputIcon.vue";
import ButtonIcon from "@/components/buttons/buttonIcon.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import Confirmation from "@/components/confirmation.vue";
import Badge from "@/components/badge.vue";

export default {
  components: {
    inputIcon,
    ButtonIcon,
    ButtonGeneral,
    Confirmation,
    Badge,
  },
  name: "TableEvent",
  props: {
    addData: {
      type: Boolean,
      default: true,
    },
    count: {
      type: Number,
      required: true,
      default: 0,
    },
    search: {
      type: String,
    },
    selected: {
      type: String,
    },
    page: {
      type: Number,
      required: true,
      default: 0,
    },
    totalPage: {
      type: Number,
      required: true,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["search", "searchFilter", "next", "before", "deleteEvent"],
  setup(props, { emit }) {
    const route = useRouter();
    const filter = ref([
      {
        label: "Tampilkan Semua",
        sortBy: "created_date",
        sortValue: "ASC",
        value: "",
      },
      {
        label: "Urutkan dari A-Z",
        sortBy: "name",
        sortValue: "ASC",
        value: "nameA",
      },
      {
        label: "Urutkan dari Z-A",
        sortBy: "name",
        sortValue: "DESC",
        value: "nameD",
      },
      {
        label: "Urutkan dari tanggal dibuat",
        sortBy: "created_date",
        sortValue: "",
        value: "created",
      },
      {
        label: "Urutkan dari status Publish",
        sortBy: "PUBLISH",
        sortValue: "",
        value: "publish",
      },
      {
        label: "Urutkan dari status Draft",
        sortBy: "DRAFT",
        sortValue: "",
        value: "draft",
      },
    ]);
    const tableHead = ref([
      {
        label: "Judul Acara",
      },
      {
        label: "Nama Group",
      },
      {
        label: "Pembuat Acara",
      },
      {
        label: "Kategori Acara",
      },
      {
        label: "Kuota Acara",
      },
      {
        label: "Status",
      },
      {
        label: "Pengunjung",
      },
      {
        label: "Pendaftar",
      },
      {
        label: "",
      },
    ]);
    const selectData = ref([]);
    const handleAddData = () => {
      route.push({ path: "event/add", append: true });
    };

    const selectAll = (event) => {
      if (!event.target.checked) {
        selectData.value = [];
      } else {
        if (selectData.value.length === 10) {
          selectData.value = [];
        } else {
          props.list.map((detail) => selectData.value.push(detail.id));
        }
      }
    };

    const selectingData = (select) => {
      let find = false;
      let dummy = null;
      !!selectData.value.find((detail) => {
        if (detail === select) {
          find = true;
          dummy = selectData.value.indexOf(select);
        }
      });
      if (find) {
        selectData.value.splice(dummy, 1);
      } else {
        selectData.value.push(select);
      }
    };

    const handleFilterData = (data) => {
      let detail = filter.value.find((item) => {
        return item.value === data;
      });
      emit("searchFilter", detail.value, detail.sortBy, detail.sortValue);
    };

    const showConfirmationDelete = ref(false);
    const handleShowConfirmationDelete = () => {
      showConfirmationDelete.value = !showConfirmationDelete.value;
    };

    const deleteEvent = () => {
      emit("deleteEvent", selectData.value);
      selectData.value = [];
      handleShowConfirmationDelete();
    };

    const isFirstPage = computed(() => {
      return props.page === 1;
    });

    const isLastPage = computed(() => {
      return props.totalPage === props.page;
    });

    return {
      selectData,
      filter,
      tableHead,
      handleAddData,
      selectAll,
      selectingData,
      handleFilterData,
      showConfirmationDelete,
      handleShowConfirmationDelete,
      deleteEvent,
      isFirstPage,
      isLastPage,
    };
  },
};
</script>
