<template>
  <div class="text-left w-100 bg-primary-50">
    <div class="container px-5 py-12 mx-auto">
      <div
        class="sm:flex sm:items-end sm:justify-between pb-10 sm:pb-[72px] border-b border-gray-600"
      >
        <img
          alt="86-logo-footer"
          :src="require(`@/assets/images/86-logo-footer.svg`)"
        />
        <div class="mt-10 text-base font-semibold sm:mt-0">
          <div>Kontak Kami</div>
          <div class="mt-3">+62 81 123 123 123</div>
        </div>
      </div>
      <div class="mt-8 text-base sm:flex sm:items-center sm:justify-between">
        <div>© Copyright 2023, Angkatan 86</div>
        <div class="flex items-center mt-6 sm:mt-0">
          <a
            href="https://www.facebook.com/groups/angkatanlapanenam/?mibextid=NSMWBT"
            rel="noreferrer noopener"
            target="_blank"
            class="mr-8"
          >
            <img
              alt="86-facebook"
              :src="require(`@/assets/images/86-facebook-bold.svg`)"
            />
          </a>
          <a
            href="https://www.instagram.com/delapanenamuniversity"
            rel="noreferrer noopener"
            target="_blank"
            class="mr-8"
          >
            <img
              alt="86-instagram"
              :src="require(`@/assets/images/86-instagram-bold.svg`)"
            />
          </a>
          <a
            href="https://www.tiktok.com/@delapan.enam.univ?_t=8eWKsNYmvix&_r=1"
            rel="noreferrer noopener"
            target="_blank"
          >
            <img
              alt="86-tiktok"
              :src="require(`@/assets/images/86-tiktok-bold.svg`)"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FootbarUser",
};
</script>
