<template>
  <div class="overflow-auto">
    <table class="table w-full overflow-auto table-fixed sm:table-auto">
      <thead class="bg-gray-50" v-if="thead !== undefined">
        <tr>
          <th
            v-for="(item, index) in thead"
            :key="index"
            class="text-xs font-medium leading-[18px] text-gray-600 py-3 px-4 w-[200px]"
          >
            {{ item.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in data"
          :key="index"
          class="text-sm font-normal leading-5 text-grayscale-500 border-y"
        >
          <td class="py-3 px-4 w-[100px]">
            <img
              :alt="item.name"
              :src="item.imageUrl"
              width="55"
              class="object-cover h-10"
            />
          </td>
          <td class="w-auto px-4 py-3">
            <div class="text-sm font-semibold leading-5 text-grayscale-900">
              {{ item.name }}
            </div>
            <div class="text-sm font-medium leading-5 text-grayscale-700">
              <template v-if="date(item.startDate) == date(item.endDate)">
                {{ date(item.startDate) }} &#x2022;
                <template v-if="time(item.startDate) == time(item.endDate)">
                  {{ time(item.startDate) }}
                </template>
                <template v-else>
                  {{ time(item.startDate) }} -
                  {{ time(item.endDate) }}
                </template>
              </template>
              <template v-else>
                {{ date(item.startDate) }} &#x2022;
                {{ time(item.startDate) }}
                -
                {{ date(item.endDate) }} &#x2022;
                {{ time(item.endDate) }}
              </template>
            </div>
          </td>
          <td
            class="px-4 py-3 text-sm font-semibold leading-5 align-middle cursor-pointer text-grayscale-900"
          >
            <router-link :to="link + `/${item.id}`"> Detail </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="data.length !== 0" class="flex items-center justify-between p-5">
      <div class="hidden sm:block">Halaman {{ page }} dari {{ totalPage }}</div>
      <div class="flex flex-1 sm:flex-none">
        <button-general
          :title="'Sebelumnya'"
          :size="'s'"
          :color="'outline'"
          class="text-sm mr-[6px]"
          @click="$emit('before')"
          :disabled="isFirstPage"
        />
        <button-general
          :title="'Selanjutnya'"
          :size="'s'"
          :color="'outline'"
          class="text-sm"
          @click="$emit('next')"
          :disabled="isLastPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import { computed } from "vue";

export default {
  name: "TableCreateEvent",
  components: { ButtonGeneral },
  props: {
    thead: {
      type: Object,
      default() {},
    },
    data: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      required: true,
      default: 0,
    },
    page: {
      type: Number,
      required: true,
      default: 0,
    },
    totalPage: {
      type: Number,
      required: true,
      default: 0,
    },
    link: {
      type: String,
    },
  },
  setup(props) {
    const date = (value) => {
      return moment(value, "DD/MM/YYYY")
        .lang("id")
        .format("dddd, DD MMMM YYYY");
    };
    const time = (value) => {
      return moment(value.split(" ")[1], "HH:mm").lang("id").format("HH:mm");
    };

    const isFirstPage = computed(() => {
      return props.page === 1;
    });

    const isLastPage = computed(() => {
      return props.totalPage === props.page;
    });

    return { date, time, isFirstPage, isLastPage };
  },
};
</script>
