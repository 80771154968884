import axios from "axios";

export default {
  state: {
    totalGroup: 0,
    totalPage: 0,
    listGroup: [],
    offset: 0,
    limit: 99999,
  },
  getters: {
    getTotalGroup: (state) => {
      return state.totalGroup;
    },
    getTotalPageGroup: (state) => {
      return state.totalPage;
    },
    getOffsetGroup: (state) => {
      return state.offset;
    },
    getListGroup: (state) => {
      return state.listGroup;
    },
  },
  actions: {
    async getListGroup({ state, commit }) {
      const params = {
        offset: state.offset,
        limit: state.limit,
      };
      const url =
        process.env.VUE_APP_API_URL +
        "/api/v1/event-group?" +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setTotalGroup", response.data.result.total);
        commit("setTotalPageGroup", response.data.result.total);
        commit("setListGroup", response.data.result.datas);
        return response;
      } catch (error) {
        commit("setTotalGroup", 0);
        commit("setTotalPageGroup", 0);
        commit("setListGroup", []);
        return error.response;
      }
    },
  },
  mutations: {
    setTotalGroup: (state, data) => {
      state.totalGroup = data;
    },
    setTotalPageGroup: (state, data) => {
      state.totalPage = Math.ceil(data / state.limit);
    },
    setOffsetGroup: (state, data) => {
      state.offset = data;
    },
    setLimitGroup: (state, data) => {
      state.limit = data;
    },
    setListGroup: (state, data) => {
      state.listGroup = data;
    },
  },
};
