<script setup>
import store from "@/store";
import { ref, defineProps, onMounted, computed } from "vue";
import MiniNotificationUser from "@/components/profiles/miniNotificationUser.vue";
import axios from "axios";

defineProps({
  style: {
    validator(value) {
      return ["white", "normal"].includes(value);
    },
  },
});

onMounted(() => {
  getNotifications();
});

const detailAuth = computed(() => store.getters.getDetailAuth);
const showMenu = ref(false);
const showNotificationMenu = ref(false);

const handleShowMenu = () => {
  showMenu.value = !showMenu.value;
};

const handleShowNotificationMenu = () => {
  showNotificationMenu.value = !showNotificationMenu.value;
};

const confirmLogout = () => {
  localStorage.removeItem("86");
  window.location.reload();
};

const handleShowConfirmation = () => {
  store.commit("setShowConfirmation", !store.getters.getShowConfirmation);
};

const totalUnreadNotifications = ref(0);
const getNotifications = async () => {
  const params = {
    offset: 1,
    limit: 1,
  };
  const url =
    process.env.VUE_APP_API_URL +
    "/api/v2/notification?" +
    new URLSearchParams(params).toString();
  try {
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
      },
    });
    totalUnreadNotifications.value = response.data.result.totalUnread;
  } catch (error) {
    console.error(error);
  }
};
</script>
<template>
  <div>
    <div class="flex items-center">
      <div class="relative">
        <button
          @click="handleShowNotificationMenu"
          class="relative p-2 bg-white rounded-full me-4"
        >
          <img
            alt="86-bell-user"
            :src="require(`@/assets/images/86-bell-user.svg`)"
            class="cursor-pointer"
            width="24"
          />
          <span
            v-if="totalUnreadNotifications"
            class="absolute bg-secondary text-white px-1 py-0.5 text-[8px] rounded-full -top-1 -right-1 min-w-[16px]"
          >
            {{ totalUnreadNotifications }}
          </span>
        </button>
        <MiniNotificationUser
          v-if="showNotificationMenu"
          class="z-50 absolute right-0 w-[450px]"
        />
      </div>
      <div class="relative z-50">
        <div class="flex items-center cursor-pointer" @click="handleShowMenu">
          <img
            v-if="detailAuth?.user?.picture !== ''"
            alt="86-user-white"
            :src="detailAuth?.user?.picture"
            class="object-cover h-10 rounded-full me-2"
            width="40"
          />
          <div v-else class="p-2 rounded-full me-2 bg-grayscale-900">
            <img
              alt="86-user-white"
              :src="require(`@/assets/images/86-user-white.svg`)"
              width="24"
            />
          </div>
          <div class="text-base font-medium leading-6 text-gray-900 me-3">
            {{ detailAuth?.user?.name }}
          </div>
          <img
            alt="86-arrow-down"
            :src="require(`@/assets/images/86-arrow-down.svg`)"
            width="24"
          />
        </div>
        <div
          v-if="showMenu"
          class="absolute bg-white text-left w-[173px] rounded-lg top-10 left-10 overflow-hidden font-medium"
        >
          <router-link :to="'/profile'">
            <div class="p-4 hover:bg-grayscale-100">Lihat Profil</div>
          </router-link>
          <button
            class="w-full p-4 text-left hover:bg-grayscale-100"
            @click="handleShowConfirmation"
          >
            Keluar Akun
          </button>
        </div>
      </div>
    </div>
    <confirmation
      :title="'Keluar Akun'"
      :message="'Apakah anda yakin ingin keluar dari akun anda? Anda perlu masuk kembali untuk mengakses akun anda.'"
      @close="handleShowConfirmation"
      @submit="confirmLogout"
    />
  </div>
</template>
