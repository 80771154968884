<template>
  <div class="sm:flex sm:items-start">
    <div
      class="w-full sm:w-2/6 mr-[18px] rounded-2xl h-[320px] bg-grayscale-300 animate-pulse"
    ></div>
    <div class="flex-1 w-full mt-6 sm:mt-0">
      <div class="flex">
        <div
          class="rounded-2xl w-[120px] h-[24px] bg-grayscale-300 animate-pulse"
        ></div>
        <div
          class="ml-4 rounded-2xl w-[120px] h-[24px] bg-grayscale-300 animate-pulse"
        ></div>
        <div
          class="ml-4 rounded-2xl w-[120px] h-[24px] bg-grayscale-300 animate-pulse"
        ></div>
      </div>
      <div
        class="mt-8 rounded-full w-full h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[400px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[600px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[200px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[400px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[600px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[200px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
    </div>
  </div>
</template>
