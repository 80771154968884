import axios from "axios";

export default {
  state: {
    dataUser: [],
    dataEvent: [],
    dataActivities: [],
    dataConclusion: null,
    yearConclusion: 2023,
  },
  getters: {
    getDataUserDashboard: (state) => {
      return state.dataUser;
    },
    getDataEventDashboard: (state) => {
      return state.dataEvent;
    },
    getDataActivitiesDashboard: (state) => {
      return state.dataActivities;
    },
    getDataConclusionDashboard: (state) => {
      return state.dataConclusion;
    },
  },
  actions: {
    async getListUserDashboard({ commit }) {
      const url = process.env.VUE_APP_API_URL + "/api/v1/dashboard/total-user";
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setDataUserDashboard", response.data.result);
        return response;
      } catch (error) {
        commit("setDataUserDashboard", []);
        return error.response;
      }
    },
    async getListEventDashboard({ commit }) {
      const url = process.env.VUE_APP_API_URL + "/api/v1/dashboard/total-event";
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setDataEventDashboard", response.data.result);
        return response;
      } catch (error) {
        commit("setDataEventDashboard", []);
        return error.response;
      }
    },
    async getListActivitiesDashboard({ commit }) {
      const url =
        process.env.VUE_APP_API_URL + "/api/v1/dashboard/activity-summary";
      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `
							Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        commit("setDataActivitiesDashboard", response.data.result);
        return response;
      } catch (error) {
        commit("setDataActivitiesDashboard", []);
        return error.response;
      }
    },
    async getListConclusionDashboard({ state, commit }) {
      const url =
        process.env.VUE_APP_API_URL +
        `/api/v1/dashboard/compared-user?year=${state.yearConclusion}`;
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setDataConclusionDashboard", response.data.result);
        return response;
      } catch (error) {
        commit("setDataConclusionDashboard", []);
        return error.response;
      }
    },
  },
  mutations: {
    setDataUserDashboard: (state, data) => {
      state.dataUser = data;
    },
    setDataEventDashboard: (state, data) => {
      state.dataEvent = data;
    },
    setDataActivitiesDashboard: (state, data) => {
      state.dataActivities = data;
    },
    setDataConclusionDashboard: (state, data) => {
      state.dataConclusion = data;
    },
    setYearConclusionDashboard: (state, data) => {
      state.yearConclusion = data;
    },
  },
};
