<template>
  <div class="grid grid-cols-1 gap-10 px-5 sm:grid-cols-4 sm:gap-6">
    <div class="col rounded-2xl h-[320px] bg-grayscale-300 animate-pulse"></div>
    <div class="sm:col-span-3">
      <div class="flex">
        <div
          class="rounded-2xl w-[120px] h-[24px] bg-grayscale-300 animate-pulse"
        ></div>
        <div
          class="ml-4 rounded-2xl w-[120px] h-[24px] bg-grayscale-300 animate-pulse"
        ></div>
        <div
          class="ml-4 rounded-2xl w-[120px] h-[24px] bg-grayscale-300 animate-pulse"
        ></div>
      </div>
      <div
        class="mt-8 rounded-full w-full h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[400px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[600px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[200px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[400px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[600px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
      <div
        class="mt-8 rounded-full w-full max-w-[200px] h-[48px] bg-grayscale-300 animate-pulse"
      ></div>
    </div>
  </div>
</template>
