<template>
  <div class="w-full p-4 bg-white border-gray-200 shadow rounded-xl">
    <div class="flex">
      <img
        alt="86-user"
        :src="require(`@/assets/images/${image}`)"
        class="me-4"
      />
      <div class="text-base font-medium leading-6 text-gray-900">
        {{ title }}
      </div>
    </div>
    <div class="grid justify-between grid-cols-3 gap-4">
      <div class="col-span-2">
        <div class="text-4xl font-semibold leading-[44px]">
          {{ data.total }}
        </div>
        <div class="flex items-center text-xs font-normal leading-4">
          <img
            alt="86-grow-up"
            :src="require(`@/assets/images/86-grow-up.svg`)"
            class="me-1"
            width="10"
            v-if="score < 0"
          />
          <img
            alt="86-grow-up"
            :src="require(`@/assets/images/86-grow-up.svg`)"
            class="me-1"
            width="10"
            v-else-if="score > 0"
          />
          <div>
            <div v-if="score === 0">Sama dari bulan lalu</div>
            <div v-else>
              <span
                :class="
                  score < 0
                    ? 'text-red-main'
                    : score === 0
                    ? 'text-primary-50'
                    : 'text-green'
                "
              >
                {{ data.percentageValue }}
              </span>
              vs last month
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <Line
          class="h-full"
          :data="chartData"
          :style="myStyles"
          :options="options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);

export default {
  name: "Metric",
  components: { Line },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
      },
    };
  },
  computed: {
    myStyles() {
      return {
        width: "121px",
        position: "relative",
      };
    },
  },
  props: {
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    image: {
      type: String,
      required: true,
      default: "86-user-black.svg",
    },
    data: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    percentage: {
      type: Number,
      required: true,
      default: -20,
    },
    list: {
      type: Object,
      required: true,
      default() {},
    },
  },
  setup(props) {
    const score = computed(() => {
      return parseFloat(props.data.percentageValue) / 100;
    });

    const chartDataByScore = computed(() => {
      return score.value > 0
        ? [0, 2, 1, 3]
        : score.value == 0
        ? [1, 1]
        : [3, 1, 2, 0];
    });

    const chartData = ref({
      labels: chartDataByScore.value,
      datasets: [
        {
          label: "Data One",
          lineTension: 0.4,
          borderColor:
            score.value < 0
              ? "#FC0D24"
              : score.value === 0
              ? "#FDCA31"
              : "#12B76A",
          radius: 0,
          fill: true,
          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(100, 0, 100, 100);

            gradient.addColorStop(
              0,
              score.value < 0
                ? "#FC0D24"
                : score.value === 0
                ? "#FDCA31"
                : "#12B76A"
            );
            gradient.addColorStop(1, "white");

            return gradient;
          },
          data: chartDataByScore.value,
        },
      ],
    });

    return { score, chartData };
  },
};
</script>
