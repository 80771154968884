<template>
  <div>
    <div class="flex w-full">
      <sidebar />
      <div class="flex-1 w-full min-w-0">
        <topbar />
        <div
          class="bg-grayscale-50 py-4 px-5 text-left mt-[72px] sm:mt-0"
          style="min-height: calc(100vh - 72px)"
        >
          <breadcrumb :title="'Acara'" :list="listMenu" />
          <loading-table v-if="loading" />
          <table-event
            v-else
            class="mt-5"
            :count="totalEvent"
            :page="page"
            :totalPage="totalPage"
            :list="listEvent"
            :search="search"
            :selected="selectedFilter"
            @search="searchBySearch"
            @searchFilter="searchFilter"
            @before="beforePageEvent"
            @next="nextPageEvent"
            @deleteEvent="deleteEvent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Sidebar from "@/components/layouts/sidebar.vue";
import Topbar from "@/components/layouts/topbar.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import TableEvent from "@/components/tables/tableEvent.vue";
import LoadingTable from "@/components/loading/loadingTable.vue";

export default {
  components: {
    Sidebar,
    Topbar,
    Breadcrumb,
    TableEvent,
    LoadingTable,
  },
  name: "ListEvent",
  setup() {
    const store = useStore();
    const loading = ref(true);
    const listMenu = ref([
      {
        title: "Beranda",
        link: "/admin",
      },
      {
        title: "List Acara",
        link: "/admin/event",
      },
    ]);
    const totalEvent = computed(() => {
      return store.getters.getTotalEvent;
    });
    const page = computed(() => {
      return store.getters.getOffsetEvent;
    });
    const totalPage = computed(() => {
      return store.getters.getTotalPageEvent;
    });
    const listEvent = computed(() => {
      return store.getters.getListEvent;
    });
    const search = computed(() => {
      return store.getters.getSearchUser;
    });
    const selectedFilter = ref("");

    const searchBySearch = (data) => {
      store.commit("setSearchEvent", data);
      store.dispatch("getListEvent");
    };

    const searchFilter = (selected, searchBy, sortValue) => {
      selectedFilter.value = selected;
      store.commit("setSortByEvent", searchBy);
      store.commit("setSortValueEvent", sortValue);
      store.dispatch("getListEvent");
    };

    onMounted(() => {
      store.commit("setActiveMenu", "Acara");
      store.dispatch("getListEvent").then(() => {
        loading.value = false;
      });
    });

    onUnmounted(() => {
      store.commit("setOffsetEvent", 1);
    });

    const beforePageEvent = () => {
      if (page.value !== 1) {
        store.commit("setOffsetEvent", page.value - 1);
        store.dispatch("getListEvent");
      }
    };

    const nextPageEvent = () => {
      if (page.value !== totalPage.value) {
        store.commit("setOffsetEvent", page.value + 1);
        store.dispatch("getListEvent");
      }
    };

    const deleteEvent = (data) => {
      loading.value = true;
      store.dispatch("deleteEvent", data).then(() => {
        store.commit("setOffsetEvent", 1);
        store.dispatch("getListEvent").then(() => {
          loading.value = false;
        });
      });
    };

    return {
      loading,
      listMenu,
      totalEvent,
      page,
      totalPage,
      listEvent,
      search,
      selectedFilter,
      searchBySearch,
      searchFilter,
      beforePageEvent,
      nextPageEvent,
      deleteEvent,
    };
  },
};
</script>
