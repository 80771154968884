<script setup>
import axios from "axios";
import { ref, defineProps, onMounted } from "vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import cardEventUser from "@/components/events/cardEventUser.vue";

const props = defineProps(["groupId", "ignoreEventIds"]);

const events = ref([]);

const getEventsByGroupId = async (groupId) => {
  const params = {
    offset: 1,
    limit: 3 + props.ignoreEventIds?.length,
    groupId,
  };
  const url =
    process.env.VUE_APP_API_URL +
    "/api/v1/event?" +
    new URLSearchParams(params).toString();
  try {
    const response = await axios({
      method: "get",
      url: url,
    });
    events.value = response.data.result.datas.filter(
      (item) => !props.ignoreEventIds.includes(item.id)
    );
  } catch (error) {
    events.value = [];
  }
};

onMounted(() => {
  getEventsByGroupId(props.groupId);
});
</script>

<template>
  <div v-if="events.length" class="w-100">
    <div class="container">
      <div class="flex items-center justify-between">
        <div class="flex item-center">
          <div class="w-2 mr-4 rounded-full bg-secondary"></div>
          <div
            class="text-xl leading-10 sm:text-[40px] font-semibold sm:leading-[56px] text-grayscale-900"
          >
            Acara Terkait
          </div>
        </div>
        <router-link :to="'/event?g=' + groupId">
          <button-general
            :title="'Lihat Semua'"
            :size="'s'"
            :color="'outline'"
            :width="'auto'"
            class="text-xs sm:text-base"
          />
        </router-link>
      </div>
      <div class="grid grid-cols-1 gap-6 mt-5 sm:grid-cols-3 sm:gap-8 sm:mt-8">
        <div v-for="(item, index) in events.slice(0, 3)" :key="index">
          <card-event-user :detail="item" />
        </div>
      </div>
    </div>
  </div>
</template>
