<template>
  <div class="mb-6">
    <div class="text-base font-semibold leading-6 first-letter:uppercase">
      {{ title }}
    </div>
    <div class="flex">
      <div
        v-for="(data, index) in list"
        :key="index"
        class="text-xs font-normal leading-4 first-letter:uppercase"
        :class="
          Object.keys(list).length - 1 === index &&
          'text-primary-50 first-letter:uppercase'
        "
      >
        <router-link :to="data.link" class="flex" @click="resetState">
          {{ data.title }}
          <span
            class="mx-2"
            :class="Object.keys(list).length - 1 === index && 'hidden'"
          >
            <img
              alt="86-chevron-right"
              :src="require(`@/assets/images/86-chevron-right.svg`)"
              width="18"
            />
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "breadcrumb",
  props: {
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    list: {
      type: Object,
      required: true,
      default() {},
    },
  },
};
</script>
