<template>
  <div class="bg-white">
    <topbar-user />
    <loading-profile v-if="loading" class="container mx-auto my-20" />
    <template v-else>
      <alert-top
        :showAlert="showSaveProfileSuccessAlert"
        :status="'success'"
        :title="'Berhasil Mengedit Profil'"
        :message="'Anda berhasil mengedit data pribadi Anda.'"
        @close="closeSaveProfileSuccessAlert"
      />
      <alert-top
        :showAlert="showSaveProfileErrorAlert"
        :title="'Terjadi kesalahan'"
        :message="'Gagal menyimpan profil'"
        @close="closeSaveProfileErrorAlert"
      />
      <div class="container px-5 py-20 mx-auto" v-if="!loading">
        <div class="grid items-start grid-cols-1 sm:grid-cols-4 sm:gap-6">
          <div class="bg-white border rounded-xl">
            <div
              class="px-4 py-5 text-lg font-semibold leading-7 border-b border-b-grayscale-100"
            >
              Foto Profile
            </div>
            <div class="p-4">
              <upload-image-2
                :img="detail.picture || ''"
                @updateImage="changeImage"
              />
            </div>
          </div>

          <div class="col-span-3 mt-10 text-left sm:mt-0">
            <div class="overflow-auto">
              <div class="flex mb-6 sm:text-lg">
                <div
                  class="pb-3 mx-3 cursor-pointer"
                  :class="
                    selectedMenu === 'profile' &&
                    'border-b-2 border-b-primary-50 text-primary-50'
                  "
                  @click="handleSelectedMenu('profile')"
                >
                  Data Pribadi
                </div>
                <div
                  class="pb-3 mx-3 cursor-pointer"
                  :class="
                    selectedMenu === 'history' &&
                    'border-b-2 border-b-primary-50 text-primary-50'
                  "
                  @click="handleSelectedMenu('history')"
                >
                  Riwayat Acara
                </div>
                <div
                  class="pb-3 mx-3 cursor-pointer"
                  :class="
                    selectedMenu === 'create' &&
                    'border-b-2 border-b-primary-50 text-primary-50'
                  "
                  @click="handleSelectedMenu('create')"
                >
                  Acara Dibuat
                </div>
              </div>
            </div>
            <div v-if="selectedMenu === 'profile'">
              <div
                class="flex items-center justify-between flex-1 p-4 mb-6 border rounded-xl"
                v-if="!detailAuth.user?.isProfileComplete"
              >
                <img
                  alt="86-complete-profile"
                  :src="require(`@/assets/images/86-complete-profile.svg`)"
                  class="me-4"
                  width="44"
                />
                <div class="flex-1 text-left">
                  <div class="text-xs sm:text-base">
                    Ada beberapa bagian yang belum anda lengkapi. Saatnya
                    melengkapi profil anda!
                  </div>
                </div>
              </div>
              <profile-edit
                :detail="detail"
                @submit="submitData"
                @cancel="wantToCancel = true"
              />
            </div>
            <div
              v-else-if="selectedMenu === 'history'"
              class="w-full bg-white border rounded-xl"
            >
              <div class="px-6 py-5 text-xl font-semibold leading-7">
                Riwayat Acara
              </div>
              <table-create-event
                v-if="participatedEventsTotal"
                :data="participatedEvents"
                :count="participatedEventsTotal"
                :page="participatedEventsPage"
                :totalPage="participatedEventsTotalPage"
                @before="goToPrevParticipatedEventsPage"
                @next="goToNextParticipatedEventsPage"
                :link="'/event'"
              />
              <div v-else>
                <div class="px-6 py-5 mx-auto text-center">
                  <img
                    :src="require(`@/assets/images/86-empty-comment.svg`)"
                    alt="Ilustrasi"
                    class="w-[256px] inline-block"
                  />
                  <p class="text-lg font-semibold text-grayscale-900">
                    Belum Mengikuti Acara
                  </p>
                  <p class="text-sm text-grayscale-500">
                    Pengguna belum mengikuti acara apapun.
                  </p>
                </div>
              </div>
            </div>
            <div
              v-else-if="selectedMenu === 'create'"
              class="w-full bg-white border rounded-xl"
            >
              <div class="px-6 py-5 text-xl font-semibold leading-7">
                Acara Dibuat
              </div>
              <table-create-event
                v-if="totalHistory"
                :data="listHistory"
                :count="totalHistory"
                :page="pageHistory"
                :totalPage="totalPageHistory"
                @before="beforePageHistory"
                @next="nextPageHistory"
                :link="'/event'"
              />
              <div v-else>
                <div class="px-6 py-5 mx-auto text-center">
                  <img
                    :src="require(`@/assets/images/86-empty-comment.svg`)"
                    alt="Ilustrasi"
                    class="w-[256px] inline-block"
                  />
                  <p class="text-lg font-semibold text-grayscale-900">
                    Belum Membuat Acara
                  </p>
                  <p class="text-sm text-grayscale-500">
                    Pengguna belum membuat acara apapun.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footbar-user />
    </template>

    <modal-template
      @close="wantToCancel = false"
      :show="wantToCancel"
      :show-icon="true"
      icon-type="alert"
    >
      <div class="px-5 pb-5 text-left sm:last:p-6">
        <div class="text-lg font-semibold">Batalkan edit profil</div>
        <div class="text-sm text-grayscale-500">
          Apakah Anda yakin ingin membatalkan edit profil?
        </div>
      </div>
      <div class="flex justify-end p-5 sm:p-6">
        <button-general
          :title="'Tidak'"
          :size="'s'"
          :width="'full'"
          :color="'outline'"
          class="mr-[6px]"
          @click="wantToCancel = false"
        />
        <button-general
          :title="'Ya'"
          :size="'s'"
          :width="'full'"
          :color="'primary'"
          @click="cancel"
        />
      </div>
    </modal-template>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import store from "@/store";
import TopbarUser from "@/components/layouts/topbarUser.vue";
import UploadImage2 from "@/components/uploadImage2.vue";
import ProfileEdit from "@/components/profiles/profileEdit.vue";
import TableCreateEvent from "@/components/tables/tableCreateEvent.vue";
import FootbarUser from "@/components/layouts/footbarUser.vue";
import AlertTop from "@/components/alertTop.vue";
import LoadingProfile from "@/components/loading/loadingProfile.vue";
import ModalTemplate from "@/components/modalTemplate.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";

export default {
  name: "AboutUs",
  components: {
    TopbarUser,
    UploadImage2,
    ProfileEdit,
    TableCreateEvent,
    FootbarUser,
    AlertTop,
    LoadingProfile,
    ModalTemplate,
    ButtonGeneral,
  },
  setup() {
    const token = JSON.parse(window.localStorage.getItem("86"));
    const id = ref();
    const loading = ref(true);
    const detailAuth = computed(() => {
      return store.getters.getDetailAuth;
    });
    const selectedMenu = ref("profile");
    const detail = ref([]);
    const dummyImage = ref("");
    // history
    const totalHistory = computed(() => {
      return store.getters.getTotalEventUser;
    });
    const pageHistory = computed(() => {
      return store.getters.getOffsetEventUser;
    });
    const totalPageHistory = computed(() => {
      return store.getters.getTotalPageEventUser;
    });
    const listHistory = ref([]);

    const beforePageHistory = () => {
      if (pageHistory.value !== 1) {
        store.commit("setOffsetEventUser", pageHistory.value - 1);
        store.dispatch("getEventUser", id.value);
      }
    };

    const nextPageHistory = () => {
      if (pageHistory.value !== totalPageHistory.value) {
        store.commit("setOffsetEventUser", pageHistory.value + 1);
        store.dispatch("getEventUser", id.value);
      }
    };

    const participatedEvents = computed(
      () => store.getters.getParticipatedEvents
    );
    const participatedEventsTotal = computed(
      () => store.getters.getParticipatedEventsTotal
    );
    const participatedEventsPage = computed(
      () => store.getters.getParticipatedEventsPage
    );
    const participatedEventsTotalPage = computed(
      () => store.getters.getParticipatedEventsTotalPage
    );

    const goToPrevParticipatedEventsPage = () => {
      if (participatedEventsPage.value !== 1) {
        store.commit(
          "setParticipatedEventsPage",
          participatedEventsPage.value - 1
        );
        store.dispatch("getParticipatedEvents", id.value);
      }
    };

    const goToNextParticipatedEventsPage = () => {
      if (participatedEventsPage.value !== participatedEventsTotalPage.value) {
        store.commit(
          "setParticipatedEventsPage",
          participatedEventsPage.value + 1
        );
        store.dispatch("getParticipatedEvents", id.value);
      }
    };

    const changeImage = (data) => {
      dummyImage.value = data;
    };

    const submitData = async (data) => {
      showSaveProfileSuccessAlert.value = false;
      showSaveProfileErrorAlert.value = false;

      let isSuccess = false;
      if (dummyImage.value === "") {
        isSuccess = await store.dispatch("updateProfile", { data: data });
      } else if (dummyImage.value === null) {
        isSuccess = store.dispatch("updateProfile", {
          data: { ...data, picture: null },
        });
      } else {
        const res = await store.dispatch("uploadImage", dummyImage.value);
        isSuccess = await store.dispatch("updateProfile", {
          data: {
            ...data,
            picture: res.data.result,
          },
        });
      }

      if (isSuccess) {
        loadData();
      }
    };

    const handleSelectedMenu = (selected) => {
      selectedMenu.value = selected;
    };

    onMounted(() => {
      loadData();
    });

    const loadData = async () => {
      loading.value = true;
      await store.dispatch("getUpdateUser");
      await store.dispatch("getProfile", token.user.email).then((res) => {
        loading.value = false;
        store.commit("setLimitEventUser", 10);
        id.value = res.data.result.id;
        store.dispatch("getEventUser", res.data.result.id);
        store.dispatch("getParticipatedEvents", res.data.result.id);
      });
      await store.dispatch("getListJob");
    };

    watch(
      () => {
        return store.getters.getDetail;
      },
      function () {
        detail.value = store.getters.getDetail;
      }
    );

    watch(
      () => {
        return store.getters.getEventUser;
      },
      function () {
        listHistory.value = store.getters.getEventUser;
      }
    );

    watch(
      () => {
        return store.getters.getDetailAuth;
      },
      function () {
        detailAuth.value = store.getters.getDetailAuth;
      }
    );

    const showSaveProfileSuccessAlert = ref(false);

    const closeSaveProfileSuccessAlert = () => {
      showSaveProfileSuccessAlert.value = false;
    };

    const showSaveProfileErrorAlert = ref(false);

    const closeSaveProfileErrorAlert = () => {
      showSaveProfileErrorAlert.value = false;
    };

    const wantToCancel = ref(false);

    const cancel = () => {
      wantToCancel.value = false;
      loadData();
    };

    return {
      loading,
      detailAuth,
      selectedMenu,
      detail,
      totalHistory,
      pageHistory,
      totalPageHistory,
      listHistory,
      beforePageHistory,
      nextPageHistory,
      changeImage,
      submitData,
      handleSelectedMenu,
      showSaveProfileSuccessAlert,
      closeSaveProfileSuccessAlert,
      showSaveProfileErrorAlert,
      closeSaveProfileErrorAlert,
      participatedEvents,
      participatedEventsTotal,
      participatedEventsPage,
      participatedEventsTotalPage,
      goToPrevParticipatedEventsPage,
      goToNextParticipatedEventsPage,
      wantToCancel,
      cancel,
    };
  },
};
</script>
