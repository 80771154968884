<template>
  <div>
    <div class="text-2xl font-semibold">
      Partisipan Acara ({{ totalParticipant }})
    </div>
    <div class="mt-6 border rounded-2xl">
      <div class="p-6">
        <div class="flex border rounded-lg py-[10px] sm:py-4 px-4 sm:px-6">
          <img
            alt="86-search"
            :src="require(`@/assets/images/86-search.svg`)"
            class="w-6 h-6 mr-2"
          />
          <input
            type="text"
            class="flex-1 mr-3 text-sm font-normal leading-6 sm:text-base focus:outline-none me-4"
            placeholder="Cari partisipan.."
            v-model="search"
            @keyup.enter="
              $emit('search', {
                search: $event.target.value,
                offset: currentPage,
              })
            "
          />
        </div>
      </div>
      <div v-if="list?.length">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="flex px-6 py-4 border-t"
        >
          <img
            alt="86-profile"
            :src="item.picture"
            class="object-cover w-12 h-12 mr-4 rounded-full"
          />
          <div class="flex flex-col flex-1 sm:flex-row">
            <div class="flex-1">
              <router-link
                :to="`/profile/${item.username}`"
                class="font-medium hover:underline"
              >
                {{ item.name }}
              </router-link>
              <div class="text-xs">{{ item.highSchool || "-" }}</div>
              <div
                v-if="item.id !== creator"
                class="mt-3 text-xs font-medium text-secondary"
              >
                Pendaftar ke-{{ (currentPage - 1) * 9 + index }}
              </div>
            </div>
            <div class="flex items-center">
              <div
                v-if="item.id === creator"
                class="px-4 py-2 mt-3 text-xs border rounded-full sm:mt-0 sm:ml-3"
              >
                Pembuat Acara
              </div>
            </div>
          </div>
        </div>
        <div>
          <pagination
            v-model="currentPage"
            :per-page="perPage"
            :total="totalParticipant || 0"
            class="p-6 border-t"
          />
        </div>
      </div>
      <div v-else class="px-6 pb-6">
        <div class="mx-auto text-center">
          <img
            :src="require(`@/assets/images/86-empty-comment.svg`)"
            alt="Belum Ada Komentar"
            class="w-[256px] inline-block"
          />
          <p class="text-lg font-semibold text-grayscale-900">
            Tidak Ada Partisipan
          </p>
          <p class="text-sm text-grayscale-500">
            Acara ini tidak memiliki partisipan.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import { ref, watch } from "vue";

export default {
  name: "ListParticipant",
  components: {
    Pagination,
  },
  props: {
    creator: {
      type: Number,
    },
    totalParticipant: {
      type: Number,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["search"],
  setup(_, { emit }) {
    const search = ref("");
    const currentPage = ref(1);
    const perPage = ref(9);

    watch(currentPage, () => {
      emit("search", {
        search: search.value,
        offset: currentPage.value,
      });
    });

    return {
      search,
      currentPage,
      perPage,
    };
  },
};
</script>
