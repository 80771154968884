<template>
  <div>
    <div class="bg-white border rounded-2xl">
      <div class="p-6">
        <div class="flex border rounded-lg py-[10px] sm:py-4 px-4 sm:px-6">
          <img
            alt="86-search"
            :src="require(`@/assets/images/86-search.svg`)"
            class="w-6 h-6 mr-2"
          />
          <input
            type="text"
            class="flex-1 mr-3 text-sm font-normal leading-6 sm:text-base focus:outline-none me-4"
            placeholder="Cari partisipan.."
            v-model="search"
            @keyup.enter="
              $emit('search', {
                search: $event.target.value,
                offset: currentPage,
              })
            "
          />
        </div>
      </div>
      <div v-if="list?.length">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="flex px-6 py-4 border-t"
        >
          <img
            alt="86-profile"
            :src="item.picture"
            class="object-cover w-12 h-12 mr-4 rounded-full"
          />
          <div class="flex flex-1">
            <div class="flex flex-col flex-1 sm:flex-row">
              <div class="flex-1">
                <router-link
                  :to="`/profile/${item.username}`"
                  class="font-medium hover:underline"
                >
                  {{ item.name }}
                </router-link>
                <div class="text-xs">@{{ item.username }}</div>
                <div
                  v-if="item.id !== creator"
                  class="mt-3 text-xs font-medium text-secondary"
                >
                  Pendaftar ke-{{ (currentPage - 1) * 10 + index }}
                </div>
              </div>
              <div class="flex items-center">
                <div
                  v-if="item.id === creator"
                  class="px-4 py-2 mt-3 text-xs border rounded-full sm:mt-0 sm:ml-3"
                >
                  Pembuat Acara
                </div>
              </div>
            </div>
            <div class="flex items-center">
              <button v-if="item.id !== creator" @click="deleteTo = item.id">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                >
                  <path
                    d="M12.2837 7.5L11.9952 15M8.00481 15L7.71635 7.5M16.023 4.82547C16.308 4.86851 16.592 4.91456 16.875 4.96358M16.023 4.82547L15.1332 16.3938C15.058 17.3707 14.2434 18.125 13.2637 18.125H6.73631C5.75655 18.125 4.94198 17.3707 4.86683 16.3938L3.97696 4.82547M16.023 4.82547C15.0677 4.6812 14.1013 4.57071 13.125 4.49527M3.125 4.96358C3.40798 4.91456 3.69198 4.86851 3.97696 4.82547M3.97696 4.82547C4.93231 4.6812 5.89874 4.57071 6.875 4.49527M13.125 4.49527V3.73182C13.125 2.74902 12.3661 1.92853 11.3838 1.8971C10.9244 1.8824 10.463 1.875 10 1.875C9.53696 1.875 9.07565 1.8824 8.61618 1.8971C7.63388 1.92853 6.875 2.74902 6.875 3.73182V4.49527M13.125 4.49527C12.0938 4.41558 11.0516 4.375 10 4.375C8.94836 4.375 7.9062 4.41558 6.875 4.49527"
                    stroke="#FC0D24"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div>
          <pagination
            v-model="currentPage"
            :per-page="perPage"
            :total="totalParticipant || 0"
            class="p-6 border-t"
          />
        </div>
      </div>
      <div v-else class="px-6 pb-6">
        <div class="mx-auto text-center">
          <img
            :src="require(`@/assets/images/86-empty-comment.svg`)"
            alt="Belum Ada Komentar"
            class="w-[256px] inline-block"
          />
          <p class="text-lg font-semibold text-grayscale-900">
            Tidak Ada Partisipan
          </p>
          <p class="text-sm text-grayscale-500">
            Acara ini tidak memiliki partisipan.
          </p>
        </div>
      </div>
    </div>
    <modal-template
      @close="deleteTo = null"
      :show="deleteTo"
      :show-icon="true"
      icon-type="warning"
    >
      <div class="px-5 pb-5 text-left sm:last:p-6">
        <div class="text-lg font-semibold">Batalkan Kehadiran</div>
        <div class="text-sm text-grayscale-500">
          Apakah Anda yakin ingin membatalkan kehadiran
          <span class="font-medium"
            >@{{ getParticipant(deleteTo)?.username }}</span
          >?
        </div>
      </div>
      <div class="flex justify-end p-5 sm:p-6">
        <button-general
          :title="'Tidak'"
          :size="'s'"
          :width="'full'"
          :color="'outline'"
          class="mr-[6px]"
          @click="deleteTo = null"
        />
        <button-general
          :title="'Ya'"
          :size="'s'"
          :width="'full'"
          :color="'secondary'"
          @click="deleteParticipant(deleteTo)"
        />
      </div>
    </modal-template>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import { ref, watch } from "vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import ModalTemplate from "@/components/modalTemplate.vue";
import store from "@/store";

export default {
  name: "ListParticipant",
  components: {
    Pagination,
    ModalTemplate,
    ButtonGeneral,
  },
  props: {
    creator: {
      type: Number,
    },
    totalParticipant: {
      type: Number,
    },
    list: {
      type: Array,
      default: () => [],
    },
    eventId: {
      type: Number,
    },
  },
  emits: ["search"],
  setup(props, { emit }) {
    const search = ref("");
    const currentPage = ref(1);
    const perPage = ref(9);
    const deleteTo = ref(null);

    watch(currentPage, () => {
      emit("search", {
        search: search.value,
        offset: currentPage.value,
      });
    });

    const deleteParticipant = async (id) => {
      await store.dispatch("cancelParticipant", {
        eventId: props.eventId,
        userId: id,
      });
      emit("search", {
        search: search.value,
        offset: currentPage.value,
      });

      deleteTo.value = null;
    };

    const getParticipant = (id) => {
      return props.list.find((item) => item.id == id);
    };

    return {
      search,
      currentPage,
      perPage,
      deleteTo,
      deleteParticipant,
      getParticipant,
    };
  },
};
</script>
