<template>
  <div
    v-if="pagesCount > 1"
    class="flex items-center justify-between text-sm font-medium text-grayscale-600 sm:text-base"
  >
    <button
      class="inline-flex items-center py-2 mr-4"
      :class="[
        currentPage === 1
          ? 'text-grayscale-400 cursor-default'
          : 'text-grayscale-700',
      ]"
      @click="changePage(currentPage - 1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5 mr-2"
      >
        <path
          fill-rule="evenodd"
          d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z"
          clip-rule="evenodd"
        />
      </svg>
      <span class="hidden sm:block"> Kembali </span>
    </button>
    <div class="flex items-center overflow-auto font-medium text-grayscale-600">
      <template v-for="(page, index) in visiblePages">
        <template v-if="typeof page === 'number'">
          <button
            :key="index"
            class="px-3 py-2"
            :class="[page === currentPage ? 'text-secondary' : '']"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
        </template>
        <template v-else>
          <span :key="index" class="inline-block px-3 py-2 text-center">
            {{ page }}
          </span>
        </template>
      </template>
    </div>
    <button
      class="inline-flex items-center py-2 ml-4"
      :class="[
        currentPage === pagesCount
          ? 'text-grayscale-400 cursor-default'
          : 'text-grayscale-700',
      ]"
      @click="changePage(currentPage + 1)"
    >
      <span class="hidden sm:block"> Lanjut </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5 ml-2"
      >
        <path
          fill-rule="evenodd"
          d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";

const props = defineProps({
  perPage: {
    type: Number,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  modelValue: {
    type: Number,
    default: () => 1,
  },
});

const emit = defineEmits(["update:modelValue"]);

const currentPage = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const pagesCount = computed(() => {
  if (!props.perPage) {
    return 0;
  }
  return Math.ceil(props.total / props.perPage);
});

const changePage = (newPage) => {
  if (newPage < 1 || newPage > pagesCount.value) {
    return;
  }
  currentPage.value = newPage;
};

const visiblePages = computed(() => {
  const visiblePages = [];
  let start = 1;
  let end = pagesCount.value;

  if (pagesCount.value <= 7) {
    for (let i = 1; i <= pagesCount.value; i++) {
      visiblePages.push(i);
    }
  } else if (currentPage.value <= 4) {
    for (let i = 1; i <= 5; i++) {
      visiblePages.push(i);
    }
    visiblePages.push("...");
    visiblePages.push(pagesCount.value);
  } else if (currentPage.value >= pagesCount.value - 3) {
    visiblePages.push(1);
    visiblePages.push("...");
    for (let i = pagesCount.value - 4; i <= pagesCount.value; i++) {
      visiblePages.push(i);
    }
  } else {
    visiblePages.push(1);
    visiblePages.push("...");
    start = currentPage.value - 1;
    end = currentPage.value + 1;
    for (let i = start; i <= end; i++) {
      visiblePages.push(i);
    }
    visiblePages.push("...");
    visiblePages.push(pagesCount.value);
  }

  return visiblePages;
});
</script>
