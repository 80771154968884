<template>
  <div class="bg-white">
    <topbar-user />
    <div
      v-if="isLoadingEvents"
      class="bg-grayscale-300 animate-pulse w-full h-full aspect-[4/1]"
    ></div>
    <div v-else class="aspect-[4/1]">
      <img
        v-if="selectedGroup && listEvent[0]?.eventGroup.imageUrl"
        alt="86-banner-event"
        :src="listEvent[0]?.eventGroup.imageUrl"
        class="object-cover w-full h-full"
      />
      <img
        v-else
        alt="86-banner-event"
        :src="require(`@/assets/images/86-banner-event.png`)"
        class="object-cover w-full h-full"
      />
    </div>
    <div v-if="selectedGroup === ''">
      <div class="container px-5 mx-auto mt-14">
        <input-user
          class="rounded-full shadow-md"
          v-model="dummySearch"
          :value="dummySearch"
          :placeholder="'Cari Acara...'"
          @click="onSearch"
        />
      </div>

      <loading-categories-2 v-if="isLoadingCategories" class="mt-14" />
      <event-detail-category
        v-else-if="!search"
        :detail="listCategory"
        :selectedCategory="selectedCategory"
        :showAll="showAll"
        @click="handleCategory"
        @show="handleShow"
      />
    </div>
    <loading-events v-if="isLoadingEvents" />
    <event-user-with-pagination
      v-else
      class="my-[74px] sm:my-[100px]"
      :title="
        search !== ''
          ? `Pencarian Terkait '${search}'`
          : selectedGroup !== ''
          ? `${
              listEvent[0]?.eventGroup.displayName || 'Tidak ada grup kategori'
            }`
          : selectedCategory === ``
          ? `Semua Acara`
          : `${namingCategory?.displayName}`
      "
    />
    <footbar-user />
  </div>
</template>

<script>
import store from "@/store";
import { ref, computed, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import TopbarUser from "@/components/layouts/topbarUser.vue";
import InputUser from "@/components/inputs/inputUser.vue";
import EventDetailCategory from "@/components/events/eventDetailCategory.vue";
import FootbarUser from "@/components/layouts/footbarUser.vue";
import EventUserWithPagination from "@/components/events/eventUserWithPagination.vue";
import LoadingCategories2 from "@/components/loading/loadingCategories2.vue";
import LoadingEvents from "@/components/loading/loadingEvents.vue";

export default {
  name: "Event",
  components: {
    TopbarUser,
    InputUser,
    EventDetailCategory,
    FootbarUser,
    EventUserWithPagination,
    LoadingEvents,
    LoadingCategories2,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const listEvent = ref([]);
    const search = ref(route.query?.q || "");
    const dummySearch = ref("");
    const showCategory = ref(false);
    const listCategory = ref([]);
    const selectedCategory = ref(route.query?.s || "");
    const selectedGroup = ref(route.query?.g || "");
    const showAll = ref(false);
    const namingCategory = computed(() => {
      return listCategory.value.find(
        (detail) => detail.id == selectedCategory.value
      );
    });
    const isLoadingCategories = ref(false);
    const isLoadingEvents = ref(false);

    const getCategories = async () => {
      isLoadingCategories.value = true;

      await store.dispatch("getListCategory");
      isLoadingCategories.value = false;
    };

    const getEvents = async () => {
      isLoadingEvents.value = true;

      store.commit("setLimitEvent", 9);
      store.commit("setCategorySelectEvent", selectedCategory.value || "");
      store.commit("setGroupSelectEvent", selectedGroup.value || "");
      await store.dispatch("getListEventForUser", {
        search: search.value || "",
      });

      isLoadingEvents.value = false;
    };

    const onSearch = () => {
      search.value = dummySearch.value;
      router.push({
        query: { q: search.value, s: selectedCategory.value },
        append: true,
      });
      getEvents();
    };

    const handleCategory = (id) => {
      selectedCategory.value = id;
      selectedGroup.value = "";
      router.push({ query: { q: search.value, s: id }, append: true });
      getEvents();
      showAll.value = false;
    };

    const handleShow = () => {
      showAll.value = !showAll.value;
    };

    onMounted(async () => {
      getCategories();
      getEvents();
    });

    watch(
      () => {
        return store.getters.getListEvent;
      },
      function () {
        listEvent.value = store.getters.getListEvent;
      }
    );

    watch(
      () => {
        return store.getters.getListCategory;
      },
      function () {
        listCategory.value = store.getters.getListCategory;
      }
    );

    watch(
      () => {
        return store.getters.getCategorySelectEvent;
      },
      function () {
        selectedCategory.value = store.getters.getCategorySelectEvent;
      }
    );

    watch(
      () => route.query.g,
      function () {
        selectedGroup.value = store.getters.getCategorySelectEvent;
        getCategories();
        getEvents();
      }
    );

    return {
      listEvent,
      search,
      dummySearch,
      showCategory,
      listCategory,
      selectedCategory,
      selectedGroup,
      showAll,
      namingCategory,
      onSearch,
      handleCategory,
      handleShow,
      isLoadingEvents,
      isLoadingCategories,
    };
  },
};
</script>
