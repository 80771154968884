<template>
  <div class="w-full p-6 bg-white border-gray-200 shadow rounded-xl">
    <div class="items-center justify-between sm:flex">
      <div class="flex items-center flex-1">
        <img
          alt="86-users"
          :src="require(`@/assets/images/86-users.svg`)"
          class="me-4"
        />
        <div class="text-base font-medium leading-6 text-gray-900">
          Ringkasan Pengguna
        </div>
      </div>
      <div class="flex flex-col gap-4 mt-4 sm:mt-0 sm:flex-row">
        <input-select-label
          :title="'Tahun'"
          :listItem="filterYear"
          @click="handleYear"
        />
      </div>
    </div>
    <Bar class="mt-7" :data="chartData" :options="options" />
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import InputSelectLabel from "../inputs/inputSelectLabel.vue";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
} from "chart.js";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

export default {
  components: {
    InputSelectLabel,
    Bar,
  },
  name: "User",
  data() {
    return {
      filterYear: [
        // get previous 5 years
        ...Array.apply(null, Array(5)).map((item, index) => ({
          label: moment().year() - index,
          value: moment().year() - index,
        })),
      ],
      options: {
        plugins: {
          legend: {
            position: "bottom",
            align: "start",
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            ticks: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
      },
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const chartData = computed(() => {
      return {
        labels: [
          "Jan",
          "Febr",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "User Tidak Aktif",
            backgroundColor: "#101828",
            data: props.list.comparedUsers.map((item) => {
              return parseInt(item.inactiveUser);
            }),
            borderRadius: 4,
          },
          {
            label: "User Aktif",
            backgroundColor: "#FDCA31",
            data: props.list.comparedUsers.map((item) => {
              return parseInt(item.activeUser);
            }),
            borderRadius: 4,
          },
        ],
      };
    });

    const handleYear = (data) => {
      store.commit("setYearConclusionDashboard", data);
      store.dispatch("getListConclusionDashboard");
    };

    return { chartData, handleYear };
  },
};
</script>
