<template>
  <div
    class="fixed sm:absolute border border-gray-200 rounded-xl bg-white left-0 top-16 h-screen w-screen sm:h-auto sm:w-[453px] sm:top-[60px] sm:left-[-225px]"
    :class="showNotification ? 'block' : 'hidden'"
  >
    <div class="p-5 text-left border-b border-gray-200">
      <div class="flex items-center">
        <div class="mr-2 text-lg font-bold leading-7 text-gray-900">
          Notifikasi
        </div>
        <badge :title="`${totalActivity} Notifikasi Baru`" :color="'purple'" />
      </div>
      <div class="text-sm font-normal leading-5">
        Melacak penggunaan web dashboard 86 University
      </div>
    </div>
    <div class="overflow-y-auto">
      <detail-notification :data="listActivity" :isTruncate="true" />
    </div>
    <router-link :to="'/admin/log'" @click="close">
      <div
        class="p-5 text-sm font-medium leading-5 border-t border-gray-200 cursor-pointer hover:bg-gray-200"
      >
        Lihat Semua Aktivitas
      </div>
    </router-link>
  </div>
</template>

<script>
import store from "@/store";
import detailNotification from "./detailNotification.vue";
import Badge from "@/components/badge.vue";
import { onBeforeMount, onUnmounted, ref } from "vue";
import axios from "axios";

export default {
  name: "Notification",
  components: { detailNotification, Badge },
  computed: {
    showNotification() {
      return store.getters.getShowNotification;
    },
  },
  setup() {
    const totalActivity = ref(0);

    const listActivity = ref([]);

    const close = () => {
      store.commit("setShowNotification", !store.getters.getShowNotification);
    };

    onBeforeMount(() => {
      getActivities();
    });

    onUnmounted(() => {
      store.commit("setShowNotification", false);
    });

    const getActivities = async () => {
      const params = {
        offset: 1,
        limit: 5,
      };
      const url =
        process.env.VUE_APP_API_URL +
        `/api/v1/activity-log?` +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        totalActivity.value = response.data.result.total;
        listActivity.value = response.data.result.datas;
        return response;
      } catch (error) {
        return error.response;
      }
    };

    return { totalActivity, listActivity, close };
  },
};
</script>
