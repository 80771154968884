<template>
  <div
    class="flex items-center px-5 py-4 border rounded-lg cursor-pointer"
    :class="active ? 'bg-surfaceSecondary' : 'bg-white'"
    @click="$emit('click')"
  >
    <div class="flex-shrink-0 p-3 mr-5 rounded-full bg-primary-50">
      <img
        alt="86-sample-icon-category-event"
        :src="detail?.iconUrl"
        class="left-0 w-6 h-6 sm:w-6 sm:h-6"
      />
    </div>
    <div class="text-xs font-semibold sm:text-base">
      {{ detail.displayName }}
    </div>
  </div>
</template>

<script>
import formats from "@/modules/formats.js";

export default {
  name: "CardEventCategoryUser",
  props: {
    detail: {
      type: Array,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  setup() {
    const { capitalizeFirstLetter } = formats();

    return { capitalizeFirstLetter };
  },
};
</script>
