<template>
  <div>
    <template
      v-for="(notifications, month) in groupedNotifications"
      :key="month"
    >
      <p class="px-6 py-4 text-sm font-medium text-left">{{ month }}</p>
      <div
        v-for="(item, index) in notifications"
        :key="index"
        class="text-left cursor-pointer"
      >
        <div class="block w-full px-6 py-4 hover:bg-gray-200">
          <div class="flex align-top grow">
            <img
              alt="86-notification-folder"
              :src="require(`@/assets/images/86-notification-folder.svg`)"
              class="mr-3"
              v-if="item.module === 'Acara'"
            />
            <img
              alt="86-notification-user"
              :src="require(`@/assets/images/86-notification-user.svg`)"
              class="mr-3"
              v-else
            />
            <div class="min-w-0 grow">
              <div
                class="text-sm font-semibold leading-5"
                :class="{ truncate: isTruncate }"
              >
                {{ item.activityInfo }}
              </div>
              <div class="flex justify-between mt-1 grow">
                <div class="text-[10px] font-normal leading-[14px] flex-1">
                  Aksi oleh <b>{{ item.userName }}</b>
                </div>
                <div class="text-[10px] font-normal leading-[14px]">
                  {{ item.actionDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Detail Notification",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isTruncate: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    groupedNotifications() {
      return this.data.reduce((groups, item) => {
        const yearMonth = moment(item.time).format("MMMM YYYY");

        if (!groups[yearMonth]) {
          groups[yearMonth] = [];
        }

        groups[yearMonth].push(item);
        return groups;
      }, {});
    },
  },
};
</script>
