<template>
  <div class="bg-white">
    <topbar-user />
    <div class="container py-[72px] sm:py-[100px] px-5 mx-auto text-justify">
      <div class="text-base sm:text-2xl">Tentang 86 University</div>
      <div class="text-xl sm:text-4xl sm:leading-[48px] mt-4 sm:mt-8">
        Sebagai komunitas yang berbasis di Indonesia, 86 University berkomitmen
        untuk mendukung pertumbuhan dan aktivitas Angkatan'86. Kami fokus pada
        peningkatan keterampilan anggota komunitas, terutama bagi mereka yang
        telah memasuki usia pensiun. Selain itu, kami berupaya mencapai
        pengakuan global dan memberikan peluang kewirausahaan melalui kolaborasi
        yang erat.
      </div>
    </div>
    <img
      alt="86-about-us"
      :src="require(`@/assets/images/86-about-us.svg`)"
      class="object-cover w-full"
    />
    <div
      class="container pt-10 pb-20 sm:pt-[72px] sm:pb-36 px-5 mx-auto text-justify"
    >
      <div class="pb-20 sm:mb-36">
        <div class="grid items-center grid-cols-1 sm:grid-cols-3">
          <div class="col">
            <div class="pb-2 text-4xl font-semibold sm:text-5xl sm:border-b">
              20.000+
            </div>
            <div class="pt-2 text-base sm:text-xl">
              Total anggota 86 University saat ini
            </div>
          </div>
          <div class="mt-6 col sm:mt-0">
            <div class="pb-2 text-4xl font-semibold sm:text-5xl sm:border-b">
              200+
            </div>
            <div class="pt-2 text-base sm:text-xl">
              Total acara yang telah diselenggarakan
            </div>
          </div>
          <div class="mt-6 col sm:mt-0">
            <div class="pb-2 text-4xl font-semibold sm:text-5xl sm:border-b">
              {{ age }} tahun
            </div>
            <div class="pt-2 text-base sm:text-xl">Total tahun aktif</div>
          </div>
        </div>
      </div>
      <div
        class="grid items-start justify-between grid-cols-1 sm:grid-cols-2 mb-14"
      >
        <div class="text-2xl font-semibold col sm:text-4xl">
          Sejarah 86 University
        </div>
        <div class="mt-4 text-base col sm:text-lg sm:mt-0">
          Gagasan yg dilakukan oleh komunitas Angkatan 86 (sebuah komunitas yang
          berisikan eks siswa-siswi sekolah lanjutan tingkat atas (SLTA)
          se-Indonesia lulusan 1986) di penghujung 2022, tepatnya 12 November
          2022 bertempat di Museum Perumusan Naskah Proklamasi (Munasprok), Jl.
          Imam Bonjol No.1, Menteng, Jakarta Pusat. <br /><br />Sebagai momen
          keberlanjutan program kegiatan yang berorientasi pada dikembangkannya
          berbagai unit usaha (Rumah 86, Sapi 86, Melon Hidroponik, dan masih
          banyak lagi) bagi para anggota yang tergabung dalam Angkatan 86.
          <br /><br />Masih banyak potensi yang bisa dikembangkan dan bermanfaat
          untuk kedepannya di saat usia (di angkatan 86) yang tidak lama lagi
          akan memasuki usia pensiun.
        </div>
      </div>
      <div class="grid items-center grid-cols-1 gap-8 sm:grid-cols-2">
        <img
          alt="86-about-us"
          :src="require(`@/assets/images/86-about-us-1.svg`)"
          class="w-full h-[160px] sm:h-auto object-cover rounded-xl"
        />
        <img
          alt="86-about-us"
          :src="require(`@/assets/images/86-about-us-2.svg`)"
          class="w-full h-[160px] sm:h-auto object-cover rounded-xl"
        />
      </div>
    </div>
    <div
      class="sm:container sm:mx-auto flex flex-col items-center mb-[72px] sm:mb-[100px] py-10 sm:py-[88px] px-5 sm:rounded-[20px] bg-grayscale-50"
      v-if="token === null"
    >
      <div class="text-2xl font-semibold sm:text-4xl">
        Bergabung Bersama Kami
      </div>
      <div class="mt-4 text-base sm:text-lg">
        Bergabung bersama keluarga besar 86 University bergerak di kegiatan
        nasional dan internasional.
      </div>
      <div class="flex items-center mt-12">
        <router-link :to="'/login'" class="mr-4">
          <button-general
            :title="'Masuk Akun'"
            :size="'l'"
            :color="'outline'"
            class="w-[200px]"
          />
        </router-link>
        <router-link :to="'/register'">
          <button-general
            :title="'Daftar Anggota'"
            :size="'l'"
            :color="'primary'"
            class="w-[200px]"
          />
        </router-link>
      </div>
    </div>
    <footbar-user />
  </div>
</template>

<script>
// import { onBeforeMount } from "vue";
// import store from "@/store";
import TopbarUser from "@/components/layouts/topbarUser.vue";
import FootbarUser from "@/components/layouts/footbarUser.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import { computed } from "vue";
import moment from "moment";

export default {
  name: "AboutUs",
  components: {
    TopbarUser,
    FootbarUser,
    ButtonGeneral,
  },
  setup() {
    const token = JSON.parse(window.localStorage.getItem("86"));

    const age = computed(() => {
      return moment().year() - 2011;
    });

    return { token, age };
  },
};
</script>
