<template>
  <div class="bg-white border border-gray-200 shadow rounded-xl">
    <div class="p-5 border border-gray-200">
      <div class="justify-between sm:flex">
        <div>
          <div class="flex items-center">
            <div class="mr-2 text-lg font-bold leading-7">Pengguna</div>
            <badge :title="`${count} Pengguna`" :color="'purple'" />
          </div>
          <div class="mt-1 text-sm font-normal leading-5 text-gray-600">
            Melacak pengguna website 86 University
          </div>
        </div>
        <div class="flex flex-wrap items-center gap-4 mt-4 sm:mt-0">
          <button-icon
            :title="'Import'"
            :size="'xs'"
            :border="'s'"
            :color="'outline'"
            :icon="'86-import.svg'"
            @click="$emit('import')"
          />
          <button-icon
            :title="'Tambah User Baru'"
            :size="'xs'"
            :border="'s'"
            :color="'primary'"
            :icon="'86-add.svg'"
            @click="handleAddData"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-wrap justify-between gap-4 p-5">
      <div class="flex flex-col gap-4 sm:flex-row">
        <input-icon
          :label="'Search'"
          :icon="'86-search.svg'"
          :placeholder="'Search'"
          :value="username"
          @keyup.enter="$emit('searchUsername', $event.target.value)"
        />
        <div>
          <div class="flex h-full border rounded-lg py-[8px] px-[10px]">
            <img
              alt="86-filter"
              :src="require(`@/assets/images/86-filter.svg`)"
              width="20"
            />
            <select
              class="font-medium outline-none appearance-none ml-7"
              @change="handleFilterData($event.target.value)"
            >
              <option
                v-for="(item, index) in filter"
                :key="index"
                :value="item.value"
                :selected="selected === item.value"
                class="p-2"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <button-general
        v-if="selectData.length !== 0"
        :title="'Hapus'"
        :size="'s'"
        :border="'s'"
        :width="'auto'"
        class="inline-block text-sm border text-red-main border-red-main"
        @click="handleShowConfirmationDelete"
      />
    </div>
    <div
      v-if="list.length === 0 && username === ''"
      class="flex flex-col py-28"
    >
      <div class="m-auto text-center">
        <img
          class="m-auto"
          alt="86-no-data"
          :src="require(`@/assets/images/86-no-data.svg`)"
        />
        <div class="text-lg font-semibold leading-7 mt-14 text-grayscale-900">
          Tidak Ada User
        </div>
        <div
          class="mt-[10px] text-sm font-normal leading-[20px] text-grayscale-500"
        >
          Tekan tombol tambah baru atau import untuk menambah user
        </div>
      </div>
    </div>
    <div
      v-else-if="list.length === 0 && username !== ''"
      class="flex flex-col py-28"
    >
      <div class="m-auto text-center">
        <img
          class="m-auto"
          alt="86-not-found"
          :src="require(`@/assets/images/86-not-found.svg`)"
        />
        <div class="text-lg font-semibold leading-7 mt-14 text-grayscale-900">
          User Tidak Ditemukan
        </div>
        <div
          class="mt-[10px] text-sm font-normal leading-[20px] text-grayscale-500"
        >
          Kami tidak bisa menemukan user yang anda cari
        </div>
      </div>
    </div>
    <div v-else class="w-full overflow-auto">
      <table class="table w-full overflow-auto">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-4 py-3 font-medium">
              <input type="checkbox" @change="selectAll" />
            </th>
            <th
              v-for="(item, index) in tableHead"
              :key="index"
              class="text-xs font-medium leading-[18px] text-gray-600 py-3 px-4 whitespace-nowrap"
            >
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in list"
            :key="index"
            class="text-sm font-normal leading-5 text-grayscale-500 border-y"
          >
            <td class="px-4 py-3">
              <input
                type="checkbox"
                :checked="!!selectData.find((x) => x === item.id)"
                @change="selectingData(item.id)"
              />
            </td>
            <td
              class="flex items-center py-3 px-4 text-gray-900 text-sm font-medium w-[250px]"
            >
              <img
                :src="item.picture"
                width="40"
                class="h-10 mr-3 rounded-full"
              />
              {{ item.name }}
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ item.username }}
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ item.email }}
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ item.createdAt }}
            </td>
            <td class="px-4 py-3 text-sm text-gray-500">
              {{ item.phoneNumber }}
            </td>
            <td class="px-4 py-3 text-gray-500">
              <router-link
                :to="`/admin/user/${item.email}`"
                class="text-sm font-medium"
              >
                Detail
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="list.length !== 0" class="flex items-center justify-between p-5">
      <div class="hidden sm:block">Halaman {{ page }} dari {{ totalPage }}</div>
      <div class="flex flex-1 sm:flex-none">
        <button-general
          :title="'Sebelumnya'"
          :size="'s'"
          :border="'s'"
          :color="'outline'"
          class="text-sm mr-[6px]"
          :disabled="isFirstPage"
          @click="$emit('before')"
        />
        <button-general
          :title="'Selanjutnya'"
          :size="'s'"
          :border="'s'"
          :color="'outline'"
          class="text-sm"
          :disabled="isLastPage"
          @click="$emit('next')"
        />
      </div>
    </div>
    <confirmation
      :title="'Hapus pengguna'"
      :message="'Apakah Anda yakin menghapus pengguna?'"
      :show="showConfirmationDelete"
      :status="'danger'"
      @close="handleShowConfirmationDelete"
      @submit="deleteUser"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import InputIcon from "@/components/inputs/inputIcon.vue";
import ButtonIcon from "@/components/buttons/buttonIcon.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import Confirmation from "@/components/confirmation.vue";
import Badge from "@/components/badge.vue";
import store from "@/store";

export default {
  components: { InputIcon, ButtonIcon, ButtonGeneral, Confirmation, Badge },
  name: "TableUser",
  data() {
    return {
      search: "",
    };
  },
  props: {
    count: {
      type: Number,
      required: true,
      default: 0,
    },
    username: {
      type: String,
    },
    selected: {
      type: String,
    },
    page: {
      type: Number,
      required: true,
      default: 0,
    },
    totalPage: {
      type: Number,
      required: true,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    "searchByUsername",
    "searchFilter",
    "import",
    "next",
    "before",
    "deleteUser",
  ],
  setup(props, { emit }) {
    const route = useRouter();
    const filter = ref([
      {
        label: "Tampilkan Semua",
        sortBy: "created_date",
        sortValue: "",
        value: "",
      },
      {
        label: "Urutkan dari A-Z",
        sortBy: "AZ",
        sortValue: "ASC",
        value: "nameA",
      },
      {
        label: "Urutkan dari Z-A",
        sortBy: "ZA",
        sortValue: "DESC",
        value: "nameD",
      },
      {
        label: "Urutkan dari tanggal dibuat",
        sortBy: "created_date",
        sortValue: "ASC",
        value: "createdA",
      },
    ]);
    const tableHead = ref([
      {
        label: "Nama",
      },
      {
        label: "Username",
      },
      {
        label: "Email",
      },
      {
        label: "Tanggal Daftar",
      },
      {
        label: "No Telepon",
      },
      {
        label: "",
      },
    ]);
    const selectData = ref([]);

    const handleAddData = () => {
      route.push({ path: "user/add", append: true });
    };

    const selectAll = (event) => {
      if (!event.target.checked) {
        selectData.value = [];
      } else {
        if (selectData.value.length === 10) {
          selectData.value = [];
        } else {
          props.list.map((detail) => selectData.value.push(detail.id));
        }
      }
    };

    const selectingData = (select) => {
      let find = false;
      let dummy = null;
      !!selectData.value.find((detail) => {
        if (detail === select) {
          find = true;
          dummy = selectData.value.indexOf(select);
        }
      });
      if (find) {
        selectData.value.splice(dummy, 1);
      } else {
        selectData.value.push(select);
      }
    };

    const handleFilterData = (data) => {
      let detail = filter.value.find((item) => {
        return item.value === data;
      });
      emit("searchFilter", detail.value, detail.sortBy, detail.sortValue);
    };

    const showConfirmationDelete = ref(false);

    const handleShowConfirmationDelete = () => {
      showConfirmationDelete.value = !showConfirmationDelete.value;
    };

    const deleteUser = () => {
      emit("deleteUser", selectData.value);
      selectData.value = [];
      handleShowConfirmationDelete();
    };

    const totalPage = computed(() => store.getters.getTotalPageUser);
    const offsetUser = computed(() => store.getters.getOffsetUser);

    const isFirstPage = computed(() => {
      return offsetUser.value === 1;
    });

    const isLastPage = computed(() => {
      return totalPage.value === offsetUser.value;
    });

    return {
      filter,
      tableHead,
      selectData,
      handleAddData,
      selectAll,
      selectingData,
      handleFilterData,
      showConfirmationDelete,
      handleShowConfirmationDelete,
      deleteUser,
      isFirstPage,
      isLastPage,
    };
  },
};
</script>
