<template>
  <div
    class="inline-block text-xs font-medium leading-[18px] rounded-2xl px-2 py-[2px]"
    :class="
      color === 'red'
        ? 'bg-[#FFE3E0] text-red-main'
        : color === 'purple'
        ? 'bg-purple text-[#6941C6]'
        : color === 'orange'
        ? 'bg-[#FFEBE0] text-[#B54708]'
        : color === 'green'
        ? 'bg-[#E0FFF3] text-green'
        : color === 'blue'
        ? 'bg-[#E0ECFF] text-[#175CD3]'
        : color === 'pink'
        ? 'bg-[#FFE0F1] text-[#C11574]'
        : color === 'yellow'
        ? ' bg-surfaceSecondary text-primary-50'
        : 'bg-gray-100 text-grayscale-900'
    "
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    title: {
      type: String,
    },
    color: {
      type: String,
    },
  },
};
</script>