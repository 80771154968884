<template>
  <div
    class="border flex flex-col border-gray-200 rounded-xl bg-white left-0 top-16 h-auto sm:max-h-[580px] overflow-y-auto"
  >
    <div
      class="sticky top-0 p-5 text-left bg-white"
      style="box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.08)"
    >
      <div class="flex items-center">
        <div class="mr-2 text-lg font-bold leading-7 text-gray-900">
          Aktivitas
        </div>
        <badge :title="`${total} Notifikasi Baru`" :color="'purple'" />
      </div>
      <div class="text-sm font-normal leading-5">
        Melacak penggunaan web dashboard 86 University
      </div>
    </div>
    <div v-if="data !== 0">
      <detail-notification :data="data" />
      <router-link :to="'/admin/log'">
        <div
          class="sticky bottom-0 p-5 mt-auto text-sm font-medium leading-5 text-center bg-white border-t border-gray-200 cursor-pointer hover:bg-gray-200"
        >
          Lihat Semua Aktivitas
        </div>
      </router-link>
    </div>
    <div
      class="flex flex-col items-center justify-center h-full py-8 mx-auto"
      v-else
    >
      <img
        alt="86-no-activities"
        :src="require(`@/assets/images/86-no-activities.svg`)"
        width="200"
      />
      <div class="mt-20 text-lg font-semibold text-grayscale-900">
        Tidak Ada Aktivitas
      </div>
      <div class="text-grayscale-500">
        Anda tidak memiliki Aktivitas terbaru
      </div>
    </div>
  </div>
</template>

<script>
import detailNotification from "./notifications/detailNotification.vue";
import Badge from "@/components/badge.vue";

export default {
  name: "Activity",
  components: { detailNotification, Badge },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
    },
  },
};
</script>
