<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import Sidebar from "@/components/layouts/sidebar.vue";
import Topbar from "@/components/layouts/topbar.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import ShortProfileUser from "@/components/profiles/shortProfileUser.vue";
import AdminEventComments from "@/components/events/adminEventComments.vue";
import InputSelect from "@/components/inputs/inputSelect.vue";
import InputSelectTitle from "@/components/inputs/inputSelectTitle.vue";
import InputLabel from "@/components/inputs/inputLabel.vue";
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import InputCheckbox from "@/components/inputs/inputCheckbox.vue";
import moment from "moment";
import ModalTemplate from "@/components/modalTemplate.vue";
import LoadingEventAdmin from "@/components/loading/loadingEventAdmin.vue";
import ListParticipantAdmin from "@/components/events/listParticipantAdmin.vue";

const router = useRouter();
const route = useRoute();
const eventId = route.params.id;

const detail = computed(() => {
  return store.getters.getDetailEvent;
});

const currentTab = ref("overview");
const listMenu = computed(() => [
  {
    title: "Beranda",
    link: "/",
  },
  {
    title: "List Acara",
    link: "/admin/event",
  },
  {
    title: detail.value?.name || "-",
    link: "/admin/event/" + route.params.id,
  },
]);
const listStatus = ref([
  {
    value: "DRAFT",
    label: "Draft",
  },
  {
    value: "PUBLISH",
    label: "Publish",
  },
  {
    value: "EXPIRED",
    label: "Expired",
  },
]);
const typeEvent = ref([
  {
    value: "OFFLINE",
    label: "Lokasi",
  },
  {
    value: "ONLINE",
    label: "Online",
  },
]);
const usingEndDate = ref(false);
const loading = ref(false);

onMounted(async () => {
  loading.value = true;
  store.commit("setActiveMenu", "Acara");
  await store.dispatch("getListGroup");
  await store.dispatch("getListCategory");
  await store.dispatch("getListJob");
  await store.dispatch("getDetailEvent", eventId);
  await store.dispatch("getEventParticipantDetail", {
    id: eventId,
    name: "",
    limit: 10,
  });
  loading.value = false;

  usingEndDate.value = !(detail.value.startDate === detail.value.endDate);
});

const listCategory = computed(() => {
  return store.getters.getListCategory.map((item) => {
    return {
      value: item.id,
      label: item.displayName,
    };
  });
});

const changeTab = (tab) => {
  currentTab.value = tab;
};

const wantToDelete = ref(false);

const deleteEvent = async () => {
  const res = await store.dispatch("deleteEvent", [eventId]);
  wantToDelete.value = false;
  if (res) {
    router.push("/admin/event");
  }
};

const listParticipant = computed(() => {
  return store.getters.getDataParticipant;
});

const totalParticipant = computed(() => {
  return store.getters.getTotalParticipant;
});

const findUser = (data) => {
  store.dispatch("getEventParticipantDetail", {
    id: route.params.id,
    name: data.search,
    limit: 10,
    offset: data.offset,
  });
};
</script>

<template>
  <div>
    <div class="flex w-full">
      <sidebar />
      <div class="w-full">
        <topbar />
        <div
          class="bg-grayscale-50 py-4 px-5 text-left mt-[72px] sm:mt-0"
          style="min-height: calc(100vh - 72px)"
          v-if="detail"
        >
          <breadcrumb :title="'Acara'" :list="listMenu" />
          <loading-event-admin v-if="loading" />
          <div v-else class="sm:flex sm:items-start">
            <div class="w-full sm:w-2/6 mr-[18px]">
              <div class="w-full bg-white rounded-xl">
                <div
                  class="flex items-center justify-between px-4 py-5 border-b border-b-grayscale-100"
                >
                  <div
                    class="text-lg font-semibold leading-7 text-grayscale-900"
                  >
                    Status
                  </div>
                  <div
                    class="rounded-full w-[10px] h-[10px]"
                    :class="{
                      'bg-green': detail.status === 'PUBLISH',
                      'bg-primary-50': detail.status === 'DRAFT',
                      'bg-secondary': detail.status === 'EXPIRED',
                    }"
                  />
                </div>
                <div class="px-4 py-5">
                  <input-select
                    class="mt-[6px]"
                    :listItem="listStatus"
                    :modelValue="detail.status"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
            <div class="flex-1 w-full mt-6 sm:mt-0">
              <div>
                <button
                  class="py-3 mr-6 text-lg font-medium"
                  :class="
                    currentTab === 'overview'
                      ? 'border-b-2 text-primary-50 border-primary-50'
                      : 'text-grayscale-500'
                  "
                  @click="changeTab('overview')"
                >
                  Overview
                </button>
                <button
                  class="py-3 mr-6 text-lg font-medium"
                  :class="
                    currentTab === 'participants'
                      ? 'border-b-2 text-primary-50 border-primary-50'
                      : 'text-grayscale-500'
                  "
                  @click="changeTab('participants')"
                >
                  Partisipan
                </button>
                <button
                  class="py-3 text-lg font-medium"
                  :class="
                    currentTab === 'comments'
                      ? 'border-b-2 text-primary-50 border-primary-50'
                      : 'text-grayscale-500'
                  "
                  @click="changeTab('comments')"
                >
                  Komentar
                </button>
              </div>
              <div v-if="currentTab === 'overview'" class="w-full mt-6">
                <div class="flex justify-between">
                  <div class="font-semibold leading-7 text-grayscale-900">
                    Overview
                  </div>
                  <div class="inline-flex">
                    <button-general
                      :title="'Hapus'"
                      :size="'xs'"
                      :border="'s'"
                      @click="wantToDelete = true"
                      class="mr-4"
                    />
                    <router-link :to="`/admin/event/${eventId}/edit`">
                      <button-general
                        :title="'Edit'"
                        :size="'xs'"
                        :border="'s'"
                        :color="'outline'"
                      />
                    </router-link>
                  </div>
                </div>
                <div class="mt-6 bg-white rounded-xl">
                  <div
                    class="flex items-center justify-between px-4 py-5 border-b border-b-grayscale-100"
                  >
                    <div
                      class="text-lg font-semibold leading-7 text-grayscale-900"
                    >
                      Informasi Group
                    </div>
                  </div>
                  <div class="px-4 py-5">
                    <input-label
                      :title="'Nama Group'"
                      :value="detail.eventGroup.name"
                      v-model="detail.eventGroup.name"
                      :disabled="true"
                    />
                    <div class="mt-6">
                      <label>Thumbnail Group Acara</label>
                      <div class="aspect-[4/1]">
                        <img
                          :src="detail.eventGroup.imageUrl"
                          class="mt-[6px] h-full w-full rounded-lg object-cover"
                        />
                      </div>
                      <div class="mt-2 text-xs text-grayscale-500">
                        * Thumbail ini akan ditampilkan pada halaman ketika user
                        lihat daftar acara berdasarkan group/parent acara.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-6 bg-white rounded-xl">
                  <div
                    class="flex items-center justify-between px-4 py-5 border-b border-b-grayscale-100"
                  >
                    <div
                      class="text-lg font-semibold leading-7 text-grayscale-900"
                    >
                      Informasi Acara
                    </div>
                  </div>
                  <div class="px-4 py-5">
                    <input-label
                      :title="'Nama Acara'"
                      :value="detail.name"
                      v-model="detail.name"
                      :disabled="true"
                    />
                    <div class="mt-6">
                      <label>Gambar Acara</label>
                      <div class="aspect-[16/9]">
                        <img
                          :src="detail.imageUrl"
                          class="mt-[6px] h-full w-full rounded-lg object-cover"
                        />
                      </div>
                      <div class="mt-2 text-xs text-grayscale-500">
                        * Gambar acara akan ditampilkan sebagai thumbail dari
                        setiap acara
                      </div>
                    </div>
                    <div class="mt-6">
                      <label>Jenis Acara</label>
                      <input-checkbox
                        :name="'TypeEvent'"
                        :select="detail.eventType"
                        v-model="detail.eventType"
                        :listItem="typeEvent"
                        class="gap-3 grid-cols-2 mt-[6px]"
                        @onChangeSelect="onChangeType"
                        :disabled="true"
                      />
                      <input-label
                        v-if="detail.eventType"
                        class="mt-6"
                        :value="detail.location"
                        v-model="detail.location"
                        :placeholder="
                          detail.eventType === 'OFFLINE'
                            ? 'Copy alamat dari google maps'
                            : detail.eventType === 'ONLINE'
                            ? 'Masukan link google meet'
                            : 'Silakan pilih jenis acara'
                        "
                        :disabled="true"
                      />
                    </div>
                    <input-select-title
                      class="mt-6"
                      :title="'Kategori Acara'"
                      :listItem="listCategory"
                      v-model="detail.eventCategory.id"
                      :disabled="true"
                    />
                    <input-label
                      class="mt-6"
                      :title="'Kuota Acara'"
                      :value="detail.quota"
                      v-model="detail.quota"
                      :disabled="true"
                    />
                    <p class="mt-2 text-xs text-grayscale-500">
                      * Kosongkan bila tidak ada batas kuota
                    </p>
                    <div class="grid grid-cols-1 gap-4 mt-6 sm:grid-cols-2">
                      <div class="col">
                        <label>Tanggal Mulai</label>
                        <div class="mt-[6px] w-full">
                          <date-picker
                            v-model:value="detail.startDate"
                            placeholder="Masukan Tanggal"
                            value-type="format"
                            format="DD/MM/YYYY"
                            :disabled="true"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <label>Waktu Mulai</label>
                        <div class="mt-[6px] w-full">
                          <date-picker
                            :value="
                              moment(
                                detail.startDate,
                                'DD/MM/YYYY HH:mm'
                              ).format('HH:mm')
                            "
                            placeholder="Masukan jam"
                            type="time"
                            value-type="format"
                            format="HH:mm"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-1 gap-4 mt-6 sm:grid-cols-2"
                      v-if="usingEndDate"
                    >
                      <div class="col">
                        <label>Tanggal Akhir</label>
                        <div class="mt-[6px] w-full">
                          <date-picker
                            v-model:value="detail.endDate"
                            placeholder="Masukan Tanggal"
                            value-type="format"
                            format="DD/MM/YYYY"
                            :disabled="true"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <label>Waktu Akhir</label>
                        <div class="mt-[6px] w-full">
                          <date-picker
                            :value="
                              moment(detail.endDate, 'DD/MM/YYYY HH:mm').format(
                                'HH:mm'
                              )
                            "
                            placeholder="Masukan jam"
                            type="time"
                            value-type="format"
                            format="HH:mm"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mt-6">
                      <label>Deskripsi Acara</label>
                      <div>
                        <textarea
                          v-model="detail.description"
                          placeholder="Masukan diskripsi Acara"
                          rows="4"
                          class="w-full px-3 py-2 border rounded-lg border-grayscale-200"
                          :disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <short-profile-user :detail="detail.user" class="mt-6" />
              </div>
              <div
                v-else-if="currentTab === 'participants'"
                class="w-full mt-6"
              >
                <list-participant-admin
                  :creator="detail.user.id"
                  :totalParticipant="totalParticipant"
                  :list="listParticipant"
                  :eventId="eventId"
                  @search="findUser"
                />
              </div>
              <div v-else-if="currentTab === 'comments'" class="mt-6">
                <admin-event-comments :event-id="eventId" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal-template
    @close="wantToDelete = false"
    :show="wantToDelete"
    :show-icon="true"
    icon-type="warning"
  >
    <div class="px-5 pb-5 text-left sm:last:p-6">
      <div class="text-lg font-semibold">Hapus acara</div>
      <div class="text-sm text-grayscale-500">
        Apakah Anda yakin ingin menghapus acara?
      </div>
    </div>
    <div class="flex justify-end p-5 sm:p-6">
      <button-general
        :title="'Tidak'"
        :size="'s'"
        :width="'full'"
        :color="'outline'"
        class="mr-[6px]"
        @click="wantToDelete = false"
      />
      <button-general
        :title="'Ya, hapus sekarang'"
        :size="'s'"
        :width="'full'"
        :color="'secondary'"
        @click="deleteEvent"
      />
    </div>
  </modal-template>
</template>
