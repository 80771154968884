<script setup>
import { ref, defineProps, defineEmits } from "vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import ImageCropper from "@/components/imageCropper.vue";

const props = defineProps({
  title: {
    type: String,
  },
  img: {
    type: String,
    default: "",
  },
  aspectRatio: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(["updateImage"]);

const fileInput = ref(null);
const imgDataUrl = ref(props.img);
const croppingImage = ref(null);

const removeImage = () => {
  imgDataUrl.value = "";
  fileInput.value.value = "";
  emit("updateImage", null);
};

const changeImage = () => {
  fileInput.value.click();
};

const onUpload = (event) => {
  let file = event.target.files[0];

  if (file) {
    croppingImage.value = URL.createObjectURL(file);
  }
};

const onCrop = (image) => {
  imgDataUrl.value = image;
  let data = new FormData();
  data.append("file", dataURLtoFile(image));
  emit("updateImage", data);
  croppingImage.value = false;
};

const cancelCropping = () => {
  croppingImage.value = null;
  fileInput.value.value = "";
};

function dataURLtoFile(dataurl, filename = "uploaded-image.jpg") {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
</script>
<template>
  <div>
    <div class="bg-white">
      <div class="h-full rounded-xl">
        <div v-show="!imgDataUrl" :style="{ aspectRatio }">
          <div
            class="flex flex-col items-center justify-center w-full h-full border rounded-xl"
          >
            <div>
              <label class="cursor-pointer text-primary-50">
                Click to upload
                <input
                  ref="fileInput"
                  type="file"
                  class="hidden"
                  accept="image/png, image/jpeg"
                  @change="onUpload"
                />
              </label>
              or drag and drop
            </div>
            <div>PNG, JPEG, JPG (max. 5mb)</div>
          </div>
        </div>
        <div v-show="imgDataUrl" :style="{ aspectRatio }">
          <img
            :src="imgDataUrl"
            class="object-cover w-full h-full rounded-lg"
          />
        </div>
        <div class="flex mt-4" v-if="imgDataUrl">
          <button-general
            :title="'Ganti Foto'"
            :color="'outline'"
            :size="'xs'"
            :border="'s'"
            @click="changeImage"
          />
          <button-general
            :title="'Hapus'"
            :size="'xs'"
            @click="removeImage"
            class="text-primary-50"
          />
        </div>
      </div>
    </div>
  </div>
  <ImageCropper
    v-if="croppingImage"
    :croppingImage="croppingImage"
    :aspectRatio="aspectRatio"
    @onCrop="onCrop"
    @cancel="cancelCropping"
  />
</template>
