<template>
  <div
    class="flex items-center text-sm font-medium leading-5 border border-grayscale-300 rounded-lg py-2 pl-4 pr-2"
  >
    <div>{{ title }} :</div>
    <select
      class="appearance-none outline-none px-2"
      :v-model="select"
      @change="$emit('click', $event.target.value)"
    >
      <option
        v-for="(item, index) in listItem"
        :key="index"
        :value="item.value"
        :selected="select === item.value"
      >
        {{ item.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "InputSelectLabel",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    select: {
      type: String,
    },
    listItem: {
      type: Object,
      required: true,
      default() {},
    },
  },
  emits: ["click"],
};
</script>