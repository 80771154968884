import axios from "axios";

export default {
  state: {
    comments: [],
    totalComments: 0,
    totalPage: 0,
    offset: 0,
    limit: 10000,
  },
  getters: {
    getComments: (state) => {
      return state.comments;
    },
    getTotalComments: (state) => {
      return state.totalComments;
    },
    getTotalPageComments: (state) => {
      return state.totalPage;
    },
    getOffsetComments: (state) => {
      return state.offset;
    },
  },
  actions: {
    async getComments({ state, commit }, eventId) {
      const url =
        process.env.VUE_APP_API_URL +
        `/api/v2/comment?offset=${state.offset}&limit=${state.limit}&eventId=${eventId}`;
      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${
              JSON.parse(window.localStorage.getItem("86"))?.token
            }`,
          },
        });
        commit("setTotalComments", response.data.result.total);
        commit("setTotalPageComments", response.data.result.total);
        commit("setComments", response.data.result.datas);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async createComment(_, { eventId, content, parentCommentId = null }) {
      const url = process.env.VUE_APP_API_URL + `/api/v2/comment`;
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: {
            eventId: eventId,
            content: content,
            parentCommentId: parentCommentId,
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async updateComment(_, { eventId, id, content }) {
      const url = process.env.VUE_APP_API_URL + `/api/v2/comment`;
      try {
        const response = await axios({
          method: "put",
          url: url,
          data: {
            eventId,
            id,
            content,
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async deleteComment(_, { eventId, id }) {
      const url = process.env.VUE_APP_API_URL + `/api/v2/comment`;
      try {
        const response = await axios({
          method: "put",
          url: url,
          data: {
            eventId,
            id,
            deleted: true,
            content: "Comment ini akan didelete",
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
  },
  mutations: {
    setTotalComments: (state, data) => {
      state.totalComments = data;
    },
    setTotalPageComments: (state, data) => {
      state.totalPage = Math.ceil(data / state.limit);
    },
    setComments: (state, data) => {
      state.comments = data;
    },
    setLimitComments: (state, data) => {
      state.limit = data;
    },
  },
};
