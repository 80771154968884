<template>
  <div class="bg-white border border-gray-200 shadow rounded-xl">
    <div class="p-5 border border-gray-200">
      <div class="justify-between sm:flex">
        <div>
          <div class="flex items-center">
            <div class="mr-2 text-lg font-bold leading-7">Aktivitas</div>

            <badge :title="`${count} Activitas`" :color="'purple'" />
          </div>
          <div class="mt-1 text-sm font-normal leading-5 text-gray-600">
            Melacak pengguna website 86 University
          </div>
        </div>
      </div>
    </div>
    <div class="items-stretch p-5 sm:flex">
      <input-icon
        :label="'Search'"
        :icon="'86-search.svg'"
        :placeholder="'Search'"
        :value="username"
        @keyup.enter="$emit('searchUsername', $event.target.value)"
        class="sm:mr-3"
      />
      <div class="mt-4 sm:mt-0 sm:ml-4">
        <div class="flex h-full border rounded-lg py-[8px] px-[10px]">
          <img
            alt="86-filter"
            :src="require(`@/assets/images/86-filter.svg`)"
            width="20"
          />
          <select
            class="px-2 font-medium outline-none appearance-none ml-7"
            @change="handleFilterData($event.target.value)"
          >
            <option
              v-for="(item, index) in filter"
              :key="index"
              :value="item.value"
              :selected="selected === item.value"
              class="p-2"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="list.length === 0" class="flex flex-col py-28">
      <div class="m-auto text-center">
        <img
          class="m-auto"
          alt="86-no-data"
          :src="require(`@/assets/images/86-no-data.svg`)"
        />
        <div class="text-lg font-semibold leading-7 mt-14 text-grayscale-900">
          Tidak ada Aktivitas
        </div>
        <div
          class="mt-[10px] text-sm font-normal leading-[20px] text-grayscale-500"
        >
          Kami tidak menemukan Aktivitas terbaru
        </div>
      </div>
    </div>
    <div v-else class="overflow-auto">
      <table class="table w-full overflow-auto table-fixed sm:table-auto">
        <thead class="bg-gray-50">
          <tr>
            <th
              v-for="(item, index) in tableHead"
              :key="index"
              class="text-xs font-medium leading-[18px] text-gray-600 py-3 px-4 w-[200px]"
            >
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in list"
            :key="index"
            class="text-sm font-normal leading-5 border text-grayscale-500"
          >
            <td class="py-3 px-4 text-gray-900 w-[250px] align-middle">
              {{ item.userName }}
            </td>
            <td class="px-4 py-3 text-gray-500 align-middle">
              {{ item.module }}
            </td>
            <td class="px-4 py-3 text-gray-500 align-middle">
              {{ item.action }}
            </td>
            <td class="px-4 py-3 text-gray-500 align-middle">
              {{ item.actionDate }}
            </td>
            <td class="px-4 py-3 text-gray-500 align-middle">
              <textarea
                :value="item.oldValue"
                rows="4"
                class="focus:outline-none"
              />
            </td>
            <td class="px-4 py-3 text-gray-500 align-middle">
              <textarea
                :value="item.newValue"
                rows="4"
                class="focus:outline-none"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="list.length !== 0" class="flex items-center justify-between p-5">
      <div class="hidden sm:block">Halaman {{ page }} dari {{ totalPage }}</div>
      <div class="flex flex-1 sm:flex-none">
        <button-general
          :title="'Sebelumnya'"
          :size="'s'"
          :border="'s'"
          :color="'outline'"
          class="text-sm mr-[6px]"
          :disabled="isFirstPage"
          @click="$emit('before')"
        />
        <button-general
          :title="'Selanjutnya'"
          :size="'s'"
          :border="'s'"
          :color="'outline'"
          class="text-sm"
          :disabled="isLastPage"
          @click="$emit('next')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import inputIcon from "@/components/inputs/inputIcon.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import store from "@/store";
import Badge from "@/components/badge.vue";

export default {
  components: { inputIcon, ButtonGeneral, Badge },
  name: "TableActivity",
  data() {
    return {
      search: "",
    };
  },
  props: {
    count: {
      type: Number,
      required: true,
      default: 0,
    },
    username: {
      type: String,
    },
    selected: {
      type: String,
    },
    page: {
      type: Number,
      required: true,
      default: 0,
    },
    totalPage: {
      type: Number,
      required: true,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["searchByUsername", "searchFilter", "next", "before"],
  setup(_, { emit }) {
    const filter = ref([
      {
        label: "Tampilkan Semua",
        sortBy: "",
        sortValue: "",
        value: "",
      },
      {
        label: "Urutkan dari A-Z",
        sortBy: "userName",
        sortValue: "ASC",
        value: "nameA",
      },
      {
        label: "Urutkan dari Z-A",
        sortBy: "userName",
        sortValue: "DESC",
        value: "nameD",
      },
      {
        label: "Urutkan dari tanggal dibuat",
        sortBy: "created_date",
        sortValue: "ASC",
        value: "createdA",
      },
    ]);
    const tableHead = ref([
      {
        label: "Nama Admin",
      },
      {
        label: "Modul",
      },
      {
        label: "Aktivitas",
      },
      {
        label: "Tanggal",
      },
      {
        label: "Old Value",
      },
      {
        label: "New Value",
      },
    ]);

    const totalPage = computed(() => store.getters.getTotalPageActivity);
    const offset = computed(() => store.getters.getOffsetActivity);

    const isFirstPage = computed(() => {
      return offset.value === 1;
    });

    const isLastPage = computed(() => {
      return totalPage.value === offset.value;
    });

    const handleFilterData = (data) => {
      let detail = filter.value.find((item) => {
        return item.value === data;
      });
      emit("searchFilter", detail.value, detail.sortBy, detail.sortValue);
    };

    return { filter, tableHead, isFirstPage, isLastPage, handleFilterData };
  },
};
</script>
