<template>
  <div class="text-left">
    <form
      class="flex border rounded-full py-[10px] sm:py-4 px-4 sm:px-6"
      @submit.prevent="$emit('click')"
    >
      <input
        type="text"
        class="flex-1 mr-3 text-sm font-normal leading-6 sm:text-base focus:outline-none me-4"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <button>
        <img
          :alt="icon"
          :src="require(`@/assets/images/${icon}`)"
          class="w-10 h-10"
        />
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "InputUser",
  data() {
    return {
      focus: false,
    };
  },
  props: {
    value: {
      type: String,
    },
    icon: {
      type: String,
      default: "86-search-user.svg",
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
  },
  emits: ["update:value", "click"],
};
</script>
