<template>
  <div class="bg-white">
    <div
      class="px-4 py-5 text-lg font-semibold leading-7 border-b border-b-grayscale-100"
    >
      Foto Profile
    </div>
    <!-- <div class="animate-pulse">
      <div class="bg-gray-600 h-[221px] w-[332px]"></div>
    </div> -->
    <div class="p-4">
      <div class="aspect-square">
        <img
          alt="86-profile"
          :src="image"
          class="object-cover w-full h-full rounded-lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfilePicture",
  props: {
    image: {
      type: String,
    },
  },
};
</script>
