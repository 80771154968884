<template>
  <div
    class="fixed top-0 left-0 h-screen w-screen bg-black-300 px-5 sm:px-0 z-[101]"
    :class="show ? 'flex items-center justify-center' : 'hidden'"
  >
    <div class="bg-white rounded-xl p-6 w-full sm:w-[400px]">
      <div class="flex justify-between">
        <img
          alt="86-alert"
          :src="require(`@/assets/images/86-alert.svg`)"
          v-if="status === 'alert'"
        />
        <img
          alt="86-danger"
          :src="require(`@/assets/images/86-danger.svg`)"
          v-if="status === 'danger'"
        />
        <img
          alt="86-warning"
          :src="require(`@/assets/images/86-warning.svg`)"
          v-if="status === 'warning'"
        />
        <img
          alt="86-close"
          :src="require(`@/assets/images/86-close.svg`)"
          class="w-3 h-3 mt-2 cursor-pointer me-2"
          @click="$emit('close')"
        />
      </div>
      <div class="mt-4 text-left">
        <p class="text-lg font-semibold leading-7 text-grayscale-900">
          {{ title }}
        </p>
        <p class="text-sm font-normal leading-5 text-gray-600">
          {{ message }}
        </p>
      </div>
      <div class="flex mt-8">
        <button-general
          :title="'Kembali'"
          :size="'s'"
          :border="'s'"
          :color="'outline'"
          class="mr-[6px]"
          @click="$emit('close')"
        />
        <button-general
          :title="
            status === 'alert'
              ? 'Simpan'
              : status === 'danger'
              ? 'Hapus'
              : 'Keluar'
          "
          :size="'s'"
          :border="'s'"
          class="ms-[6px]"
          :color="status === 'alert' ? 'primary' : 'secondary'"
          @click="$emit('submit')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";

export default {
  components: { ButtonGeneral },
  name: "Confirmation",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "warning",
    },
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    message: {
      type: String,
      required: true,
      default: "Message",
    },
  },
  computed: {
    showConfirmation() {
      return store.getters.getShowConfirmation;
    },
  },
  emits: ["close", "submit"],
};
</script>
