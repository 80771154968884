<template>
  <div class="flex items-center cursor-pointer me-9">
    <img
      alt="86-image-user"
      :src="detailAuth?.user?.picture"
      class="object-cover h-10 rounded-full me-2"
      width="40"
    />
    <div class="text-left">
      <div
        class="text-sm font-semibold leading-5"
        :class="style === 'white' ? 'text-white' : 'text-grayscale-900'"
      >
        {{ detailAuth?.user?.name }}
      </div>
      <div class="text-xs font-normal leading-4 text-grayscale-400">
        {{ detailAuth?.user?.email }}
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { computed } from "vue";

export default {
  name: "MiniProfile",
  props: {
    style: {
      validator(value) {
        return ["white", "normal"].includes(value);
      },
    },
  },
  setup() {
    const token = JSON.parse(window.localStorage.getItem("86"));

    const detailAuth = computed(() => store.getters.getDetailAuth);

    return {
      token,
      detailAuth,
    };
  },
};
</script>
