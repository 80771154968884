<template>
  <div class="sticky top-0 z-[100] w-full bg-primary-50">
    <div
      class="bg-white h-[72px] w-full items-center justify-end pe-[50px] hidden sm:flex"
    >
      <div class="relative" :class="showNotification ? 'z-[2]' : 'z-0'">
        <img
          alt="86-notification-on"
          :src="require(`@/assets/images/86-notification-on.svg`)"
          class="cursor-pointer me-2"
          width="32"
          @click="setShowNotification"
        />
        <notification />
      </div>
      <router-link :to="'/admin/setting'">
        <img
          alt="86-setting"
          :src="require(`@/assets/images/86-setting.svg`)"
          class="cursor-pointer me-2"
          width="32"
        />
      </router-link>
      <hr class="bg-grey w-[1px] h-[44px] me-6" />
      <mini-profile />
      <img
        alt="86-logout"
        :src="require(`@/assets/images/86-logout.svg`)"
        class="cursor-pointer"
        @click="handleConfirmationLogout"
      />
    </div>
  </div>
  <confirmation
    :show="showConfirmation"
    :title="'Keluar dari dashboard'"
    :message="'Apakah Anda yakin ingin keluar dari dasboard?'"
    @close="handleConfirmationLogout"
    @submit="confirmLogout"
  />
</template>

<script>
import store from "@/store";
import MiniProfile from "../miniprofile.vue";
import Notification from "@/components/notifications";
import Confirmation from "@/components/confirmation.vue";

export default {
  components: { MiniProfile, Notification, Confirmation },
  name: "Topbar",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    showConfirmation() {
      return store.getters.getShowConfirmation;
    },
    showNotification() {
      return store.getters.getShowNotification;
    },
  },
  methods: {
    handleConfirmationLogout() {
      store.commit("setShowConfirmation", !store.getters.getShowConfirmation);
    },
    confirmLogout() {
      localStorage.removeItem("86");
      window.location.reload();
    },
    setShowNotification() {
      store.commit("setShowNotification", !store.getters.getShowNotification);
    },
  },
};
</script>
