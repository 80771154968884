<template>
  <div class="grid gap-4">
    <label
      v-for="(item, index) in listItem"
      :key="index"
      class="col flex items-start py-[10px] px-[10px] w-full rounded-xl border accent-[#B54708] cursor-pointer"
      :class="[
        Object.keys(listItem).length - 1 !== index && 'mr-8',
        item.value == select
          ? 'bg-surfaceSecondary border-primary-50'
          : ' border-grayscale-200',
      ]"
      @change="$emit('update:modelValue', $event.target.value)"
      :for="item.value"
    >
      <input
        type="radio"
        :id="item.value"
        :name="name"
        :value="item.value"
        class="w-[20px] h-[20px] mr-3"
        @click="onChangeSelect"
        :checked="item.value == select"
        :disabled="disabled"
      />
      <span>
        <div class="text-base font-medium leading-6">{{ item.label }}</div>
        <div>{{ item.deskripsi }}</div>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "InputCheckbox",
  props: {
    name: {
      type: String,
    },
    select: {
      type: String,
    },
    listItem: {
      type: Object,
      required: true,
      default() {},
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ["onChangeSelect"],
  setup(_, { emit }) {
    const onChangeSelect = (data) => {
      emit("onChangeSelect", data.target.value);
    };

    return { onChangeSelect };
  },
};
</script>
