<template>
  <div class="container px-5 mx-auto mt-14">
    <div class="sm:border sm:p-6 rounded-2xl">
      <div class="text-xl font-semibold leading-9 text-left sm:text-3xl">
        Kategori Acara
      </div>
      <div class="grid grid-cols-2 gap-4 mt-5 sm:grid-cols-4 sm:gap-6 sm:mt-6">
        <div
          class="flex items-center px-5 py-4 border rounded-lg cursor-pointer"
          :class="selectedCategory === '' ? 'bg-surfaceSecondary' : 'bg-white'"
          @click="$emit('click', '')"
        >
          <div class="p-3 mr-5 rounded-full bg-primary-50">
            <img
              alt="86-sample-icon-category-event"
              :src="require(`@/assets/images/86-category-all.svg`)"
              width="24"
              height="24"
              class="left-0 rounded-full"
            />
          </div>
          <div class="text-xs font-semibold sm:text-base">Semua</div>
        </div>
        <div v-for="(item, index) in detail" :key="index">
          <div>
            <card-event-category-user
              v-show="showAll || (!showAll && index < 3)"
              :detail="item"
              :active="item.id == selectedCategory"
              @click="$emit('click', item.id)"
              :class="index > 2 && 'hidden sm:flex'"
            />
          </div>
        </div>
      </div>
      <div @click="$emit('show')">
        <div
          v-if="!showAll"
          class="flex items-center justify-center mt-6 cursor-pointer"
        >
          <div>Lihat Lebih Banyak</div>
          <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="ml-1.5 w-5 h-5 hidden sm:block"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.23017 7.20938C5.52875 6.92228 6.00353 6.93159 6.29063 7.23017L10 11.1679L13.7094 7.23017C13.9965 6.93159 14.4713 6.92228 14.7698 7.20938C15.0684 7.49647 15.0777 7.97125 14.7906 8.26983L10.5406 12.7698C10.3992 12.9169 10.204 13 10 13C9.79599 13 9.60078 12.9169 9.45938 12.7698L5.20938 8.26983C4.92228 7.97125 4.93159 7.49647 5.23017 7.20938Z"
              fill="#0F172A"
            />
          </svg>
        </div>
        <div
          v-else
          class="flex items-center justify-center mt-6 cursor-pointer"
        >
          <div>Lihat Lebih Sedikit</div>
          <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="ml-1.5 w-5 h-5 rotate-180 hidden sm:block"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.23017 7.20938C5.52875 6.92228 6.00353 6.93159 6.29063 7.23017L10 11.1679L13.7094 7.23017C13.9965 6.93159 14.4713 6.92228 14.7698 7.20938C15.0684 7.49647 15.0777 7.97125 14.7906 8.26983L10.5406 12.7698C10.3992 12.9169 10.204 13 10 13C9.79599 13 9.60078 12.9169 9.45938 12.7698L5.20938 8.26983C4.92228 7.97125 4.93159 7.49647 5.23017 7.20938Z"
              fill="#0F172A"
            />
          </svg>
        </div>
      </div>
      <modal-template
        class="sm:hidden"
        :title="'Kategori Acara'"
        @close="$emit('show')"
        :show="showAll"
      >
        <div class="px-4 py-6">
          <div
            class="flex items-center px-5 py-4 border rounded-lg cursor-pointer"
            :class="
              selectedCategory === '' ? 'bg-surfaceSecondary' : 'bg-white'
            "
            @click="$emit('click', '')"
          >
            <div class="p-3 mr-5 rounded-full bg-primary-50">
              <img
                alt="86-sample-icon-category-event"
                :src="require(`@/assets/images/86-category-all.svg`)"
                width="24"
                height="24"
                class="left-0 rounded-full"
              />
            </div>
            <div class="text-xs font-semibold sm:text-base">Semua</div>
          </div>
          <div v-for="(item, index) in detail" :key="index">
            <card-event-category-user
              :detail="item"
              :active="item.id == selectedCategory"
              class="mt-3"
              @click="$emit('click', item.id)"
            />
          </div>
        </div>
      </modal-template>
    </div>
  </div>
</template>

<script>
import CardEventCategoryUser from "@/components/events/cardEventCategoryUser.vue";
import ModalTemplate from "@/components/modalTemplate.vue";

export default {
  name: "EventDetailCategory",
  components: {
    CardEventCategoryUser,
    ModalTemplate,
  },
  props: {
    detail: {
      type: Array,
      default: () => [],
    },
    selectedCategory: {
      type: String,
    },
    showAll: {
      type: Boolean,
    },
  },
  emits: ["click", "show"],
  setup() {},
};
</script>
