<template>
  <div>
    <div class="flex w-full">
      <sidebar />
      <div class="flex-1 w-full min-w-0">
        <topbar />
        <div
          class="bg-grayscale-50 py-4 px-5 text-left mt-[72px] sm:mt-0"
          style="min-height: calc(100vh - 72px)"
        >
          <breadcrumb :title="'Beranda'" :list="listMenu" />
          <loading-dashboard v-if="loading" />
          <template v-else>
            <div
              class="grid items-center grid-cols-1 gap-4 mb-5 sm:grid-cols-2 sm:items-start"
            >
              <div class="col">
                <div class="grid items-start gap-4 sm:grid-cols-2">
                  <metric :data="dataUser" :title="'Total User'" class="col" />
                  <metric
                    :data="dataEvent"
                    :title="'Total Acara'"
                    :image="'86-file.svg'"
                    class="col"
                  />
                </div>
                <user
                  v-if="dataConclusion"
                  :list="dataConclusion"
                  class="mt-5"
                />
              </div>
              <div class="mt-5 col sm:mt-0">
                <activity :data="dataActivity" :total="totalActivity" />
              </div>
            </div>
            <table-event-dashboard
              :addData="false"
              :count="totalEvent"
              :page="page"
              :totalPage="totalPage"
              :list="listEvent"
              :search="search"
              :selected="selectedFilter"
              @search="searchBySearch"
              @searchFilter="searchFilter"
              @before="beforePageEvent"
              @next="nextPageEvent"
              @deleteEvent="deleteEvent"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, onUnmounted } from "vue";
import { useStore } from "vuex";
import Sidebar from "@/components/layouts/sidebar.vue";
import Topbar from "@/components/layouts/topbar.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import Metric from "@/components/progress/metric.vue";
import Activity from "@/components/activity.vue";
import User from "@/components/progress/user.vue";
import LoadingDashboard from "@/components/loading/loadingDashboard.vue";
import TableEventDashboard from "@/components/tables/tableEventDashboard.vue";

export default {
  components: {
    Sidebar,
    Topbar,
    Breadcrumb,
    Metric,
    Activity,
    User,
    TableEventDashboard,
    LoadingDashboard,
  },
  name: "HomeView",
  setup() {
    const store = useStore();
    const loading = ref(true);
    const listMenu = ref([
      {
        title: "Beranda",
        link: "/admin",
      },
      {
        title: "Overview Dashboard",
        link: "/admin",
      },
    ]);
    const totalEvent = computed(() => {
      return store.getters.getTotalEvent;
    });
    const page = computed(() => {
      return store.getters.getOffsetEvent;
    });
    const totalPage = computed(() => {
      return store.getters.getTotalPageEvent;
    });
    const listEvent = computed(() => {
      return store.getters.getListEvent;
    });
    const dataUser = computed(() => {
      return store.getters.getDataUserDashboard;
    });
    const dataEvent = computed(() => {
      return store.getters.getDataEventDashboard;
    });
    const totalActivity = computed(() => {
      return store.getters.getTotalActivity;
    });
    const dataActivity = computed(() => {
      return store.getters.getListActivity;
    });
    const dataConclusion = computed(() => {
      return store.getters.getDataConclusionDashboard;
    });
    const search = computed(() => {
      return store.getters.getSearchUser;
    });
    const selectedFilter = ref("");

    const searchBySearch = (data) => {
      store.commit("setSearchEvent", data);
      store.dispatch("getListEvent");
    };

    const searchFilter = (selected, searchBy, sortValue) => {
      selectedFilter.value = selected;
      store.commit("setSortByEvent", searchBy);
      store.commit("setSortValueEvent", sortValue);
      store.dispatch("getListEvent");
    };

    onMounted(async () => {
      store.commit("setActiveMenu", "Dashboard");
      await store.dispatch("getListUserDashboard");
      await store.dispatch("getListEventDashboard");
      await store.dispatch("getListActivity");
      await store.dispatch("getListConclusionDashboard");
      await store.dispatch("getListEvent");
      loading.value = false;
    });

    onUnmounted(() => {
      store.commit("setOffsetEvent", 1);
    });

    watch(
      () => {
        return store.getters.getDataUserDashboard;
      },
      function () {
        dataUser.value = store.getters.getDataUserDashboard;
      }
    );

    watch(
      () => {
        return store.getters.getDataEventDashboard;
      },
      function () {
        dataEvent.value = store.getters.getDataEventDashboard;
      }
    );

    watch(
      () => {
        return store.getters.getListActivity;
      },
      function () {
        dataActivity.value = store.getters.getListActivity;
      }
    );

    watch(
      () => {
        return store.getters.getDataConclusionDashboard;
      },
      function () {
        dataConclusion.value = store.getters.getDataConclusionDashboard;
      }
    );

    const beforePageEvent = () => {
      if (page.value !== 0) {
        store.commit("setOffsetEvent", page.value - 1);
        store.dispatch("getListEvent");
      }
    };

    const nextPageEvent = () => {
      if (page.value !== totalPage.value) {
        store.commit("setOffsetEvent", page.value + 1);
        store.dispatch("getListEvent");
      }
    };

    const deleteEvent = (data) => {
      loading.value = true;
      store.dispatch("deleteEvent", data).then(() => {
        store.commit("setOffsetEvent", 1);
        store.dispatch("getListEvent").then(() => {
          loading.value = false;
        });
      });
    };

    return {
      loading,
      listMenu,
      totalEvent,
      page,
      totalPage,
      listEvent,
      search,
      selectedFilter,
      searchBySearch,
      searchFilter,
      dataUser,
      dataEvent,
      totalActivity,
      dataActivity,
      dataConclusion,
      beforePageEvent,
      nextPageEvent,
      deleteEvent,
    };
  },
};
</script>
