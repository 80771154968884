<template>
  <div>
    <div class="flex w-full">
      <sidebar />
      <div class="flex-1 w-full min-w-0">
        <topbar />
        <div
          class="bg-grayscale-50 py-4 px-5 text-left mt-[72px] sm:mt-0"
          style="min-height: calc(100vh - 72px)"
        >
          <breadcrumb :title="'Aktivitas'" :list="listMenu" />
          <loading-table v-if="loading" />
          <table-activity
            v-else
            class="mt-5"
            :count="totalActivity"
            :page="page"
            :totalPage="totalPage"
            :list="listActivity"
            :username="username"
            :selected="selectedFilter"
            @searchUsername="searchByUsername"
            @searchFilter="searchFilter"
            @before="beforePage"
            @next="nextPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import Sidebar from "@/components/layouts/sidebar.vue";
import Topbar from "@/components/layouts/topbar.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import TableActivity from "@/components/tables/tableActivity.vue";
import LoadingTable from "@/components/loading/loadingTable.vue";

export default {
  components: {
    Sidebar,
    Topbar,
    Breadcrumb,
    TableActivity,
    LoadingTable,
  },
  name: "LogActivity",
  setup() {
    const loading = ref(false);
    const store = useStore();
    const listMenu = ref([
      {
        title: "Beranda",
        link: "/admin",
      },
      {
        title: "Aktivitas List",
        link: "/admin/log",
      },
    ]);
    const totalActivity = computed(() => {
      return store.getters.getTotalActivity;
    });
    const page = computed(() => {
      return store.getters.getOffsetActivity;
    });
    const totalPage = computed(() => {
      return store.getters.getTotalPageActivity;
    });
    const listActivity = computed(() => {
      return store.getters.getListActivity;
    });
    const username = computed(() => {
      return store.getters.getUsernameActivity;
    });
    const selectedFilter = ref("");

    const searchByUsername = (data) => {
      store.commit("setUsernameActivity", data);
      store.dispatch("getListActivity");
    };

    const searchFilter = (selected, searchBy, sortValue) => {
      selectedFilter.value = selected;
      store.commit("setSortByActivity", searchBy);
      store.commit("setSortValueActivity", sortValue);
      store.dispatch("getListActivity");
    };

    const beforePage = () => {
      if (page.value !== 0) {
        store.commit("setOffsetActivity", page.value - 1);
        store.dispatch("getListActivity");
      }
    };

    const nextPage = () => {
      if (page.value !== totalPage.value) {
        store.commit("setOffsetActivity", page.value + 1);
        store.dispatch("getListActivity");
      }
    };

    onMounted(() => {
      loading.value = true;
      store.dispatch("getListActivity").then(() => {
        loading.value = false;
      });
      store.commit("setActiveMenu", "Aktivitas Log");
    });

    return {
      loading,
      listMenu,
      totalActivity,
      page,
      totalPage,
      listActivity,
      username,
      selectedFilter,
      searchByUsername,
      searchFilter,
      beforePage,
      nextPage,
    };
  },
};
</script>
