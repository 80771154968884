<script setup>
import { ref, computed, onBeforeMount, watch } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import Sidebar from "@/components/layouts/sidebar.vue";
import Topbar from "@/components/layouts/topbar.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import ProfilePicture from "@/components/profiles/profilePicture.vue";
import UploadImage2 from "@/components/uploadImage2.vue";
import TableCreateEvent from "@/components/tables/tableCreateEvent.vue";
import ProfileUser from "@/components/profiles/profileUser.vue";
import ProfileEdit from "@/components/profiles/profileEdit.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import SidebarTemplate from "@/components/sidebarTemplate.vue";
import InputPassword from "@/components/inputs/inputPassword.vue";
import AlertTop from "@/components/alertTop.vue";
import Confirmation from "@/components/confirmation.vue";
import ModalTemplate from "@/components/modalTemplate.vue";
import LoadingProfile from "@/components/loading/loadingProfile.vue";

const route = useRoute();
const router = useRouter();
const selectedMenu = ref("profile");
const listMenu = ref([
  {
    title: "Beranda",
    link: "/",
  },
  {
    title: "List Pengguna",
    link: "/admin/user",
  },
  {
    title: route.params.id,
    link: "/admin/event/" + route.params.id,
  },
]);
const id = ref();
const loading = ref(true);
const detail = ref([]);
const dummyImage = ref("");
const onEdit = ref(false);
const showResetPassword = ref(false);
const newPassword = ref("");
// const showDeleteUser = ref(false);

// const handleShowDeleteUser = () => {
//   showDeleteUser.value = !showDeleteUser.value;
// };
// notification
const showNotification = ref(false);
const statusNotification = ref("");
const titleNotification = ref("");
const messageNotification = ref("");

const handleShowNotification = () => {
  showNotification.value = !showNotification.value;
};

// history
const totalHistory = computed(() => {
  return store.getters.getTotalEventUser;
});
const pageHistory = computed(() => {
  return store.getters.getOffsetEventUser;
});
const totalPageHistory = computed(() => {
  return store.getters.getTotalPageEventUser;
});
const listHistory = computed(() => {
  return store.getters.getEventUser;
});

const beforePageHistory = () => {
  if (pageHistory.value !== 1) {
    store.commit("setOffsetEventUser", pageHistory.value - 1);
    store.dispatch("getEventUser", id.value);
  }
};

const nextPageHistory = () => {
  if (pageHistory.value !== totalPageHistory.value) {
    store.commit("setOffsetEventUser", pageHistory.value + 1);
    store.dispatch("getEventUser", id.value);
  }
};

const participatedEvents = computed(() => store.getters.getParticipatedEvents);
const participatedEventsTotal = computed(
  () => store.getters.getParticipatedEventsTotal
);
const participatedEventsPage = computed(
  () => store.getters.getParticipatedEventsPage
);
const participatedEventsTotalPage = computed(
  () => store.getters.getParticipatedEventsTotalPage
);

const goToPrevParticipatedEventsPage = () => {
  if (participatedEventsPage.value !== 1) {
    store.commit("setParticipatedEventsPage", participatedEventsPage.value - 1);
    store.dispatch("getParticipatedEvents", id.value);
  }
};

const goToNextParticipatedEventsPage = () => {
  if (participatedEventsPage.value !== participatedEventsTotalPage.value) {
    store.commit("setParticipatedEventsPage", participatedEventsPage.value + 1);
    store.dispatch("getParticipatedEvents", id.value);
  }
};

const handleSelectedMenu = (selected) => {
  selectedMenu.value = selected;
};

const handleOnEdit = () => {
  onEdit.value = !onEdit.value;
};

const handleShowResetPassword = () => {
  newPassword.value = "";
  showResetPassword.value = !showResetPassword.value;
};

const changeImage = (data) => {
  dummyImage.value = data;
};

const submitData = async (detail) => {
  let dummy = { ...detail };
  if (dummyImage.value) {
    const res = await store.dispatch("uploadImage", dummyImage.value);

    if (!res) {
      return;
    }

    dummy = { ...dummy, picture: res.data.result };
  } else {
    dummy = { ...dummy, picture: null };
  }

  const isSuccess = await store.dispatch("updateProfile", { data: dummy });

  if (isSuccess) {
    onEdit.value = false;
  }
};

const showConfirmationChangePassword = ref(false);

const handleShowConfirmationChangePassword = () => {
  showConfirmationChangePassword.value = !showConfirmationChangePassword.value;
};

const changePassword = () => {
  const payload = {
    email: detail.value.email,
    password: newPassword.value,
  };
  store.dispatch("forgetPassword", payload).then((res) => {
    handleShowConfirmationChangePassword();
    if (res.data?.message !== undefined) {
      statusNotification.value = "fail";
      titleNotification.value = "Gagal mengubah kata sandi";
      messageNotification.value =
        "Pengguna gagal mengubah kata sandi. Pastikan bahwa data kredensial pengguna yang Anda masukan sudah benar.";
    } else {
      statusNotification.value = "success";
      titleNotification.value = "Berhasil mengubah kata sandi";
      messageNotification.value =
        "Perubahan anda telah berhasil disimpan. Pastikan perubahan yang telah dibuat sudah benar.";
      newPassword.value = "";
    }
    handleShowResetPassword();
    handleShowNotification();
  });
};

const showConfirmationDelete = ref(false);

const handleShowConfirmationDelete = () => {
  showConfirmationDelete.value = !showConfirmationDelete.value;
};

const deleteUser = () => {
  store.dispatch("deleteSingleUser", route.params.id).then((res) => {
    handleShowConfirmationDelete();
    if (res.data?.message !== undefined) {
      statusNotification.value = "fail";
      titleNotification.value = "Gagal menghapus pengguna.";
      messageNotification.value =
        "Pengguna gagal dihapus. Silahkan hapus kembali pengguna yang diinginkan. Pastikan bahwa pengguna yang akan dihapus sudah benar karena aktivitas ini tidak dapat dibatalkan.";
      handleShowNotification();
    } else {
      statusNotification.value = "success";
      titleNotification.value = "Berhasil menghapus pengguna";
      messageNotification.value =
        "Anda berhasil menghapus pengguna. Tindakan ini tidak dapat dibatalkan. Silahkan menambahkan kembali pengguna apabila terjadi kesalahan dalam menghapus pengguna.";
      newPassword.value = "";
      handleShowNotification();
      setTimeout(() => {
        router.push("/admin/user");
      }, 2000);
    }
  });
};

onBeforeMount(() => {
  store.commit("setActiveMenu", "Pengguna");
  loadData();
});

const loadData = async () => {
  await store.dispatch("getProfile", route.params.id).then((res) => {
    if (res.data?.message !== undefined) {
      router.push("/404");
    } else {
      loading.value = false;
      store.commit("setLimitEventUser", 10);
      id.value = res.data.result.id;
      store.dispatch("getEventUser", res.data.result.id);
      store.dispatch("getParticipatedEvents", res.data.result.id);
    }
  });
};

watch(
  () => {
    return store.getters.getDetail;
  },
  function () {
    detail.value = store.getters.getDetail;
  }
);

watch(
  () => {
    return store.getters.getEventUser;
  },
  function () {
    listHistory.value = store.getters.getEventUser;
  }
);

const wantToCancel = ref(false);

const cancel = () => {
  wantToCancel.value = false;
  onEdit.value = false;
  loadData();
};
</script>

<template>
  <div>
    <div class="flex w-full">
      <sidebar />
      <div class="w-full">
        <topbar />
        <div
          class="bg-grayscale-50 py-4 px-5 text-left mt-[72px] sm:mt-0"
          style="min-height: calc(100vh - 72px)"
        >
          <breadcrumb :title="'Pengguna'" :list="listMenu" />
          <loading-profile v-if="loading" />
          <div
            v-else
            class="grid items-start grid-cols-1 sm:grid-cols-4 sm:gap-6"
          >
            <profile-picture
              class="border col rounded-xl"
              :image="detail.picture"
              v-if="!onEdit"
            />
            <div v-else class="bg-white border rounded-xl">
              <div
                class="px-4 py-5 text-lg font-semibold leading-7 border-b border-b-grayscale-100"
              >
                Foto Profile
              </div>
              <div class="p-4">
                <upload-image-2
                  class="border col rounded-xl"
                  :img="detail.picture"
                  @updateImage="changeImage"
                />
              </div>
            </div>
            <div class="col-span-3 mt-10 text-left sm:mt-0">
              <div class="flex mb-6">
                <div
                  class="pb-3 mx-4 mt-3 cursor-pointer"
                  :class="
                    selectedMenu === 'profile' &&
                    'border-b border-b-primary-50 text-primary-50'
                  "
                  @click="handleSelectedMenu('profile')"
                >
                  Data Pribadi
                </div>
                <div
                  class="pb-3 mx-4 mt-3 cursor-pointer"
                  :class="
                    selectedMenu === 'history' &&
                    'border-b border-b-primary-50 text-primary-50'
                  "
                  @click="handleSelectedMenu('history')"
                >
                  Riwayat Acara
                </div>
                <div
                  class="pb-3 mx-4 mt-3 cursor-pointer"
                  :class="
                    selectedMenu === 'create' &&
                    'border-b border-b-primary-50 text-primary-50'
                  "
                  @click="handleSelectedMenu('create')"
                >
                  Acara Dibuat
                </div>
              </div>
              <div v-if="selectedMenu === 'profile'">
                <div class="flex items-center justify-between px-5 py-4">
                  <div class="text-lg font-semibold">Overview</div>
                  <div class="flex">
                    <button-general
                      :title="'Hapus'"
                      :size="'s'"
                      :border="'s'"
                      :width="'auto'"
                      class="text-red-main"
                      @click="handleShowConfirmationDelete"
                    />
                    <button-general
                      :title="'Reset Sandi'"
                      :size="'s'"
                      :border="'s'"
                      :width="'auto'"
                      @click="handleShowResetPassword"
                    />
                    <button-general
                      :title="'Edit'"
                      :size="'s'"
                      :border="'s'"
                      :width="'auto'"
                      :color="'outline'"
                      @click="handleOnEdit"
                    />
                  </div>
                </div>
                <profile-user :detail="detail" v-if="!onEdit" />
                <profile-edit
                  :detail="detail"
                  @cancel="wantToCancel = true"
                  @submit="submitData"
                  v-else
                />
              </div>
              <div
                v-else-if="selectedMenu === 'history'"
                class="w-full bg-white border rounded-xl"
              >
                <div class="px-6 py-5 text-xl font-semibold leading-7">
                  Riwayat Acara
                </div>
                <table-create-event
                  v-if="participatedEventsTotal"
                  :data="participatedEvents"
                  :count="participatedEventsTotal"
                  :page="participatedEventsPage"
                  :totalPage="participatedEventsTotalPage"
                  @before="goToPrevParticipatedEventsPage"
                  @next="goToNextParticipatedEventsPage"
                  :link="'/event'"
                />
                <div v-else>
                  <div class="px-6 py-5 mx-auto text-center">
                    <img
                      :src="require(`@/assets/images/86-empty-comment.svg`)"
                      alt="Ilustrasi"
                      class="w-[256px] inline-block"
                    />
                    <p class="text-lg font-semibold text-grayscale-900">
                      Belum Mengikuti Acara
                    </p>
                    <p class="text-sm text-grayscale-500">
                      Pengguna belum mengikuti acara apapun.
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-else-if="selectedMenu === 'create'"
                class="w-full bg-white border rounded-xl"
              >
                <div class="px-6 py-5 text-xl font-semibold leading-7">
                  Acara Dibuat
                </div>
                <table-create-event
                  v-if="totalHistory"
                  :data="listHistory"
                  :count="totalHistory"
                  :page="pageHistory"
                  :totalPage="totalPageHistory"
                  @before="beforePageHistory"
                  @next="nextPageHistory"
                  :link="'/event'"
                />
                <div v-else>
                  <div class="px-6 py-5 mx-auto text-center">
                    <img
                      :src="require(`@/assets/images/86-empty-comment.svg`)"
                      alt="Ilustrasi"
                      class="w-[256px] inline-block"
                    />
                    <p class="text-lg font-semibold text-grayscale-900">
                      Belum Mengikuti Acara
                    </p>
                    <p class="text-sm text-grayscale-500">
                      Pengguna belum mengikuti acara apapun.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sidebar-template
      :title="'Reset Kata Sandi'"
      :show="showResetPassword"
      @close="handleShowResetPassword"
    >
      <div class="px-10 py-4 text-left">
        <div class="text-lg font-semibold">Reset Kata Sandi</div>
        <div class="text-sm text-grayscale-500">
          Langkah ini dilakukan ketika kata sandi lama pengguna terlupakan.
          Sehingga pengguna bisa mendapatkan kembali akses ke akun 86
          university.
        </div>
        <input-password
          class="mt-4"
          :title="'Kata Sandi Baru'"
          :useIcon="false"
          :placeholder="'Masukan kata sandi baru'"
          v-model="newPassword"
          :value="newPassword"
        />
      </div>
      <div
        class="fixed bottom-0 w-full px-10 py-5 bg-white sm:w-2/4"
        style="box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.08)"
      >
        <button-general
          :title="'Simpan'"
          :size="'s'"
          :border="'s'"
          :color="'primary'"
          @click="handleShowConfirmationChangePassword"
        />
      </div>
    </sidebar-template>
    <alert-top
      :showAlert="showNotification"
      :status="statusNotification"
      :title="titleNotification"
      :message="messageNotification"
      @close="handleShowNotification"
    />
    <confirmation
      :title="'Hapus pengguna'"
      :message="'Apakah anda yakin menghapus pengguna ini? Tindakan ini tidak dapat dibatalkan.'"
      :show="showConfirmationDelete"
      :status="'danger'"
      @close="handleShowConfirmationDelete"
      @submit="deleteUser"
    />
    <confirmation
      :title="'Ubah kata sandi'"
      :message="'Apakah Anda yakin ingin mengubah kata sandi pengguna ini? Pastikan Anda telah mendapatkan persetujuan dari pengguna sebelum melanjutkan.'"
      :show="showConfirmationChangePassword"
      :status="'alert'"
      @close="handleShowConfirmationChangePassword"
      @submit="changePassword"
    />
    <modal-template
      @close="wantToCancel = false"
      :show="wantToCancel"
      :show-icon="true"
      icon-type="alert"
    >
      <div class="px-5 pb-5 text-left sm:last:p-6">
        <div class="text-lg font-semibold">Batalkan edit pengguna</div>
        <div class="text-sm text-grayscale-500">
          Apakah Anda yakin membatalkan edit pengguna?
        </div>
      </div>
      <div class="flex justify-end p-5 sm:p-6">
        <button-general
          :title="'Tidak'"
          :size="'s'"
          :width="'full'"
          :color="'outline'"
          class="mr-[6px]"
          @click="wantToCancel = false"
        />
        <button-general
          :title="'Ya'"
          :size="'s'"
          :width="'full'"
          :color="'primary'"
          @click="cancel"
        />
      </div>
    </modal-template>
  </div>
</template>
