import axios from "axios";

export default {
  state: {
    totalUser: 0,
    totalPage: 0,
    listUser: [],
    offset: 1,
    limit: 10,
    sortBy: "created_date",
    username: "",
    sortValue: "desc",
    detail: [],
    userParticipate: [],
    listJob: [],
  },
  getters: {
    getTotalUser: (state) => {
      return state.totalUser;
    },
    getTotalPageUser: (state) => {
      return state.totalPage;
    },
    getListUser: (state) => {
      return state.listUser;
    },
    getOffsetUser: (state) => {
      return state.offset;
    },
    getSortByUser: (state) => {
      return state.sortBy;
    },
    getUsernameUser: (state) => {
      return state.username;
    },
    getSortValueUser: (state) => {
      return state.sortValue;
    },
    getDetail: (state) => {
      return state.detail;
    },
    getUserParticipate: (state) => {
      return state.userParticipate;
    },
    getListJob: (state) => {
      return state.listJob;
    },
  },
  actions: {
    async getListUser({ state, commit }) {
      const params = {
        offset: state.offset,
        limit: state.limit,
        sortBy: state.sortBy,
        username: state.username,
      };
      const url =
        process.env.VUE_APP_API_URL +
        `/api/v1/user?` +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        commit("setTotalUser", response.data.result.total);
        commit("setTotalPageUser", response.data.result.total);
        commit("setListUser", response.data.result.datas);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    // personal
    async detailProfile(_, data) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/user/${data}`;
      try {
        const response = await axios({
          method: "get",
          url: url,
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async updateProfile({ dispatch }, { data, showToast = true }) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/user`;
      try {
        await axios({
          method: "put",
          url: url,
          data,
          headers: {
            Authorization: `
							Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        if (showToast) {
          dispatch("addNewAlert", {
            status: "success",
            title: "Berhasil",
            message: "Anda berhasil mengubah data pengguna",
          });
        }
        return true;
      } catch (error) {
        if (showToast) {
          dispatch("addNewAlert", {
            status: "error",
            title: "Terdapat kesalahan",
            message:
              "Mengubah data pengguna gagal, silakan masukkan data dengan benar dan coba lagi.",
          });
        }
        return false;
      }
    },
    async completeProfile({ dispatch }, { data, showToast = true }) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/user-post-register`;
      try {
        await axios({
          method: "put",
          url: url,
          data,
          headers: {
            Authorization: `
							Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        if (showToast) {
          dispatch("addNewAlert", {
            status: "success",
            title: "Berhasil",
            message: "Anda berhasil mengubah data pengguna",
          });
        }
        return true;
      } catch (error) {
        if (showToast) {
          dispatch("addNewAlert", {
            status: "error",
            title: "Terdapat kesalahan",
            message:
              "Mengubah data pengguna gagal, silakan masukkan data dengan benar dan coba lagi.",
          });
        }
        return false;
      }
    },
    async getProfile({ commit }, data) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/user/${data}`;
      try {
        const response = await axios({
          method: "get",
          url: url,
          data: data,
        });
        commit("setDetail", response.data.result);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async getEventParticipant({ commit }) {
      const url =
        process.env.VUE_APP_API_URL + `/api/v1/event/event-by-participant`;
      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `
							Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        commit("setUserParticipate", response.data.result.datas);
        return response;
      } catch (error) {
        commit("setUserParticipate", []);
        return error.response;
      }
    },
    async adduser(_, data) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/user`;
      try {
        await axios({
          method: "post",
          url: url,
          data,
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    async importUser({ dispatch }, data) {
      const payload = [];
      data.map((detail) => {
        payload.push({
          name: detail.Nama,
          nickname: detail["Nama Panggilan"],
          username: detail.Username,
          phoneNumber: detail.Telepon?.toString(),
          address: detail.Alamat,
          email: detail.Email,
          highSchool: detail.SMA,
          occupation: "-",
          about: "-",
          specialities: "-",
          picture: "",
          role: "ROLE_USER",
          languages: "Bahasa",
        });
      });
      const url = process.env.VUE_APP_API_URL + `/api/v1/user/imports`;
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: {
            users: payload,
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        dispatch("addNewAlert", {
          status: "success",
          title: "Berhasil",
          message: "Anda berhasil mengimpor pengguna",
        });
        return response;
      } catch (error) {
        dispatch("addNewAlert", {
          status: "error",
          title: "Terdapat kesalahan",
          message:
            "Mengimpor user gagal. Pastikan format excel sesuai atau username belum pernah digunakan sebelumnya.",
        });
        return error.response;
      }
    },
    async getListJob({ commit }, data) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/job`;
      try {
        const response = await axios({
          method: "get",
          url: url,
          data: data,
        });
        commit("setListJob", response.data.result.datas);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async deleteSingleUser(_, data) {
      const url = process.env.VUE_APP_API_URL + "/api/v1/user/" + data;
      try {
        const response = await axios({
          method: "delete",
          url: url,
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async deleteMultiUser(_, data) {
      const url = process.env.VUE_APP_API_URL + "/api/v1/user/delete";
      try {
        const response = await axios({
          method: "put",
          url: url,
          data: {
            userIds: data,
          },
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    },
  },
  mutations: {
    setTotalUser: (state, data) => {
      state.totalUser = data;
    },
    setTotalPageUser: (state, data) => {
      state.totalPage = Math.ceil(data / state.limit);
    },
    setListUser: (state, data) => {
      state.listUser = data;
    },
    setOffsetUser: (state, data) => {
      state.offset = data;
    },
    setLimitUser: (state, data) => {
      state.limit = data;
    },
    setSortByUser: (state, data) => {
      state.sortBy = data;
    },
    setUsernameUser: (state, data) => {
      state.username = data;
    },
    setSortValueUser: (state, data) => {
      state.sortValue = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },
    setUserParticipate: (state, data) => {
      state.userParticipate = data;
    },
    setListJob: (state, data) => {
      state.listJob = data;
    },
  },
};
