<template>
  <div class="flex h-screen">
    <div class="relative w-[50%] hidden sm:block">
      <img
        alt="86-splash-auth"
        :src="require(`@/assets/images/86-splash-auth.svg`)"
        class="h-full w-full absolute object-cover -z-10"
      />
      <img
        alt="86-logo-banner"
        :src="require(`@/assets/images/86-logo-banner.svg`)"
        class="w-[170px] sm:w-[348px] absolute bottom-12 left-12"
      />
    </div>
    <div class="flex-1 py-10 px-6 sm:py-10 sm:px-10">
      <img alt="86-logo" :src="require(`@/assets/images/86-logo.svg`)" />
      <div
        v-if="!successChange"
        class="h-full w-full sm:p-5 flex flex-col justify-center"
      >
        <h5>Buat Kata Sandi Baru</h5>
        <div class="text-sm leading-5 text-grayscale-500 mt-2">
          Buat kata sandi baru anda untuk dapat kembali mengakses akun anda.
        </div>
        <form ref="form" @submit.prevent="submit">
          <input-label
            class="mt-10"
            :title="'Username/Email'"
            :placeholder="'Masukkan Username/Email Anda'"
            :value="data.email"
            v-model="data.email"
            :messageError="messageEmail"
          />
          <input-password
            class="mt-4"
            :title="'Kata Sandi'"
            :useIcon="false"
            :placeholder="'Masukkan kata sandi anda'"
            v-model="data.password"
            :value="data.password"
            :messageError="messagePassword"
          />
          <input-password
            class="mt-4"
            :title="'Konfirmasi Kata Sandi'"
            :useIcon="false"
            :placeholder="'Masukkan kembali kata sandi'"
            v-model="data.repassword"
            :value="data.repassword"
            :messageError="messagePassword"
          />
          <button-primary
            :title="'Ubah Kata Sandi'"
            :active="email !== '' || password !== '' || repassword !== ''"
            class="mt-10"
          />
        </form>
        <div class="text-grayscale-600 text-sm font-normal leading-5 mt-10">
          Mengalami kesulitan?
          <!-- <router-link :to="'/register'"> -->
          <span class="cursor-pointer text-secondary"> Hubungi Admin </span>
          <!-- </router-link> -->
        </div>
        <div class="text-grayscale-600 text-sm font-normal leading-5 mt-6">
          Kembali ke
          <router-link
            to="/login"
            class="cursor-pointer text-grayscale-900 font-semibold"
          >
            login
          </router-link>
        </div>
      </div>
      <div
        v-else
        class="h-full w-full sm:p-5 flex flex-col items-center justify-center"
      >
        <img
          alt="86-checklist-success"
          :src="require(`@/assets/images/86-checklist-success.svg`)"
          width="120"
        />
        <h5 class="mt-6">Kata Sandi Berhasil Diubah!</h5>
        <div class="text-sm leading-5 text-grayscale-500 mt-2">
          Kata sandi berhasil diubah. Silahkan masuk kembali ke akun menggunakan
          kata sandi baru anda.
        </div>
        <router-link to="/login" class="w-full">
          <button-primary :title="'Masuk Akun'" class="mt-10" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import store from "@/store";
import useValidators from "@/modules/validators";
import InputLabel from "@/components/inputs/inputLabel.vue";
import InputPassword from "@/components/inputs/inputPassword.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";

export default {
  components: { InputLabel, InputPassword, ButtonPrimary },
  name: "ForgetPassword",
  setup() {
    const successChange = ref(false);
    const data = ref({
      email: "",
      password: "",
      repassword: "",
    });
    const { isEmpty, checkPassword } = useValidators();
    const messageEmail = ref("");
    const messagePassword = ref("");

    const validateInput = () => {
      messageEmail.value = "";
      messagePassword.value = "";
    };

    const submit = () => {
      messageEmail.value = isEmpty("Email", data.value.email);
      messagePassword.value = isEmpty("Password", data.value.password);
      messagePassword.value = checkPassword(
        data.value.password,
        data.value.repassword
      );
      if (messageEmail.value === "" && messagePassword.value === "") {
        const payload = {
          email: data.value.email,
          password: data.value.password,
        };
        store.dispatch("forgetPassword", payload).then((res) => {
          if (res) {
            if (res.data?.message !== undefined) {
              messageEmail.value = res.data.message;
            } else {
              successChange.value = true;
            }
          }
        });
      }
    };

    return {
      successChange,
      data,
      messageEmail,
      messagePassword,
      validateInput,
      submit,
    };
  },
};
</script>