<template>
  <div>
    <input-label
      :title="'Nama Acara'"
      :value="dataEvent.name"
      v-model="dataEvent.name"
    />
    <div class="mt-6">
      <label>Gambar Acara</label>
      <upload-image-2
        :title="'Gambar Acara'"
        :img="dataEvent.image"
        class="mt-[6px]"
        :aspectRatio="16 / 9"
        @updateImage="updateImage"
      />
      <div class="mt-2 text-xs text-grayscale-500">
        * Gambar acara akan ditampilkan sebagai thumbail dari setiap acara
      </div>
    </div>
    <div class="mt-6">
      <label>Jenis Acara</label>
      <input-checkbox
        :name="'TypeEvent'"
        :select="dataEvent.eventType"
        v-model="dataEvent.eventType"
        :listItem="typeEvent"
        class="gap-3 grid-cols-2 mt-[6px]"
        @onChangeSelect="onChangeType"
      />
      <input-label
        v-if="dataEvent.eventType"
        class="mt-6"
        :value="dataEvent.location"
        v-model="dataEvent.location"
        :placeholder="
          dataEvent.eventType === 'OFFLINE'
            ? 'Copy alamat dari google maps'
            : dataEvent.eventType === 'ONLINE'
            ? 'Masukan link google meet'
            : 'Silakan pilih jenis acara'
        "
      />
    </div>
    <input-select-title
      class="mt-6"
      :title="'Kategori Acara'"
      :listItem="detailCategory"
      :value="dataEvent.eventCategory"
      v-model="dataEvent.eventCategory"
    />
    <input-label
      class="mt-6"
      :title="'Kuota Acara'"
      :value="dataEvent.quota"
      v-model="dataEvent.quota"
    />
    <p class="mt-2 text-xs text-grayscale-500">
      * Kosongkan bila tidak ada batas kuota
    </p>
    <div class="grid grid-cols-1 gap-4 mt-6 sm:grid-cols-2">
      <div class="col">
        <label>Tanggal Mulai</label>
        <div class="mt-[6px] w-full">
          <date-picker
            v-model:value="dataEvent.startDate"
            placeholder="Masukan Tanggal"
            value-type="format"
            format="DD/MM/YYYY"
          />
        </div>
      </div>
      <div class="col">
        <label>Waktu Mulai</label>
        <div class="mt-[6px] w-full">
          <date-picker
            v-model:value="dataEvent.startTime"
            placeholder="Masukan jam"
            type="time"
            value-type="format"
            format="HH:mm"
          />
        </div>
      </div>
    </div>
    <div class="flex items-center mt-4">
      <input
        type="checkbox"
        v-model="usingEndDate"
        id="checkUsingEndDate"
        class="mr-2"
      />
      <label for="checkUsingEndDate">
        Tambahkan waktu dan tanggal selesai
      </label>
    </div>
    <div class="grid grid-cols-1 gap-4 mt-6 sm:grid-cols-2" v-if="usingEndDate">
      <div class="col">
        <label>Tanggal Akhir</label>
        <div class="mt-[6px] w-full">
          <date-picker
            v-model:value="dataEvent.endDate"
            placeholder="Masukan Tanggal"
            value-type="format"
            format="DD/MM/YYYY"
          />
        </div>
      </div>
      <div class="col">
        <label>Waktu Akhir</label>
        <div class="mt-[6px] w-full">
          <date-picker
            v-model:value="dataEvent.endTime"
            placeholder="Masukan jam"
            type="time"
            value-type="format"
            format="HH:mm"
          />
        </div>
      </div>
    </div>
    <div class="mt-6">
      <label>Deskripsi Acara</label>
      <div>
        <textarea
          v-model="dataEvent.description"
          placeholder="Masukan diskripsi Acara"
          rows="4"
          class="w-full px-3 py-2 border rounded-lg border-grayscale-200"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import InputSelectTitle from "@/components/inputs/inputSelectTitle.vue";
import InputLabel from "@/components/inputs/inputLabel.vue";
import InputCheckbox from "@/components/inputs/inputCheckbox.vue";
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import UploadImage2 from "../uploadImage2.vue";

export default {
  name: "EventAddDetail",
  components: {
    InputSelectTitle,
    InputLabel,
    InputCheckbox,
    DatePicker,
    UploadImage2,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    listGroup: {
      type: Array,
    },
    listCategory: {
      type: Array,
      default: () => [],
    },
    selectedType: {
      type: String,
    },
    thumbnailImage: {
      type: String,
    },
    image: {
      type: String,
    },
  },
  emits: ["updateThumbnailImage", "updateImage"],
  setup(props, { emit }) {
    const dataEvent = computed(() => {
      return props.data;
    });
    const detailGroup = computed(() => {
      return props.listGroup.map((item) => {
        return {
          value: item.id,
          label: item.displayName,
        };
      });
    });
    const detailCategory = computed(() => {
      return props.listCategory.map((item) => {
        return {
          value: item.id,
          label: item.displayName,
        };
      });
    });
    const typeEvent = ref([
      {
        value: "OFFLINE",
        label: "Lokasi",
      },
      {
        value: "ONLINE",
        label: "Online",
      },
    ]);
    const usingEndDate = ref(
      !(
        dataEvent.value.startDate === dataEvent.value.endDate &&
        dataEvent.value.startTime === dataEvent.value.endTime
      )
    );

    watch(
      usingEndDate,
      (value) => {
        if (!value) {
          dataEvent.value.endDate = null;
          dataEvent.value.endTime = null;
        }
      },
      { immediate: true }
    );

    const updateThumbnailImage = (data) => {
      emit("updateThumbnailImage", data);
    };
    const updateImage = (data) => {
      emit("updateImage", data);
    };

    return {
      dataEvent,
      detailGroup,
      detailCategory,
      typeEvent,
      usingEndDate,
      updateThumbnailImage,
      updateImage,
    };
  },
};
</script>
