<template>
  <div
    class="container grid grid-cols-1 gap-6 px-5 py-8 mx-auto mt-5 sm:grid-cols-3 sm:gap-8 sm:mt-8"
  >
    <div v-for="i in 6" :key="i">
      <div class="bg-grayscale-300 animate-pulse h-[300px] rounded-2xl"></div>
      <div
        class="w-full h-8 mt-4 bg-grayscale-300 animate-pulse rounded-2xl"
      ></div>
      <div
        class="w-1/2 h-8 mt-4 bg-grayscale-300 animate-pulse rounded-2xl"
      ></div>
    </div>
  </div>
</template>
