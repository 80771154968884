<script setup>
import { ref, computed, defineProps } from "vue";
import store from "@/store";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import ModalTemplate from "@/components/modalTemplate.vue";

const props = defineProps({
  eventId: {
    type: Number,
    required: true,
  },
});

const isEditing = ref(false);

const comments = computed(() => store.getters.getComments);
store.dispatch("getComments", props.eventId);

const expandedCommentIds = ref([]);
const deleteTo = ref(null);

const showDeleteConfirmation = (comment) => {
  deleteTo.value = comment.id;
};

const deleteComment = async (eventId, id) => {
  await store.dispatch("deleteComment", { eventId, id });
  await store.dispatch("getComments", eventId);

  deleteTo.value = null;
};

const isCommentExpanded = (commentId) => {
  return expandedCommentIds.value.includes(commentId);
};

const toggleEditingMode = () => {
  isEditing.value = !isEditing.value;
};

const toggleExpand = (commentId) => {
  if (isCommentExpanded(commentId)) {
    unexpandComment(commentId);
  } else {
    expandComment(commentId);
  }
};

const expandComment = (commentId) => {
  expandedCommentIds.value.push(commentId);
};

const unexpandComment = (commentId) => {
  expandedCommentIds.value = expandedCommentIds.value.filter(
    (id) => id !== commentId
  );
};
</script>

<template>
  <div class="w-full bg-white rounded-xl">
    <div
      class="flex items-center justify-between px-4 py-5 border-b border-b-grayscale-100"
    >
      <div class="text-lg font-semibold leading-7 text-grayscale-900">
        Komentar
      </div>
      <div class="flex">
        <button-general
          :title="'Edit'"
          :size="'xs'"
          :border="'s'"
          :color="'outline'"
          @click="toggleEditingMode"
        />
      </div>
    </div>
    <div class="px-6 py-4">
      <p class="text-xl font-semibold text-grayscale-900">
        {{ comments.length }} Komentar
      </p>
      <div v-if="comments.length">
        <div
          v-for="(comment, index) in comments"
          :key="index"
          class="flex mt-4"
        >
          <div class="flex-shrink-0">
            <img
              :src="comment.userImageUrl"
              :alt="comment.name"
              class="w-12 h-12 rounded-full"
            />
          </div>
          <div class="flex-1 ml-3">
            <div class="flex justify-between">
              <div>
                <p class="text-lg font-semibold text-grayscale-900">
                  {{ comment.name }}
                </p>
                <p class="mt-0.5 text-sm text-grayscale-600">
                  {{ comment.time }}
                  <template v-if="comment.edited">(diedit)</template>
                </p>
              </div>
              <div v-if="isEditing" class="ml-3">
                <button
                  class="text-secondary"
                  @click="showDeleteConfirmation(comment)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-[18px] h-[18px]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <p class="mt-3 text-grayscale-700">
              {{ comment.content }}
            </p>
            <div v-if="comment.replies.length">
              <div v-if="isCommentExpanded(comment.id)">
                <div
                  v-for="(reply, index2) in comment.replies"
                  :key="index2"
                  class="flex mt-4"
                >
                  <div class="flex-shrink-0">
                    <img
                      :src="reply.userImageUrl"
                      :alt="reply.name"
                      class="w-12 h-12 rounded-full"
                    />
                  </div>
                  <div class="flex-1 ml-3">
                    <div class="flex justify-between">
                      <div>
                        <p class="text-lg font-semibold text-grayscale-900">
                          {{ reply.name }}
                        </p>
                        <p class="mt-0.5 text-sm text-grayscale-600">
                          {{ reply.time }}
                          <template v-if="comment.edited">(diedit)</template>
                        </p>
                      </div>
                      <div v-if="isEditing" class="ml-3">
                        <button
                          class="text-secondary"
                          @click="showDeleteConfirmation(reply)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-[18px] h-[18px]"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <p class="mt-3 text-grayscale-700">
                      {{ reply.content }}
                    </p>
                  </div>
                </div>
              </div>
              <button
                class="mt-3 font-medium text-grayscale-800"
                @click="toggleExpand(comment.id)"
              >
                {{
                  isCommentExpanded(comment.id) ? "Sembunyikan" : "Tampilkan"
                }}
                balasan ({{ comment.replies.length }})
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="inline-block w-5 h-5"
                  :class="{ 'rotate-180': isCommentExpanded(comment.id) }"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mt-4">
        <div class="mx-auto text-center">
          <img
            :src="require(`@/assets/images/86-empty-comment.svg`)"
            alt="Belum Ada Komentar"
            class="w-[256px] inline-block"
          />
          <p class="text-lg font-semibold text-grayscale-900">
            Belum Ada Komentar
          </p>
          <p class="text-sm text-grayscale-500">
            Acara ini belum memiliki komentar.
          </p>
        </div>
      </div>
    </div>
  </div>
  <modal-template
    @close="deleteTo = null"
    :show="deleteTo"
    :show-icon="true"
    icon-type="warning"
  >
    <div class="px-5 pb-5 text-left sm:last:p-6">
      <div class="text-lg font-semibold">Hapus Komentar</div>
      <div class="text-sm text-grayscale-500">
        Apakah anda yakin ingin menghapus komentar ini?
      </div>
    </div>
    <div class="flex justify-end p-5 sm:p-6">
      <button-general
        :title="'Batal'"
        :size="'s'"
        :width="'full'"
        :color="'outline'"
        class="mr-[6px]"
        @click="deleteTo = null"
      />
      <button-general
        :title="'Hapus Komentar'"
        :size="'s'"
        :width="'full'"
        :color="'secondary'"
        @click="deleteComment(eventId, deleteTo)"
      />
    </div>
  </modal-template>
</template>

<script>
export default {
  name: "AdminEventComments",
};
</script>
