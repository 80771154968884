<template>
  <button
    class="text-base font-medium leading-6 w-full"
    :class="[
      active ? 'bg-primary-50' : 'bg-grayscale-300',
      size === 'xs'
        ? 'py-2'
        : size === 's'
        ? 'py-[8px]'
        : size === 'm'
        ? 'py-3'
        : size === 'm'
        ? 'py-[14px]'
        : 'py-4',
      border === 's' ? 'rounded-xl' : 'rounded-full',
    ]"
    :disabled="!active"
    @click="onSubmit"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "ButtonPrimary",
  props: {
    title: {
      type: String,
      required: true,
      default: "Button",
    },
    active: {
      type: Boolean,
      default: true,
    },
    size: {
      validator(value) {
        return ["xs", "s", "m", "l"].includes(value);
      },
    },
    border: {
      validator(value) {
        return ["s"].includes(value);
      },
    },
  },
  emits: ["click"],
  methods: {
    onSubmit() {
      this.$emit("click");
    },
  },
};
</script>