<template>
  <div class="w-100">
    <div class="container px-5 py-5 mx-auto text-left">
      <div class="flex item-center">
        <div class="w-2 mr-4 rounded-full bg-secondary"></div>
        <div
          class="text-xl leading-10 sm:text-[40px] font-semibold sm:leading-[56px] text-grayscale-900"
        >
          Galeri Acara
        </div>
      </div>
      <div
        class="text-2xl leading-8 sm:text-[56px] font-semibold sm:leading-[72px] mt-6 text-grayscale-900"
      >
        “ Kualitas dari sebuah persahabatan sejati akan mendatangkan kebahagiaan
        di dunia dan akhirat. “
      </div>
      <card-event-galery class="mt-8 sm:mt-14" :detail="listGallery" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "@/store";
import cardEventGalery from "./cardEventGalery.vue";

export default {
  components: { cardEventGalery },
  name: "EventGalery",
  setup() {
    const listGallery = computed(() => store.getters.getListGalleryLanding);

    return { listGallery };
  },
};
</script>
