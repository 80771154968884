<template>
  <div
    class="fixed top-0 left-0 h-screen w-screen bg-black-300 z-[104]"
    :class="show ? 'flex justify-center' : 'hidden'"
  >
    <div class="w-full p-5 overflow-auto">
      <div class="mx-auto bg-white rounded-xl w-full sm:w-[500px]">
        <div class="flex justify-between p-5 sm:p-6">
          <div class="flex items-center">
            <div class="mr-4" v-if="showIcon">
              <img
                alt="86-alert"
                :src="require(`@/assets/images/86-alert.svg`)"
                v-if="iconType === 'alert'"
              />
              <img
                alt="86-danger"
                :src="require(`@/assets/images/86-danger.svg`)"
                v-else-if="iconType === 'danger'"
              />
              <img
                alt="86-warning"
                :src="require(`@/assets/images/86-warning.svg`)"
                v-else-if="iconType === 'warning'"
              />
            </div>
            <div class="text-lg font-semibold leading-7 text-grayscale-900">
              {{ title }}
            </div>
          </div>
          <img
            alt="86-close"
            :src="require(`@/assets/images/86-close.svg`)"
            class="w-3 h-3 mt-2 cursor-pointer me-2"
            @click="$emit('close')"
          />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from "vue";

export default {
  name: "ModalTemplate",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    iconType: {
      type: String,
      default: "alert",
    },
    showIcon: {
      type: Boolean,
    },
  },
  emits: ["close"],
  setup(props) {
    watch(
      () => props.show,
      () => {
        if (props.show) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      }
    );
  },
};
</script>
