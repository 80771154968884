import { createRouter, createWebHistory, RouterView } from "vue-router";
// import HomeView from '../views/HomeView.vue';
// import NotFoundAdmin from "@/views/Admin/404.vue";
import NotFound from "@/views/404.vue";
import Landing from "@/views/Landing.vue";
import Event from "@/views/Event.vue";
import EventDetail from "@/views/EventDetail.vue";
import AboutUs from "@/views/AboutUs.vue";
import Profile from "@/views/User/Profile.vue";
import DetailProfile from "@/views/User/DetailProfile.vue";
import AdminLogin from "@/views/Admin/Login.vue";
import Setting from "@/views/Admin/Setting.vue";
import Dashboard from "@/views/Admin/Dashboard.vue";
import LogActivity from "@/views/Admin/LogActivity.vue";
import ListUser from "@/views/Admin/User/ListUser.vue";
import DetailUser from "@/views/Admin/User/DetailUser.vue";
import AddUser from "@/views/Admin/User/AddUser.vue";
import ListEvent from "@/views/Admin/Event/ListEvent.vue";
import DetailEvent from "@/views/Admin/Event/DetailEvent.vue";
import AddEvent from "@/views/Admin/Event/AddEvent.vue";
import UserLogin from "@/views/User/Login.vue";
import UserRegister from "@/views/User/Register.vue";
import UserForgetPassword from "@/views/User/ForgetPassword.vue";
import Notifications from "@/views/Notifications.vue";
import EditEvent from "@/views/Admin/Event/EditEvent.vue";

const token = window.localStorage.getItem("86");
let expired = false;
let type = "";
if (token !== null) {
  expired = Date.now() > JSON.parse(token).expired;
} else {
  expired = true;
}
if (expired) {
  localStorage.removeItem("86");
} else {
  type = JSON.parse(token)?.user?.role;
}

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
  },
  {
    path: "/event",
    name: "event",
    component: Event,
  },
  {
    path: "/event/:id",
    name: "eventDetail",
    component: EventDetail,
  },
  {
    path: "/profile",
    name: "profile",
    children: [
      {
        path: "",
        name: "profile",
        component: Profile,
        beforeEnter(to, from, next) {
          if (expired) {
            next("/login");
          } else {
            next();
          }
        },
      },
      {
        path: ":id",
        name: "detailProfile",
        component: DetailProfile,
      },
    ],
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: AboutUs,
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
  },
  {
    path: "/admin",
    name: "admin",
    component: RouterView,
    children: [
      // {
      //   path: "/:pathMatch(.*)*",
      //   name: NotFoundAdmin,
      //   component: NotFoundAdmin,
      // },
      {
        path: "login",
        name: "adminLogin",
        component: AdminLogin,
        beforeEnter(to, from, next) {
          if ((!expired && type === "ROLE_ADMINS") || type === "ROLE_ADMIN") {
            next("/admin");
          } else {
            next();
          }
        },
      },
      {
        path: "",
        name: "dashboard",
        component: Dashboard,
        beforeEnter(to, from, next) {
          if (!expired && (type === "ROLE_ADMINS" || type === "ROLE_ADMIN")) {
            next();
          } else {
            next("/admin/login");
          }
        },
      },
      {
        path: "setting",
        name: "Setting",
        component: Setting,
        beforeEnter(to, from, next) {
          if (!expired && (type === "ROLE_ADMINS" || type === "ROLE_ADMIN")) {
            next();
          } else {
            next("/admin/login");
          }
        },
      },
      {
        path: "log",
        name: "logActivity",
        component: LogActivity,
        beforeEnter(to, from, next) {
          if (!expired && (type === "ROLE_ADMINS" || type === "ROLE_ADMIN")) {
            next();
          } else {
            next("/admin/login");
          }
        },
      },
      {
        path: "user",
        name: "listUser",
        beforeEnter(to, from, next) {
          if (!expired && (type === "ROLE_ADMINS" || type === "ROLE_ADMIN")) {
            next();
          } else {
            next("/admin/login");
          }
        },
        children: [
          {
            path: "",
            name: "listUser",
            component: ListUser,
          },
          {
            path: ":id",
            name: "detailUser",
            component: DetailUser,
          },
          {
            path: "add",
            name: "addUser",
            component: AddUser,
          },
        ],
      },
      {
        path: "event",
        name: "listEvent",
        beforeEnter(to, from, next) {
          if (!expired && (type === "ROLE_ADMINS" || type === "ROLE_ADMIN")) {
            next();
          } else {
            next("/admin/login");
          }
        },
        children: [
          {
            path: "",
            name: "listEvent",
            component: ListEvent,
          },
          {
            path: ":id",
            children: [
              {
                path: "",
                name: "detailEvent",
                component: DetailEvent,
              },
              {
                path: "edit",
                name: "editEvent",
                component: EditEvent,
              },
            ],
          },
          {
            path: "add",
            name: "addEvent",
            component: AddEvent,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: UserLogin,
    beforeEnter(to, from, next) {
      if (expired) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/register",
    name: "register",
    component: UserRegister,
    beforeEnter(to, from, next) {
      if (expired) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: UserForgetPassword,
    beforeEnter(to, from, next) {
      if (expired) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  { path: "/:pathMatch(.*)*", name: 404, component: NotFound },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
