<script setup>
import axios from "axios";
import moment from "moment";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

onMounted(() => {
  getNotifications();
});

const router = useRouter();
const notifications = ref([]);
const totalNotifications = ref(0);
const totalUnreadNotifications = ref(0);
const isFetchingNotification = ref(false);

const groupedNotifications = computed(() => {
  return notifications.value.reduce((groups, item) => {
    const yearMonth = moment(item.time).format("MMMM YYYY");

    if (!groups[yearMonth]) {
      groups[yearMonth] = [];
    }

    groups[yearMonth].push(item);
    return groups;
  }, {});
});

const getNotifications = async () => {
  const params = {
    offset: 1,
    limit: 4,
  };
  const url =
    process.env.VUE_APP_API_URL +
    "/api/v2/notification?" +
    new URLSearchParams(params).toString();
  try {
    isFetchingNotification.value = true;
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
      },
    });
    notifications.value = response.data.result.datas;
    totalNotifications.value = response.data.result.total;
    totalUnreadNotifications.value = response.data.result.totalUnread;
  } catch (error) {
    console.error(error);
  } finally {
    isFetchingNotification.value = false;
  }
};

const readNotification = async (notification) => {
  if (!notification.read) {
    const url = process.env.VUE_APP_API_URL + "/api/v2/notification/read";
    try {
      await axios({
        method: "post",
        url: url,
        headers: {
          Authorization: `
          Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
        },
        data: {
          id: notification.id,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  router.push(notification.redirectUrl);
};

const readAllNotifications = async () => {
  const url = process.env.VUE_APP_API_URL + "/api/v2/notification/read";
  try {
    await axios({
      method: "post",
      url: url,
      headers: {
        Authorization: `
          Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
      },
      data: {
        all: true,
      },
    });
  } catch (error) {
    console.error(error);
  }

  getNotifications();
};
</script>

<template>
  <div class="bg-white border border-gray-200 rounded-xl">
    <div
      class="flex items-center justify-between px-6 py-5 border-b border-gray-200"
    >
      <div class="flex items-center">
        <span class="text-lg font-bold">Notifikasi</span>
        <span
          class="ml-2 bg-purple text-[#6941C6] text-xs px-2 py-1 font-medium rounded-full"
        >
          {{ totalUnreadNotifications }}
        </span>
      </div>
      <div>
        <button
          class="text-xs font-medium text-right text-secondary"
          @click="readAllNotifications"
        >
          Tandai semua telah dibaca
        </button>
      </div>
    </div>
    <div class="py-6 text-left">
      <template v-if="isFetchingNotification">
        <div v-for="i in 5" :key="i" class="flex items-center px-6 py-4">
          <div class="w-8 h-8 rounded-full bg-grayscale-300 animate-pulse" />
          <div class="flex-1 ml-3">
            <div
              class="w-full h-4 rounded-full bg-grayscale-300 animate-pulse"
            />
            <div
              class="mt-2 bg-grayscale-300 animate-pulse w-1/4 h-[15px] rounded-full"
            />
          </div>
        </div>
      </template>
      <template v-else-if="!Object.keys(groupedNotifications).length">
        <div class="mx-auto text-center">
          <img
            :src="require(`@/assets/images/86-empty-comment.svg`)"
            alt="Belum Ada Komentar"
            class="w-[256px] inline-block"
          />
          <p class="text-lg font-semibold text-grayscale-900">
            Belum Ada Notifikasi
          </p>
          <p class="text-sm text-grayscale-500">
            Anda belum memiliki notifikasi.
          </p>
        </div>
      </template>
      <template
        v-else
        v-for="(notifications, month) in groupedNotifications"
        :key="month"
      >
        <p class="px-6 text-sm font-medium">{{ month }}</p>
        <ul class="mt-2">
          <li
            v-for="notification in notifications"
            :key="notification.id"
            class="flex px-6 py-4"
            :class="{ 'opacity-50 bg-grayscale-50': notification.read }"
          >
            <img
              :src="notification.user?.picture"
              alt="Avatar"
              class="flex-shrink-0 w-8 h-8 rounded-full"
            />
            <div class="flex-1 min-w-0 ml-3">
              <button
                class="w-full text-sm font-semibold text-left"
                @click="readNotification(notification)"
              >
                <span class="block w-full truncate">
                  {{ notification.user?.name }} {{ notification.content }}
                </span>
              </button>
              <p class="mt-1 text-[10px] text-grayscale-400">
                {{ moment(notification.time).format("DD MMM YYYY, HH:mm") }}
              </p>
            </div>
          </li>
        </ul>
      </template>
    </div>
    <div class="px-6 py-5 border-t border-gray-200">
      <router-link :to="'/notifications'" class="text-sm font-medium"
        >Lihat semua notifikasi</router-link
      >
    </div>
  </div>
</template>
