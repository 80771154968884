<template>
  <div
    class="container grid grid-cols-2 gap-3 px-5 mx-auto sm:grid-cols-3 sm:gap-6"
  >
    <div
      v-for="i in 6"
      :key="i"
      class="bg-grayscale-300 rounded-2xl animate-pulse h-[82px]"
    ></div>
  </div>
</template>
