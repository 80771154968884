<template>
  <div>
    <div class="flex w-full">
      <sidebar />
      <div class="w-full">
        <topbar />
        <div
          class="bg-grayscale-50 py-4 px-5 text-left mt-[72px] sm:mt-0"
          style="min-height: calc(100vh - 72px)"
        >
          <breadcrumb :title="'Pengguna'" :list="listMenu" />
          <loading-profile v-if="loading" />
          <div
            v-else
            class="grid items-start grid-cols-1 sm:grid-cols-4 sm:gap-6"
          >
            <profile-picture
              v-if="!onEdit"
              class="border col rounded-xl"
              :image="detail.picture"
            />
            <div v-else class="bg-white border rounded-xl">
              <div
                class="px-4 py-5 text-lg font-semibold leading-7 border-b border-b-grayscale-100"
              >
                Foto Profile
              </div>
              <div class="p-4">
                <upload-image-2
                  class="border col rounded-xl"
                  :img="detail.picture"
                  @updateImage="changeImage"
                />
              </div>
            </div>
            <div class="col-span-3 mt-10 bg-white rounded-xl sm:mt-0">
              <div
                class="flex items-center justify-between px-4 py-5 border-b border-b-grayscale-100"
              >
                <div class="flex-1 text-lg font-semibold leading-7">
                  Overview
                </div>
                <button-general
                  :title="'Edit'"
                  :color="'outline'"
                  :width="'auto'"
                  :size="'xs'"
                  :border="'s'"
                  @click="handleEdit"
                />
              </div>
              <div class="px-4 py-5">
                <div class="mb-3 text-lg font-semibold leading-7">
                  Informasi Profile
                </div>
                <div class="mb-8 text-sm leading-5 text-grayscale-400">
                  Berikut adalah informasi anda sebagai Admin website 86
                  University. Pastikan data anda sudah benar untuk menggunakan
                  informasi ini sebagai kredensial akun admin anda.
                </div>
                <div v-if="!onEdit">
                  <DetailUser :title="'Nama Pengguna'" :content="detail.name" />
                  <DetailUser :title="'Email'" :content="detail.email" />
                  <DetailUser :title="'Username'" :content="detail.username" />
                  <DetailUser
                    :title="'Kata Sandi'"
                    :content="`${'*'.repeat(dummyData?.password?.length || 1)}`"
                  />
                  <DetailUser
                    :title="'Dibuat'"
                    :content="'10/05/2023, 12:37'"
                  />
                </div>
                <div v-else>
                  <input-label
                    class="mt-5"
                    :title="'Nama'"
                    :placeholder="'Masukkan nama anda'"
                    v-model="dummyData.name"
                    :value="dummyData.name"
                  />
                  <input-label
                    class="mt-5"
                    :title="'Email'"
                    :placeholder="'Masukkan email anda'"
                    v-model="dummyData.email"
                    :value="dummyData.email"
                  />
                  <input-label
                    class="mt-5"
                    :title="'Username'"
                    :placeholder="'Masukkan username anda'"
                    v-model="dummyData.username"
                    :value="dummyData.username"
                  />
                  <div class="grid gap-4 mt-5 grid-col-1 sm:grid-col-2">
                    <input-password
                      class="col"
                      :title="'Kata Sandi'"
                      :useIcon="false"
                      :placeholder="'Masukkan kata sandi anda'"
                      v-model="dummyData.password"
                      :value="dummyData.password"
                    />
                    <input-password
                      class="col"
                      :title="'Konfirmasi Kata Sandi'"
                      :useIcon="false"
                      :placeholder="'Masukkan kembali kata sandi'"
                      v-model="dummyData.repassword"
                      :value="dummyData.repassword"
                    />
                  </div>
                  <div class="flex justify-end mt-10">
                    <button-general
                      class="mr-4"
                      :title="'Batalkan'"
                      :color="'outline'"
                      :size="'xs'"
                      :width="'auto'"
                      :border="'s'"
                      @click="cancelUpdate"
                    />
                    <button-general
                      :title="'Simpan'"
                      :color="'primary'"
                      :size="'xs'"
                      :width="'auto'"
                      :border="'s'"
                      @click="submitUpdate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref, inject, computed } from "vue";
import store from "@/store";
import Sidebar from "@/components/layouts/sidebar.vue";
import Topbar from "@/components/layouts/topbar.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import ProfilePicture from "@/components/profiles/profilePicture.vue";
import UploadImage2 from "@/components/uploadImage2.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import DetailUser from "@/components/users/detailUser.vue";
import InputLabel from "@/components/inputs/inputLabel.vue";
import InputPassword from "@/components/inputs/inputPassword.vue";
import LoadingProfile from "@/components/loading/loadingProfile.vue";

export default {
  components: {
    Sidebar,
    Topbar,
    Breadcrumb,
    ProfilePicture,
    UploadImage2,
    ButtonGeneral,
    DetailUser,
    InputLabel,
    InputPassword,
    LoadingProfile,
  },
  name: "LogActivity",
  setup() {
    const token = ref(JSON.parse(window.localStorage.getItem("86")));
    const cryoptojs = inject("cryptojs");
    const listMenu = ref([
      {
        title: "Beranda",
        link: "/admin",
      },
      {
        title: "Pengaturan",
        link: "/admin/setting",
      },
    ]);
    const loading = ref(true);
    const id = ref();
    const detail = computed(() => {
      return {
        ...store.getters.getDetailAuth?.user,
        password: cryoptojs.AES.decrypt(
          token.value.secret,
          "Secret Passphrase"
        ).toString(cryoptojs.enc.Utf8),
        repassword: cryoptojs.AES.decrypt(
          token.value.secret,
          "Secret Passphrase"
        ).toString(cryoptojs.enc.Utf8),
        workingExps: [],
      };
    });
    const onEdit = ref(false);
    const dummyImage = ref("");

    const dummyData = ref(null);

    const handleEdit = () => {
      onEdit.value = !onEdit.value;
      if (onEdit.value) {
        listMenu.value = [
          {
            title: "Beranda",
            link: "/admin",
          },
          {
            title: "Pengaturan",
            link: "/admin/setting",
          },
          {
            title: "Edit",
            link: "/admin/setting",
          },
        ];
      } else {
        listMenu.value = [
          {
            title: "Beranda",
            link: "/admin",
          },
          {
            title: "Pengaturan",
            link: "/admin/setting",
          },
        ];
      }
    };

    const changeImage = (data) => {
      dummyImage.value = data;
    };

    const cancelUpdate = () => {
      dummyData.value = { ...detail.value };
      handleEdit();
    };

    const submitUpdate = () => {
      if (dummyData.value.password !== dummyData.value.repassword) {
        store.dispatch("addNewAlert", {
          status: "error",
          title: "Terdapat kesalahan",
          message: "Kata sandi konfirmasi tidak sama dengan kata sandi",
        });
        return;
      }
      if (dummyImage.value === "") {
        store.dispatch("updateProfile", { data: dummyData.value }).then(() => {
          handleEdit();
          store.dispatch(
            "setPassword",
            cryoptojs.AES.encrypt(
              dummyData.value.password,
              "Secret Passphrase"
            ).toString()
          );
          loadData();
        });
      } else {
        store.dispatch("uploadImage", dummyImage.value).then((res) => {
          let dummy = { ...dummyData.value, picture: res.data.result };
          store.dispatch("updateProfile", { data: dummy }).then(() => {
            handleEdit();
            store.dispatch(
              "setPassword",
              cryoptojs.AES.encrypt(
                dummyData.value.password,
                "Secret Passphrase"
              ).toString()
            );
            loadData();
          });
        });
      }
    };

    async function loadData() {
      store.dispatch("getUpdateUser").then((res) => {
        loading.value = false;
        id.value = res.data.result.id;
        store.commit("setActiveMenu", "Pengaturan");
        token.value = JSON.parse(window.localStorage.getItem("86"));

        dummyData.value = detail.value;
      });
    }

    onBeforeMount(() => {
      loadData();
    });

    return {
      listMenu,
      loading,
      id,
      detail,
      onEdit,
      dummyData,
      handleEdit,
      changeImage,
      cancelUpdate,
      submitUpdate,
    };
  },
};
</script>
