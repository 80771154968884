<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import Greeting from "@/components/notifications/greeting.vue";
import TopbarUser from "@/components/layouts/topbarUser.vue";
import HeaderUser from "@/components/layouts/headerUser.vue";
import CompleteProfile from "@/components/profiles/completeProfile.vue";
import EventUser from "@/components/events/eventUser.vue";
import EventCategoryUser from "@/components/events/eventCategoryUser.vue";
import EventGalery from "@/components/events/eventGalery.vue";
import FootbarUser from "@/components/layouts/footbarUser.vue";
import LoadingHeaderUser from "@/components/loading/loadingHeaderUser.vue";
import LoadingEvents from "@/components/loading/loadingEvents.vue";
import LoadingCategories from "@/components/loading/loadingCategories.vue";
import LoadingGallery from "@/components/loading/loadingGallery.vue";

const token = JSON.parse(window.localStorage.getItem("86"));
const router = useRouter();

const handleCategory = (id) => {
  router.push({ path: "/event", query: { s: id }, append: true });
};

const isLoadingBanners = ref(false);
const isLoadingEvents = ref(false);
const isLoadingCategories = ref(false);
const isLoadingGallery = ref(false);

onMounted(async () => {
  isLoadingBanners.value = true;
  isLoadingEvents.value = true;
  isLoadingCategories.value = true;
  isLoadingGallery.value = true;

  await store.dispatch("getListBannerLanding");
  isLoadingBanners.value = false;

  store.commit("setLimitEvent", 9);
  store.commit("setCategorySelectEvent", "");
  store.commit("setGroupSelectEvent", 0);
  await store.dispatch("getListEventForUser");
  isLoadingEvents.value = false;

  store.commit("setFilterByEvent", "");
  store.commit("setLimitCategory", 6);
  await store.dispatch("getListCategory");
  isLoadingCategories.value = false;

  await store.dispatch("getListGalleryLanding");
  isLoadingGallery.value = false;
});
</script>

<template>
  <div class="bg-white">
    <greeting v-show="token !== null" />
    <topbar-user />
    <loading-header-user v-if="isLoadingBanners" />
    <header-user v-else />
    <complete-profile v-show="token !== null" />
    <loading-events v-if="isLoadingEvents" />
    <event-user v-else class="my-[74px] sm:my-[100px]" />
    <loading-categories v-if="isLoadingCategories" />
    <event-category-user
      v-else
      class="my-[74px] sm:my-[100px]"
      @click="handleCategory"
    />
    <loading-gallery v-if="isLoadingGallery" />
    <event-galery v-else class="py-10 sm:py-[80px] bg-gray-50" />
    <footbar-user />
  </div>
</template>
