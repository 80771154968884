<template>
  <div class="flex" :class="step !== 3 ? 'min-h-screen' : 'h-screen'">
    <div class="relative w-[50%] hidden sm:block">
      <img
        alt="86-splash-auth-user"
        :src="require(`@/assets/images/86-splash-auth-user.svg`)"
        class="absolute object-cover w-full h-full -z-10"
      />
      <img
        alt="86-logo-banner"
        :src="require(`@/assets/images/86-logo-banner.svg`)"
        class="w-[170px] sm:w-[348px] absolute bottom-12 left-12"
      />
    </div>
    <div class="flex-1 px-6 py-10 sm:py-10 sm:px-10">
      <router-link :to="'/'">
        <img alt="86-logo" :src="require(`@/assets/images/86-logo.svg`)" />
      </router-link>
      <div class="flex flex-col justify-center w-full mt-10 sm:mt-0 sm:p-5">
        <div v-if="step !== 3">
          <div class="flex justify-between">
            <div class="w-full h-1 mr-2 rounded-md bg-primary-50" />
            <div
              class="w-full h-1 rounded-md"
              :class="step === 2 ? 'bg-primary-50' : 'bg-gray-200'"
            />
          </div>
          <div class="flex justify-between mt-2 text-xs text-grayscale-600">
            <div>
              <span v-if="step === 1">Langkah 1 : Informasi Akun</span>
              <span v-else>Langkah 2 : Informasi Akun</span>
            </div>
            <div>
              Langkah <span class="text-primary-50">{{ step }}</span> / 2
            </div>
          </div>
        </div>
        <div v-if="step === 1">
          <h5 class="mt-10">Informasi Akun</h5>
          <div class="mt-2 text-sm leading-5 text-grayscale-500">
            Masukan username, email, dan kata sandi untuk membuat akun.
          </div>
          <form ref="form" @submit.prevent="register">
            <input-label
              class="mt-10"
              :title="'Username'"
              :placeholder="'Masukkan username anda'"
              v-model="data.username"
              :value="data.username"
              :messageError="messageUsername"
            />
            <input-email
              class="mt-4"
              :title="'Email'"
              :useIcon="false"
              :placeholder="'Masukkan email anda'"
              v-model="data.email"
              :value="data.email"
              :messageError="messageEmail"
            />
            <input-password
              class="mt-4"
              :title="'Kata Sandi'"
              :useIcon="false"
              :placeholder="'Masukkan kata sandi anda'"
              v-model="data.password"
              :value="data.password"
              :messageError="messagePassword"
            />
            <input-password
              class="mt-4"
              :title="'Konfirmasi Kata Sandi'"
              :useIcon="false"
              :placeholder="'Masukkan kembali kata sandi'"
              v-model="data.repassword"
              :value="data.repassword"
              :messageError="messagePassword"
            />
            <button-primary
              :title="'Lanjut'"
              :active="
                data.username !== '' &&
                data.email !== '' &&
                data.email !== '' &&
                data.repassword !== ''
              "
              class="mt-10"
            />
          </form>
        </div>
        <div v-else-if="step === 2">
          <h5 class="mt-4">Informasi Pribadi</h5>
          <div class="mt-2 text-sm leading-5 text-grayscale-500">
            Masukan informasi pribadi anda untuk melengkapi profil.
          </div>
          <form ref="form" @submit.prevent="completeRegister">
            <input-label
              class="mt-10"
              :title="'Nama Lengkap'"
              :placeholder="'Masukan nama lengkap anda'"
              v-model="data.name"
              :value="data.name"
            />
            <input-label
              class="mt-4"
              :title="'Nama Panggilan'"
              :placeholder="'Masukan nama panggilan anda'"
              v-model="data.nickname"
              :value="data.nickname"
            />
            <input-label
              class="mt-4"
              :title="'Nomor Telepon'"
              :placeholder="'08123xxxxx'"
              v-model="data.phoneNumber"
              :value="data.phoneNumber"
            />
            <input-label
              class="mt-4"
              :title="'Asal SMA'"
              :placeholder="'Masukan asal SMA anda'"
              v-model="data.highSchool"
              :value="data.highSchool"
            />
            <input-label
              class="mt-4"
              :title="'Alamat'"
              :placeholder="'Masukan alamat anda'"
              v-model="data.address"
              :value="data.address"
            />
            <button-primary :title="'Selesai'" class="mt-10" />
          </form>
          <button-general :title="'Lewati'" class="mt-3" @click="nextStep" />
        </div>
        <div v-else class="flex flex-col items-center justify-center">
          <img
            alt="86-checklist-success"
            :src="require(`@/assets/images/86-checklist-success.svg`)"
            width="120"
          />
          <h5 class="mt-6">Berhasil Membuat Akun!</h5>
          <div class="mt-2 text-sm leading-5 text-grayscale-500">
            Akun berhasil dibuat. Silakan login untuk explore acara-acara
            menarik untuk Anda.
          </div>
          <router-link to="/login" class="w-full">
            <button-primary :title="'Menuju ke Halaman Login'" class="mt-10" />
          </router-link>
        </div>
        <div v-if="step !== 3">
          <div class="mt-10 text-sm font-normal leading-5 text-grayscale-600">
            Sudah punya akun?
            <router-link :to="'/login'">
              <span class="cursor-pointer text-secondary"> Masuk di sini </span>
            </router-link>
          </div>
          <div class="mt-6 text-sm font-normal leading-5 text-grayscale-600">
            Kembali ke
            <router-link
              to="/"
              class="font-semibold cursor-pointer text-grayscale-900"
            >
              beranda
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import store from "@/store";
import useValidators from "@/modules/validators";
import InputLabel from "@/components/inputs/inputLabel.vue";
import InputEmail from "@/components/inputs/inputEmail.vue";
import InputPassword from "@/components/inputs/inputPassword.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";

export default {
  components: {
    InputLabel,
    InputEmail,
    InputPassword,
    ButtonPrimary,
    ButtonGeneral,
  },
  name: "UserRegister",
  setup() {
    const { isEmpty, checkPassword } = useValidators();
    const step = ref(1);
    const data = ref({
      username: "",
      email: "",
      password: "",
      repassword: "",
      name: "",
      nickname: "",
      phoneNumber: "",
      highSchool: "",
      address: "",
    });

    const messageUsername = ref("");
    const messageEmail = ref("");
    const messagePassword = ref("");

    watch(
      data,
      () => {
        if (
          messageUsername.value ||
          messageEmail.value ||
          messagePassword.value
        ) {
          messageUsername.value = isEmpty("Username", data.value.username);
          messageEmail.value = isEmpty("Email", data.value.email);
          messagePassword.value = checkPassword(
            data.value.password,
            data.value.repassword
          );
        }
      },
      {
        deep: true,
      }
    );

    const nextStep = () => {
      if (step.value !== 3) {
        step.value++;
      }
    };

    const register = () => {
      messageUsername.value = isEmpty("Username", data.value.username);
      messageEmail.value = isEmpty("Email", data.value.email);
      messagePassword.value = checkPassword(
        data.value.password,
        data.value.repassword
      );
      if (
        messageEmail.value === "" &&
        messageEmail.value === "" &&
        messagePassword.value === ""
      ) {
        const payload = {
          username: data.value.username,
          name: data.value.username,
          email: data.value.email,
          password: data.value.password,
        };
        store.dispatch("register", payload).then((res) => {
          if (res.data?.message !== undefined) {
            messageEmail.value = res.data.message;
          } else {
            nextStep();
          }
        });
      }
    };

    const completeRegister = () => {
      store.dispatch("detailProfile", data.value.email).then((res) => {
        const payload = {
          ...res.data.result,
          name: data.value.name,
          nickname: data.value.nickname,
          phoneNumber: data.value.phoneNumber,
          highSchool: data.value.highSchool,
          address: data.value.address,
        };
        store
          .dispatch("completeProfile", { data: payload, showToast: false })
          .then((res) => {
            if (res) {
              nextStep();
            }
          });
      });
    };

    return {
      step,
      data,
      messageUsername,
      messageEmail,
      messagePassword,
      nextStep,
      register,
      completeRegister,
    };
  },
};
</script>
