<template>
  <div class="relative">
    <swiper
      :breakpoints="{
        '375': {
          slidesPerView: 'auto',
          spaceBetween: 10,
        },
        '640': {
          slidesPerView: 'auto',
          spaceBetween: 30,
        },
      }"
      :navigation="false"
      :freeMode="true"
      :loop="true"
      :modules="modules"
      class="mySwiper galeries"
      :autoplay="{
        delay: 2500,
      }"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(item, index) in detail"
        :key="index"
        class="w-[140px] h-[140px] sm:w-[360px] sm:h-[360px]"
      >
        <img
          alt="item.name"
          :src="item.bannerUrl"
          class="w-full h-full object-cover rounded-lg"
        />
      </swiper-slide>
    </swiper>
    <div class="z-10 absolute top-0 bottom-0 flex items-center">
      <button
        class="p-2 sm:p-4 bg-white text-grayscale-900 shadow-md rounded-full"
        @click="prev"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
          />
        </svg>
      </button>
    </div>
    <div class="z-10 absolute top-0 bottom-0 right-0 flex items-center">
      <button
        class="p-2 sm:p-4 bg-white text-grayscale-900 shadow-md rounded-full"
        @click="next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/free-mode";

// import required modules
import { Autoplay, Navigation, FreeMode } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  name: "CardEventGalery",
  props: {
    detail: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  setup() {
    return {
      modules: [Autoplay, Navigation, FreeMode],
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
  },
};
</script>
