import axios from "axios";
import { createStore } from "vuex";
import globalModules from "./global";
import authModules from "./auth";
import dashboardModules from "./dashboard";
import landingModules from "./landing";
import eventModules from "./event";
import categoryModules from "./category";
import userModules from "./user";
import activityModules from "./activity";
import groupModules from "./group";
import commentModules from "./comment";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async uploadImage({ dispatch }, data) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/upload`;
      try {
        const response = await axios({
          method: "post",
          url: url,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data,
        });

        return response;
      } catch (error) {
        dispatch("addNewAlert", {
          status: "error",
          title: "Terdapat kesalahan saat mengupload gambar",
          message:
            "Gambar terlalu besar. Ukuran gambar harus sesuai dengan yang diminta.",
        });
        return false;
      }
    },
  },
  modules: {
    globalModules,
    authModules,
    dashboardModules,
    landingModules,
    eventModules,
    categoryModules,
    userModules,
    activityModules,
    groupModules,
    commentModules,
  },
});
