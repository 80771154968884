<template>
  <button
    class="px-4 text-base font-medium leading-6"
    :class="[
      size === 'xs'
        ? 'py-1'
        : size === 's'
        ? 'py-2'
        : size === 'm'
        ? 'py-3'
        : size === 'l'
        ? 'py-[14px]'
        : 'py-4',
      border === 's' ? 'rounded-xl' : 'rounded-full',
      color === 'primary'
        ? 'bg-primary-50 text-grayscale-900'
        : color === 'secondary'
        ? 'bg-secondary text-white'
        : color === 'teritary'
        ? 'bg-grayscale-300 text-grayscale-900'
        : color === 'outline'
        ? 'border-grayscale-400 border'
        : color === 'white'
        ? 'bg-white text-grayscale-900'
        : null,
      width === 'auto' ? 'w-auto' : 'w-full',
      disabled ? 'opacity-50' : '',
    ]"
    @click="onSubmit"
    :disabled="disabled"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "ButtonGeneral",
  props: {
    title: {
      type: String,
      required: true,
      default: "Button",
    },
    size: {
      validator(value) {
        return ["xs", "s", "m", "l"].includes(value);
      },
    },
    border: {
      validator(value) {
        return ["s"].includes(value);
      },
    },
    color: {
      validator(value) {
        return ["primary", "secondary", "outline"].includes(value);
      },
    },
    width: {
      validator(value) {
        return ["auto", "full"].includes(value);
      },
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ["click"],
  methods: {
    onSubmit() {
      this.$emit("click");
    },
  },
};
</script>
