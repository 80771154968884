export default {
  state: {
    activeMenu: "Dashboard",
    showMenu: false,
    showAlert: false,
    showConfirmation: false,
    showNotification: false,
    showGreeting: false,
    alerts: [],
  },
  getters: {
    getActiveMenu: (state) => {
      return state.activeMenu;
    },
    getShowMenu: (state) => {
      return state.showMenu;
    },
    getShowAlert: (state) => {
      return state.showAlert;
    },
    getShowConfirmation: (state) => {
      return state.showConfirmation;
    },
    getShowNotification: (state) => {
      return state.showNotification;
    },
    getShowGreeting: (state) => {
      return state.showGreeting;
    },
    getAlerts: (state) => {
      return state.alerts;
    },
  },
  actions: {
    addNewAlert({ state, commit }, { status, title, message }) {
      commit("setAlerts", [
        ...state.alerts,
        { id: makeId(), status, title, message },
      ]);
    },
    deleteAlert({ state, commit }, id) {
      commit(
        "setAlerts",
        state.alerts.filter((alert) => alert.id !== id)
      );
    },
  },
  mutations: {
    setActiveMenu: (state, data) => {
      state.activeMenu = data;
    },
    setShowMenu: (state, data) => {
      state.showMenu = data;
    },
    setShowConfirmation: (state, data) => {
      state.showConfirmation = data;
    },
    setShowNotification: (state, data) => {
      state.showNotification = data;
    },
    setShowGreeting: (state, data) => {
      state.showGreeting = data;
    },
    setAlerts: (state, data) => {
      state.alerts = data;
    },
  },
};

function makeId(length = 8) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
