<template>
  <div class="w-full z-[100] top-8 right-0" :class="showAlert ? 'fixed' : 'hidden'">
    <div
      class="container top-8 mx-auto p-3 rounded-xl bg-white border-gray-100 shadow-lg sm:w-[400px]"
    >
      <div class="flex items-start">
        <div class="me-4">
          <img
            alt="86-success"
            :src="require(`@/assets/images/86-success.svg`)"
            class="me-2"
            v-if="status === 'success'"
          />
          <img
            alt="86-fail"
            :src="require(`@/assets/images/86-fail.svg`)"
            class="me-2"
            v-else
          />
        </div>
        <div class="text-left flex-1">
          <div class="text-sm font-medium leading-5 text-gray-900">
            {{ title }}
          </div>
          <div class="text-xs font-normal leading-4 text-grayscale-400">
            {{ message }}
          </div>
        </div>
        <img
          alt="86-close"
          :src="require(`@/assets/images/86-close.svg`)"
          class="w-[10px] cursor-pointer"
          @click="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopAlert",
  props: {
    status: {
      type: String,
      required: true,
      default: "fail",
    },
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    message: {
      type: String,
      required: true,
      default: "Message",
    },
    showAlert: {
      type: Boolean,
    },
  },
};
</script>