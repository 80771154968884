<template>
  <div>
    <div class="flex w-full">
      <sidebar />
      <div class="w-full">
        <topbar />
        <div
          class="bg-grayscale-50 py-4 px-5 text-left mt-[72px] sm:mt-0"
          style="min-height: calc(100vh - 72px)"
        >
          <breadcrumb :title="'Pengguna'" :list="listMenu" />
          <div class="grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div class="sm:mr-5">
              <div class="bg-white border rounded-xl">
                <div
                  class="px-4 py-5 text-lg font-semibold leading-7 border-b border-b-grayscale-100"
                >
                  Foto Profile
                </div>
                <div class="p-4">
                  <upload-image-2
                    class="border col rounded-xl"
                    @updateImage="changeImage"
                  />
                </div>
              </div>
            </div>
            <div class="col sm:col-span-2">
              <div class="text-lg font-semibold">Informasi Pengguna</div>
              <div class="mt-3 text-sm text-grayscale-500">
                Isikan data pengguna dengan lengkap dan benar untuk membuat akun
                pengguna. Pastikan seluruh data yang dimasukkan sudah benar.
              </div>
              <!-- Personal Profile -->
              <div class="mt-6 bg-white border border-grayscale-100 rounded-xl">
                <div
                  class="px-6 py-5 text-xl font-semibold leading-7 border-b border-grayscale-100"
                >
                  Data Pribadi
                </div>
                <div class="px-6 py-5">
                  <!-- Name -->
                  <input-label
                    :title="'Nama'"
                    :placeholder="'Masukan nama'"
                    :value="data.name"
                    v-model="data.name"
                  />
                  <!-- Nickname -->
                  <input-label
                    class="mt-5"
                    :title="'Nama Panggilan'"
                    :placeholder="'Masukan nama panggilan'"
                    :value="data.nickname"
                    v-model="data.nickname"
                  />
                  <!-- Username -->
                  <input-label
                    class="mt-5"
                    :title="'Nama Username'"
                    :placeholder="'Masukan nama username'"
                    :value="data.username"
                    v-model="data.username"
                  />
                  <!-- Password -->
                  <div class="grid grid-cols-1 gap-4 mt-5 sm:grid-cols-2">
                    <input-password
                      class="col"
                      :title="'Kata Sandi'"
                      :useIcon="false"
                      :placeholder="'Masukkan kata sandi anda'"
                      v-model="data.password"
                      :value="data.password"
                      :messageError="messagePassword"
                    />
                    <input-password
                      class="col"
                      :title="'Konfirmasi Kata Sandi'"
                      :useIcon="false"
                      :placeholder="'Masukkan kembali kata sandi'"
                      v-model="data.repassword"
                      :value="data.repassword"
                      :messageError="messagePassword"
                    />
                  </div>
                  <!-- Phone -->
                  <input-label
                    class="mt-5"
                    :title="'Nomor Telpon/WA'"
                    :placeholder="'Masukan nomor telpon/WA'"
                    :value="data.phoneNumber"
                    v-model="data.phoneNumber"
                  />
                  <!-- Address -->
                  <input-label
                    class="mt-5"
                    :title="'Alamat'"
                    :placeholder="'Masukan alamat tempat tinggal'"
                    :value="data.address"
                    v-model="data.address"
                  />
                  <!-- Email -->
                  <input-email
                    class="mt-5"
                    :title="'Email'"
                    :useIcon="false"
                    :placeholder="'Masukan email aktif'"
                    v-model="data.email"
                    :value="data.email"
                  />
                  <!-- School -->
                  <input-label
                    class="mt-5"
                    :title="'Asal SMA'"
                    :placeholder="'Masukan asal SMA'"
                    :value="data.highSchool"
                    v-model="data.highSchool"
                  />
                  <!-- Profession -->
                  <input-label
                    class="mt-5"
                    :title="'Profesi'"
                    :placeholder="'Masukan profesi sekarang'"
                    :value="data.occupation"
                    v-model="data.occupation"
                  />
                </div>
              </div>
              <profile-edit
                mode="add"
                :usePersonal="false"
                :detail="data"
                @submit="submitData"
                @cancel="handleShowConfirmationCancel"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert-top
      :showAlert="showNotification"
      :status="statusNotification"
      :title="titleNotification"
      :message="messageNotification"
      @close="handleShowNotification"
    />
    <confirmation
      :title="'Batalkan tambah pengguna'"
      :message="'Apakah anda yakin membatalkan proses ini?'"
      :show="showConfirmationCancel"
      :status="'alert'"
      @close="handleShowConfirmationCancel"
      @submit="cancelData"
    />
    <confirmation
      :title="'Tambah pengguna'"
      :message="'Apakah anda yakin ingin menambah pengguna? Pastikan data yang anda masukkan sudah benar.'"
      :show="showConfirmationAdd"
      :status="'alert'"
      @close="handleShowConfirmationAdd"
      @submit="submitData"
    />
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import Sidebar from "@/components/layouts/sidebar.vue";
import Topbar from "@/components/layouts/topbar.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import UploadImage2 from "@/components/uploadImage2.vue";
import InputLabel from "@/components/inputs/inputLabel.vue";
import InputPassword from "@/components/inputs/inputPassword.vue";
import InputEmail from "@/components/inputs/inputEmail.vue";
import ProfileEdit from "@/components/profiles/profileEdit.vue";
import AlertTop from "@/components/alertTop.vue";
import Confirmation from "@/components/confirmation.vue";
import "vue-datepicker-next/index.css";
import moment from "moment";

export default {
  components: {
    Sidebar,
    Topbar,
    Breadcrumb,
    UploadImage2,
    InputLabel,
    InputPassword,
    InputEmail,
    ProfileEdit,
    AlertTop,
    Confirmation,
  },
  name: "AddUser",
  setup() {
    const router = useRouter();
    const listMenu = ref([
      {
        title: "Beranda",
        link: "/",
      },
      {
        title: "List Pengguna",
        link: "/admin/user",
      },
      {
        title: "Tambah Pengguna Baru",
        link: "/admin/user/add",
      },
    ]);
    const data = ref({
      name: "",
      nickname: "",
      username: "",
      password: "",
      repassword: "",
      phoneNumber: "",
      address: "",
      email: "",
      highSchool: "",
      occupation: "",
      picture: "",
      role: "ROLE_USER",
      languages: "",
      about: "",
      specialities: "",
      workingExps: [],
      businesses: [],
      educations: [],
      socials: [],
    });
    const convertDate = (value) => {
      return moment(value !== undefined ? value : moment(), "MM/YYYY")
        .lang("id")
        .format("YYYY");
    };
    const convertMonth = (value) => {
      return moment(value !== "" ? value : moment(), "MMMM")
        .lang("id")
        .format("MM");
    };
    const convertYear = (value) => {
      return moment(value !== "" ? value : moment(), "YYYY")
        .lang("id")
        .format("YYYY");
    };
    // notification
    const showNotification = ref(false);
    const statusNotification = ref("");
    const titleNotification = ref("");
    const messageNotification = ref("");
    const showConfirmationCancel = ref(false);

    const handleShowConfirmationCancel = () => {
      showConfirmationCancel.value = !showConfirmationCancel.value;
    };

    const handleShowNotification = () => {
      showNotification.value = !showNotification.value;
    };
    // image
    const dummyImage = ref("");
    const changeImage = (data) => {
      dummyImage.value = data;
    };
    //  about
    const showAddAbout = ref(false);
    const dummyAbout = ref("");
    const handleShowAddAbout = () => {
      dummyAbout.value = data.value.about;
      showAddAbout.value = !showAddAbout.value;
    };
    const saveAbout = () => {
      data.value.about = dummyAbout.value;
      handleShowAddAbout();
    };

    // specialist
    const dummySpecialist = ref("");
    const saveSpecialist = (detail) => {
      dummySpecialist.value = "";
      data.value.specialities.push(detail);
    };
    const removeListSpecialist = (index) => {
      data.value.specialities.splice(index, 1);
    };

    // workingExps
    const showAddWorkingExps = ref(false);
    const dummyWorkingExps = ref({
      position: "",
      jobTypeId: "",
      name: "",
      address: "",
      monthStart: "",
      yearStart: "",
      monthEnd: "",
      yearEnd: "",
      description: "",
    });
    const lastWorkingExps = ref(false);

    const handleShowAddWorkingExps = () => {
      showAddWorkingExps.value = !showAddWorkingExps.value;
    };

    const saveWorkingExps = () => {
      const detail = {
        position: dummyWorkingExps.value.position,
        jobTypeId: dummyWorkingExps.value.jobTypeId,
        name: dummyWorkingExps.value.name,
        address: dummyWorkingExps.value.address,
        startDate: `${convertMonth(
          dummyWorkingExps.value.monthStart
        )}/${convertYear(dummyWorkingExps.value.yearStart)}`,
        endDate: `${convertMonth(
          dummyWorkingExps.value?.monthEnd
        )}/${convertYear(dummyWorkingExps.value?.yearEnd)}`,
        description: dummyWorkingExps.value.description,
      };
      dummyWorkingExps.value = {
        position: "",
        jobTypeId: "",
        name: "",
        address: "",
        monthStart: "",
        yearStart: "",
        monthEnd: "",
        yearEnd: "",
        description: "",
      };
      lastWorkingExps.value = false;
      data.value.workingExps.push(detail);
      handleShowAddWorkingExps();
    };

    // businesses
    const showAddBusinesses = ref(false);
    const dummyBusinesses = ref({
      name: "",
      address: "",
      monthStart: "",
      yearStart: "",
      monthEnd: "",
      yearEnd: "",
      description: "",
    });
    const lastBusinesses = ref(false);

    const handleShowAddBusinesses = () => {
      showAddBusinesses.value = !showAddBusinesses.value;
    };

    const saveBusinesses = () => {
      const detail = {
        name: dummyBusinesses.value.name,
        address: dummyBusinesses.value.address,
        startDate: `${convertMonth(
          dummyBusinesses.value.monthStart
        )}/${convertYear(dummyBusinesses.value.yearStart)}`,
        endDate: `${convertMonth(
          dummyWorkingExps.value?.monthEnd
        )}/${convertYear(dummyWorkingExps.value?.yearEnd)}`,
        description: dummyBusinesses.value.description,
      };
      dummyBusinesses.value = {
        name: "",
        address: "",
        monthStart: "",
        yearStart: "",
        monthEnd: "",
        yearEnd: "",
        description: "",
      };
      lastBusinesses.value = false;
      data.value.businesses.push(detail);
      handleShowAddBusinesses();
    };

    // educations
    const showAddEducations = ref(false);
    const dummyEducations = ref({
      university: "",
      title: "",
      studyMajor: "",
      monthStart: "",
      yearStart: "",
      monthEnd: "",
      yearEnd: "",
      gpa: 0,
      description: "",
    });

    const handleShowAddEducations = () => {
      showAddEducations.value = !showAddEducations.value;
    };

    const saveEducations = () => {
      const detail = {
        university: dummyEducations.value.university,
        title: dummyEducations.value.title,
        studyMajor: dummyEducations.value.studyMajor,
        startDate: `${convertMonth(
          dummyEducations.value.monthStart
        )}/${convertYear(dummyEducations.value.yearStart)}`,
        endDate: `${convertMonth(
          dummyEducations.value?.monthEnd
        )}/${convertYear(dummyEducations.value?.yearEnd)}`,
        gpa: dummyEducations.value.gpa,
        description: dummyEducations.value.description,
      };
      dummyEducations.value = {
        university: "",
        title: "",
        studyMajor: "",
        monthStart: "",
        yearStart: "",
        monthEnd: "",
        yearEnd: "",
        gpa: 0,
        description: "",
      };
      data.value.educations.push(detail);
      handleShowAddEducations();
    };

    // socials
    const showAddSocials = ref(false);
    const dummySocials = ref({
      instagram: "",
      facebook: "",
      linkedin: "",
      twitter: "",
      tiktok: "",
    });

    const handleShowAddSocials = () => {
      showAddSocials.value = !showAddSocials.value;
    };

    const saveSocials = () => {
      const detail = {
        instagram: dummySocials.value.instagram,
        facebook: dummySocials.value.facebook,
        linkedin: dummySocials.value.linkedin,
        twitter: dummySocials.value.twitter,
        tiktok: dummySocials.value.tiktok,
      };
      data.value.socials = detail;
      handleShowAddSocials();
    };

    const cancelData = () => {
      data.value = {
        name: "",
        nickname: "",
        username: "",
        password: "",
        repassword: "",
        phoneNumber: "",
        address: "",
        email: "",
        highSchool: "",
        occupation: "",
        picture: "",
        role: "",
        languages: "",
        about: "",
        specialities: [],
        workingExps: [],
        businesses: [],
        educations: [],
        socials: [],
      };
      handleShowConfirmationCancel();
      router.push("/admin/user");
    };

    async function submitData(detail) {
      let dummy = { ...detail };

      if (dummy.password !== dummy.repassword) {
        store.dispatch("addNewAlert", {
          status: "error",
          title: "Terdapat kesalahan",
          message: "Kata sandi konfirmasi tidak sama dengan kata sandi",
        });
        return;
      }

      if (dummyImage.value !== "") {
        const res = await store.dispatch("uploadImage", dummyImage.value);

        if (!res) {
          return;
        }

        dummy = { ...dummy, picture: res.data.result };
      }
      const isSuccess = await store.dispatch("adduser", dummy);

      if (isSuccess) {
        statusNotification.value = "success";
        titleNotification.value = "Berhasil menambah pengguna.";
        messageNotification.value =
          "Anda berhasil menambah akun pengguna. Anda bisa memberikan kredensial akun kepada pengguna terkait.";
        setTimeout(() => {
          router.push("/admin/user");
        }, 2000);
      } else {
        statusNotification.value = "fail";
        titleNotification.value = "Gagal menambah pengguna";
        messageNotification.value =
          "Pengguna gagal ditambah. Pastikan bahwa data kredensial pengguna yang Anda masukan sudah benar.";
      }
      handleShowNotification();
    }

    onBeforeMount(() => {
      store.commit("setActiveMenu", "Pengguna");
      store.dispatch("getListJob");
    });

    return {
      listMenu,
      data,
      convertDate,
      convertMonth,
      convertYear,
      showNotification,
      statusNotification,
      titleNotification,
      messageNotification,
      handleShowNotification,
      showConfirmationCancel,
      handleShowConfirmationCancel,
      changeImage,
      showAddAbout,
      dummyAbout,
      handleShowAddAbout,
      saveAbout,
      dummySpecialist,
      saveSpecialist,
      removeListSpecialist,
      showAddWorkingExps,
      dummyWorkingExps,
      lastWorkingExps,
      handleShowAddWorkingExps,
      saveWorkingExps,
      showAddBusinesses,
      dummyBusinesses,
      lastBusinesses,
      handleShowAddBusinesses,
      saveBusinesses,
      showAddEducations,
      dummyEducations,
      handleShowAddEducations,
      saveEducations,
      showAddSocials,
      dummySocials,
      handleShowAddSocials,
      saveSocials,
      cancelData,
      submitData,
    };
  },
};
</script>
