<template>
  <div class="text-left">
    <div class="flex border rounded-lg py-[8px] px-[10px]">
      <img :alt="icon" :src="require(`@/assets/images/${icon}`)" class="me-4" />
      <input
        type="text"
        class="flex-1 text-base font-normal leading-6 ms-3 focus:outline-none"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('update:value', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputIcon",
  data() {
    return {
      focus: false,
    };
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "Label",
    },
    icon: {
      type: String,
      default: "86-user.svg",
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
  },
  emits: ["update:value"],
};
</script>